import { createSlice } from "@reduxjs/toolkit";

export const userFilesSlice = createSlice({
  name: "userfiles",
  initialState: {
    userFiles: [],
  },
  reducers: {
    userFilesUpdate: (state, action) => {
      state.userFiles = action.payload;
    },
    userFilesReset: (state) => {
      state.userFiles = [];
    },
  },
});

export const { userFilesUpdate, userFilesReset } = userFilesSlice.actions;

export const getUserFeatureFiles = (state: any) => state.userFiles.userFiles;

export default userFilesSlice.reducer;
