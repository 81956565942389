import { Box, IconButton, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import React from "react";
import notFoundCss from "./NotFound.module.css";

const NotFound = () => {
  return (
    <Box className={notFoundCss.notFoundOuterBox}>
      <Typography variant="h5">Access Denied/Page Not Found</Typography>
      <IconButton>
        <WarningAmberIcon className={notFoundCss.notFoundIcon} />
      </IconButton>
      <Typography
        variant="body2"
        className={notFoundCss.notFoundTypography}
      >
        The site you're trying to access either doesn't exist or isn't
        accessible due to insufficient privileges.
      </Typography>
    </Box>
  );
};

export default NotFound;
