import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
export const formatDateStr = (
  date: Date | string,
  dateFormat?: string,
  isLocalDateTime?: boolean
): string => {
  if (isLocalDateTime) {
    return dayjs(date).format(dateFormat ? dateFormat : "MM/DD/YYYY");
  }
  dayjs.extend(utc);
  return dayjs.utc(date).format(dateFormat ? dateFormat : "MM/DD/YYYY");
};
