import { Box, Button, Link, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { forgotPassword } from "../../clients/MailService";
import forgotPasswordstyles from "./ForgotPassword.module.css";

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [userNameError, setUserNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isInvalidUser, setIsInvalidUser] = useState(false);

  useEffect(() => {
    if (userNameError === true) {
      setUserNameError(false);
    }
    if (emailError === true) {
      setEmailError(false);
    }
    if (isInvalidUser === true) {
      setIsInvalidUser(false);
    }
  }, [username, email]);

  const isEmail = (email: string) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,6}$/.test(email);
  };

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    if (username === "") {
      setUserNameError(true);
    } else if (!isEmail(email)) {
      setEmailError(true);
    } else {
      forgotPassword(username, email).then((res) => {
        if (res.status === 200) {
          setUsername("");
          setEmail("");
          toast.success("Password reset link sent successfully to " + email);
        } else if (res.status === 401) {
          setIsInvalidUser(true);
        } else {
          toast.error("Failed to send reset link to " + email);
        }
      });
    }
  };

  return (
    <Box className={forgotPasswordstyles.outterBox1}>
      <Box className={forgotPasswordstyles.outterBox2}>
        <Typography
          variant="h5"
          sx={{ padding: "1rem" }}
        >
          Forgot Password
        </Typography>
        <Box>
          <Box className={forgotPasswordstyles.inputBox}>
            {isInvalidUser && (
              <Typography className={forgotPasswordstyles.passwordMessage}>
                Oops ! Looks like you are not registered with us!
              </Typography>
            )}
            <TextField
              type="text"
              value={username}
              fullWidth
              size="small"
              label="Username"
              variant="outlined"
              onChange={handleUserNameChange}
              required
              helperText={
                userNameError ? (
                  <span className={forgotPasswordstyles.errorMessage}>
                    Invalid Username
                  </span>
                ) : (
                  ""
                )
              }
            />
            <TextField
              type="text"
              value={email}
              fullWidth
              size="small"
              label="Email"
              variant="outlined"
              onChange={handleEmailChange}
              required
              helperText={
                emailError ? (
                  <span className={forgotPasswordstyles.errorMessage}>
                    Invalid Email
                  </span>
                ) : (
                  ""
                )
              }
            />
          </Box>
          <Link
            href="login"
            className={forgotPasswordstyles.loginLink}
          >
            Back to Sign In
          </Link>
          <Box className={forgotPasswordstyles.actionBox}>
            <Button
              sx={{ width: "5rem" }}
              variant="outlined"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
