import { Box, Typography } from "@mui/material";
import { Step } from "../../../types/Feature";
import TableTemplateA from "../../templates/TableTemplateA";
import ReuseComponent from "../ReuseComponent";
import viewstepstyles from "./ViewFeatureStep.module.css";

interface ViewFeatureStepProps {
  stepIndex: number;
  step: Step;
}

const ViewFeatureStep = ({ stepIndex, step }: ViewFeatureStepProps) => {
  const stringConversion = (text: string, values: string[]) => {
    const textArray = text.split(" ");
    let result = "";
    let ind = 0;
    textArray.forEach((val) => {
      if (val === "{string}") {
        result = result + `"${values[ind]}" `;
        ind++;
      } else if (val === "{word}") {
        result = result + `${values[ind]} `;
        ind++;
      } else if (val === "{int}") {
        result = result + `${values[ind]} `;
        ind++;
      } else {
        result = result + val + " ";
      }
    });
    return result;
  };

  return (
    <>
      <ReuseComponent
        key={stepIndex}
        Name={step.keyword}
        Value={stringConversion(step.text, step.values)}
      />
      {step.datatable != null && (
        <Box className={viewstepstyles.viewFeatureStepInnerBox}>
          <Typography
            variant="h3"
            sx={{ whiteSpace: "nowrap" }}
          >
            Test Data
          </Typography>
          <Box sx={{ overflowX: "auto" }}>
            <TableTemplateA
              data={step.datatable.rows}
              columnNames={step.datatable.headers}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ViewFeatureStep;
