import { HttpMethod } from "src/types/HttpMethod";

const apiUrl = `${process.env.REACT_APP_BASE_URL}/rs/automation/products`;

export const getAllProducts = async (username: string, password: string) => {
  const url = `${apiUrl}/getAllProducts`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Get,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
    },
  });
};
