import { Box, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Footer from "./components/footer/Footer";
import AppHeader from "./components/navbar/AppHeader";
import Sidebar from "./components/navbar/Sidebar";
import Routers from "./routings/Routers";
import { dsgTheme } from "./styles/theme";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={dsgTheme}>
        <Router>
          <AppHeader />
          <Box sx={{ display: "flex", width: "100vw", height: "87.5vh" }}>
            <Sidebar />
            <Box sx={{ width: "100%", overflow: "scroll" }}>
              <Routers />
            </Box>
          </Box>
          <Footer />
          <ToastContainer position="bottom-left" />
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
