import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { LOCATION } from "src/components/constants/Constants";
import ReuseComponent from "src/components/features/ReuseComponent";
import EditFeature from "src/components/features/edit/EditFeature";
import viewfeaturestyles from "src/components/features/view/ViewFeature.module.css";
import ViewFeatureStep from "src/components/features/view/ViewFeatureStep";
import ViewScenario from "src/components/features/view/ViewScenario";
import { Feature, Scenario } from "src/types/Feature";

interface ViewFeatureProps {
  fileIndex: number;
  featureData: Feature;
  stepDefList: string[];
  onBack: () => void;
}

const ViewFeature = ({
  fileIndex,
  featureData,
  onBack,
  stepDefList,
}: ViewFeatureProps) => {
  const [scenario, setScenario] = useState<Scenario | null>(null);
  const [isEdit, setIsEdit] = useState(false);

  const handleScenarioEdit = (scenarioIndex: number) => {
    setScenario(featureData.scenarios[scenarioIndex]);
  };

  const handleOnScenarioBack = () => {
    setScenario(null);
  };

  return (
    <>
      {!isEdit && !scenario && (
        <>
          <Box className={viewfeaturestyles.editFeatureOuterBox}>
            <ReuseComponent
              Name="Module"
              Value={featureData.name}
            />
            {/* Background */}
            <Box className={viewfeaturestyles.backgroundOuterBox}>
              <ReuseComponent
                key={"viewBackground"}
                Name="Background"
                Value={featureData.background.name}
              />
              {/* Background Steps */}
              <Box className={viewfeaturestyles.viewbackgroundStepsBox}>
                {featureData.background.steps.map(
                  (backGroundSteps, stepIndex) => (
                    <ViewFeatureStep
                      key={`background__step__${stepIndex}`}
                      stepIndex={stepIndex}
                      step={backGroundSteps}
                    />
                  )
                )}
              </Box>
            </Box>
            {/* Scenario's */}
            <Box className={viewfeaturestyles.scenarioListBox}>
              {featureData.scenarios.map((Scenario, scenarioIndex) => (
                <Box
                  className={viewfeaturestyles.scenarioBox}
                  key={scenarioIndex}
                >
                  <Box className={viewfeaturestyles.scenarioText}>
                    <Typography
                      sx={{ whiteSpace: "nowrap" }}
                      variant="h3"
                    >
                      Test Scenario {scenarioIndex + 1} :
                    </Typography>
                    <Typography>{Scenario.name}</Typography>
                  </Box>
                  <IconButton onClick={() => handleScenarioEdit(scenarioIndex)}>
                    <VisibilityIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
          {/* Action Button's */}
          <Box className={viewfeaturestyles.actionButtonsBox}>
            {fileIndex !== -1 &&
              featureData.location === LOCATION.IN_PROGRESS && (
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={stepDefList.length === 0}
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Edit
                </Button>
              )}
            <Button
              type="submit"
              variant="outlined"
              onClick={onBack}
            >
              Back
            </Button>
          </Box>
        </>
      )}
      {!isEdit && scenario !== null && (
        <ViewScenario
          scenarioData={scenario}
          handleOnScenarioBack={handleOnScenarioBack}
        />
      )}
      {isEdit && (
        <EditFeature
          fileIndex={fileIndex}
          featureData={featureData}
          stepDefList={stepDefList}
          handleOnBack={onBack}
        />
      )}
    </>
  );
};

export default ViewFeature;
