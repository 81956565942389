import { HttpMethod } from "../types/HttpMethod";
import { Report } from "../types/Report";

const apiUrl = `${process.env.REACT_APP_BASE_URL}/rs/automation/process`;

export const downloadserverreport = (
  auth: string,
  folderName: number,
  reportUser: string,
  productCode: string
) => {
  const url = `${apiUrl}/${productCode}/downloadReport?folderName=${folderName}&reportUser=${reportUser}`;
  return fetch(url, {
    method: HttpMethod.Get,
    headers: {
      Authorization: auth,
    },
  });
};

export const getreportsummarydatafrmdb = (auth: string, filterdata: Report) => {
  const url = `${apiUrl}/reports`;
  const data = {
    runId: filterdata.runId ? [filterdata.runId] : null,
    reportStartDate: filterdata.reportStartDate
      ? [filterdata.reportStartDate]
      : null,
    reportEndDate: filterdata.reportEndDate ? [filterdata.reportEndDate] : null,
    countryCode: filterdata.countryCode ? [filterdata.countryCode] : null,
    status: filterdata.status ? [filterdata.status] : null,
    reportUser: filterdata.reportUser ? [filterdata.reportUser] : null,
    productCode: filterdata.productCode ? [filterdata.productCode] : null,
    clientCode: filterdata.clientCode ? [filterdata.clientCode] : null,
    scheduleJobId: filterdata.scheduleJob?.userSpecificScheduleJobId
      ? [filterdata.scheduleJob.userSpecificScheduleJobId]
      : null,
  };
  return fetch(url, {
    method: HttpMethod.Post,
    headers: { Authorization: auth, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};
