import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";

// mui global styles here

export const dsgTheme = createTheme({
  palette: {
    primary: {
      main: "#FE524A",
    },
    secondary: {
      main: "#444343",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "#ffff",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#FE524A",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.sectionfieldcss": {
            paddingTop: "1.4%",
          },

          color: "#191f1c",
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": { color: "#444343" },
        },
      },
    },

    // MuiInput: {
    //   styleOverrides: {
    //     root: {
    //       padding: 0,
    //     },
    //     input: {
    //       padding: 0,
    //       "&:focus": {
    //         backgroundColor: "white",
    //       },
    //     },

    //     underline: {
    //       "&:after": {
    //         border: "0.2px solid #adadad",
    //       },
    //     },
    //   },
    // },

    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          color: "#191f1c",
          "&.Mui-disabled": {
            backgroundColor: "#e0e0e0",
          },
        },

        root: {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #adadad",
            },
          },

          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #adadad",
            },

            "& .MuiStandardInput-notchedOutline": {
              border: "1px solid #adadad",
            },

            "&:after": {
              border: "1px solid #adadad",
            },
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiInput-root": {
            "&:before": {
              borderBottom: "1px solid #a59da55c",
            },
            "&:after": {
              borderBottom: "1px solid #a59da55c",
            },
          },
        },
        input: {
          "&.MuiInput-input": {
            padding: 0,
            "&:hover": {
              border: "none",
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "1px solid #a59da55c",
          },
        },
        input: {
          "&:focus": {
            backgroundColor: "inherit",
          },
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        h6: {
          color: "#FE524A",
          fontWeight: 600,
          textAlign: "center",
          textTransform: "capitalize",
          // Center the text horizontally and vertically
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        },
        h5: {
          color: "#444343",
          fontWeight: 600,
          textAlign: "center",
          textTransform: "capitalize",
          paddingTop: "0.7%",
        },
        h3: {
          color: "#b70879",
          // padding: "8px",
          fontSize: "1rem",
        },
        h4: {
          color: "#444343",
          fontWeight: "bold",
          textAlign: "center",
          textTransform: "capitalize",
          paddingTop: "0.7%",
          fontSize: "1.3rem",
        },
        body1: {
          fontSize: "14px",
          color: "#444343",
        },
        body2: {
          fontSize: "16px", //used for warningPopup
          color: "#191F1C",
        },
        subtitle1: {
          color: "#444343",
          fontWeight: "normal",
          textAlign: "center",
          textTransform: "capitalize",
        },
        subtitle2: {
          fontSize: "14px",
          color: "#444343",
          fontWeight: "bold",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          padding: "8px 8px",
          fontSize: "14px",
          color: "#FE524A",
          border: "1px solid #FE524A",
          // textTransform:'capitalize',
          borderRadius: "4px",
          fontWeight: "medium",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#FE524A",
            color: "#FFFFFF",
            cursor: "pointer",
            "& svg": {
              fill: "white",
            },
          },
          "&:disabled": {
            backgroundColor: "#D9D9D9",
            color: "#4F4F4F",
            border: "1px solid #4F4F4F",
            cursor: "not-allowed",
            "& svg": {
              fill: "#4f4f4f",
            },
          },
          "&.navigate": {
            border: "none",
          },
          "&.infoicon": {
            "&:hover": {
              background: "none",
              border: "none",
              color: "#fe524a",
            },
            "&:hover svg": {
              fill: "white",
            },
          },
          "&.Mui-disabled": {
            backgroundColor: "#D9D9D9",
            color: "#4F4F4F",
            border: "1px solid #4F4F4F",
            cursor: "not-allowed",
            "& svg": {
              fill: "#4f4f4f",
            },
          },
        },
        text: {
          "&:disabled": {
            backgroundColor: "#f7f5f5",
            color: "#4F4F4F",
            cursor: "not-allowed",
            "& svg": {
              fill: "#4f4f4f",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#fe524a",
        },
        outlined: {
          "& .MuiOutlinedInput": {
            styleOverrides: {
              input: {
                color: "#fe524a",
                border: "1px solid #adadad",
              },
            },
          },
        },
        filled: {
          backgroundColor: "white",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          width: "97%",
          float: "left",
          padding: "0px 0px 0px 24px",
          marginLeft: "-2%",
        },
        displayedRows: {
          float: "left",
          margin: "0px",
        },
        actions: {
          marginLeft: "70%",
          position: "sticky",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {},
      },
    },
    // Additional styles for .tableBody
    MuiTable: {
      styleOverrides: {
        root: {
          "& .tableBody": {
            width: "100%",
          },
        },
      },
    },

    // Additional styles for .tableContainer
    MuiTableContainer: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          "&:not(.MuiTableCell-head), &.MuiTableCell-head": {
            border: "none",
          },
          "&.MuiTableCell-head": {
            backgroundColor: "#faefef",
          },
          padding: "8px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-of-type(even)": {
            backgroundColor: "#faefef",
          },
          "&.summaryrow": {
            backgroundColor: "#ffffff",
          },
          height: "5%",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.radiocss": {
            padding: 0,
            paddingLeft: 9,
          },
          color: "#fe524a",
          "&.Mui-disabled": {
            "&.radiosvg svg": {
              color: "rgba(0, 0, 0, 0.38)",
            },
          },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          border: "#FE524A",

          color: "#FE524A",
        },

        root: {
          "&.Mui-checked": {
            color: "#FE524A",
          },
          "&.checkcss": {
            padding: 0,
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        outlined: {
          margin: "4px",
          display: "inline-flex",
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: "277px",
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        contentWrapper: {
          height: "270px",
          overflow: "overlay",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          textDecorationColor: "#fe524a",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "red",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        //used for warningPopup
        paper: {
          minWidth: "30rem",
          maxWidth: "none",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          //used for warning popup
          padding: "16px 20px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:disabled": {
            color: "#4F4F4F",
            border: "none",
            cursor: "not-allowed",
            "& svg": {
              fill: "#4F4F4F",
            },
          },
          "&:hover": {
            backgroundColor: "unset",
          },
          "&.dropdownbutton": {
            padding: "0",
            gap: "10%",
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#FE524A",
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#FE524A",
          },
          "&.Mui-disabled": {
            pointerEvents: "auto",
            cursor: "not-allowed",
          },
        },
      },
    },
  },
});
