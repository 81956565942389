import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/slices/userSlice";
import NavItems, { NavItem } from "./NavItems";

const Sidebar = () => {
  const [isOpened, setIsOpened] = useState(false);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const SidebarData: NavItem[] = NavItems();

  function navigateToPage(route: string): void {
    navigate(route);
  }

  const [menuState, setMenuState] = useState<{ [key: string]: boolean }>(
    SidebarData.reduce(
      (acc, item) => ({
        ...acc,
        [item.label]: false,
      }),
      {}
    )
  );

  const toggleSubmenu = (itemName: string) => {
    setMenuState((prevState) => ({
      ...prevState,
      [itemName]: !prevState[itemName],
    }));
  };

  const handleClick = () => {
    setIsOpened((prev) => {
      return !prev;
    });
    setMenuState(
      SidebarData.reduce(
        (acc, item) => ({
          ...acc,
          [item.label]: false,
        }),
        {}
      )
    );
  };

  const handlePopoverClose = (itemName: string) => {
    setAnchorEl(null);
    setMenuState((prevState) => ({
      ...prevState,
      [itemName]: !prevState[itemName],
    }));
  };

  return (
    <>
      {user && (
        <Box
          sx={{
            padding: "0.3rem",
            height: "90vh",
            boxShadow:
              "0px 2px 2px -2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          }}
        >
          <List>
            {isOpened ? (
              <IconButton
                edge="end" //menu display starts from left corner
                aria-label="menu"
                id="icon"
                onClick={() => handleClick()}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                edge="end" //menu display starts from left corner
                aria-label="menu"
                id="icon"
                sx={{ marginLeft: "0.5rem" }}
                onClick={() => handleClick()}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box
              sx={{ height: "35rem", overflowX: "hidden", overflowY: "scroll" }}
            >
              {SidebarData.map((navItem, index) => (
                <Box key={index}>
                  {navItem.privilege && (
                    <Box key={navItem.label}>
                      <ListItem
                        disablePadding
                        onClick={(e) => {
                          if ((navItem as NavItem).options) {
                            toggleSubmenu(navItem.label);
                            setAnchorEl(e.currentTarget);
                          } else {
                            navigateToPage(`${navItem.route}`);
                          }
                        }}
                      >
                        <ListItemButton sx={{ gap: "16px" }}>
                          <Box>{navItem.icon}</Box>
                          {isOpened === true ? (
                            <ListItemText
                              primary={navItem.label}
                              sx={{ textWrap: "nowrap" }}
                            />
                          ) : (
                            ""
                          )}
                          {(navItem as NavItem).options && isOpened ? ( // Check if there are options
                            <ListItemIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {menuState[navItem.label] ? ( // Check if the submenu is open
                                <KeyboardArrowDownIcon // Render the ExpandLessIcon when open
                                  id="icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleSubmenu(navItem.label);
                                  }}
                                />
                              ) : (
                                <KeyboardArrowRightIcon // Render the ExpandMoreIcon when closed
                                  id="icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleSubmenu(navItem.label);
                                  }}
                                />
                              )}
                            </ListItemIcon>
                          ) : null}
                        </ListItemButton>
                      </ListItem>
                      {(navItem as NavItem).options &&
                        menuState[navItem.label] &&
                        isOpened && (
                          <List>
                            {(navItem as NavItem).options?.map(
                              (option: any) => (
                                <ListItem
                                  key={option.id}
                                  disablePadding
                                  onClick={() =>
                                    navigateToPage(`${option.route}`)
                                  }
                                  sx={{
                                    marginLeft: "1.5rem",
                                  }}
                                >
                                  <ListItemButton sx={{ gap: "16px" }}>
                                    <Box>{option.icon}</Box>
                                    <ListItemText primary={option.label} />
                                  </ListItemButton>
                                </ListItem>
                              )
                            )}
                          </List>
                        )}
                      {(navItem as NavItem).options &&
                        menuState[navItem.label] &&
                        !isOpened && (
                          <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={() => handlePopoverClose(navItem.label)}
                            anchorOrigin={{
                              vertical: "center",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <Box
                              onMouseLeave={() =>
                                handlePopoverClose(navItem.label)
                              }
                            >
                              <List>
                                {navItem &&
                                  navItem.options?.map((option: any) => (
                                    <ListItem
                                      key={option.id}
                                      disablePadding
                                      onClick={() => navigate(option.route)}
                                    >
                                      <ListItemButton
                                        component="div"
                                        sx={{
                                          "&:hover": {
                                            backgroundColor: "#FFCFCD",
                                          },
                                          gap: "16px",
                                        }}
                                      >
                                        <Box>{option.icon}</Box>
                                        <ListItemText
                                          sx={{ color: "black" }}
                                          primary={option.label}
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                  ))}
                              </List>
                            </Box>
                          </Popover>
                        )}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </List>
        </Box>
      )}
    </>
  );
};

export default Sidebar;
