import PublishIcon from "@mui/icons-material/Publish";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import lodash from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import DeselectIcon from "@mui/icons-material/Deselect";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import { toast } from "react-toastify";
import { getAllFeatureFiles } from "src/clients/FeatureService";
import { sendReviewRequestMail } from "src/clients/MailService";
import {
  getAllPublishData,
  publishFeatureFiles as publishFeaturesToReview,
  savePublishedData,
} from "src/clients/PublishService";
import {
  getUserFeatureFiles,
  userFilesUpdate,
} from "src/redux/slices/userFilesSlice";
import { selectUser } from "src/redux/slices/userSlice";
import { PublishData, PublishFeatures } from "src/types/PublishData";
import { PublishRequestStatus } from "src/types/PublishRequestStatus";
import { Feature } from "../../types/Feature";
import { LOCATION } from "../constants/Constants";
import WarningPopup from "../modal/WarningPopup";
import FeatureCard from "../templates/FeatureCard";
import Pagination from "../templates/Pagination";
import publishstyles from "./Publish.module.css";

const Publish = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(15);
  const [isDisable, isLoading] = useState<boolean>(false);
  const [commentPopup, setCommentPopup] = useState<boolean>();
  const [PRComment, setPRComment] = useState<string>("");
  const publishData: PublishData = {
    publishStatus: "",
    product: "",
    updatedBy: "",
    updatedTimeStamp: "",
    createdBy: "",
    createdTimeStamp: "",
    publishId: 0,
    clientId: 0,
    publishFeatures: [],
  };
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const userfiles = useSelector(getUserFeatureFiles) || [];
  const [featureData, setFeatureData] = useState<Feature[] | undefined>(() => {
    if (userfiles.userFiles === undefined) {
      return undefined;
    } else {
      return userfiles.userFiles;
    }
  });
  const fetchData = async () => {
    const featuresRes = await getAllFeatureFiles(
      [LOCATION.IN_PROGRESS, LOCATION.REVIEW],
      user.auth,
      user.productCode
    );
    if (featuresRes.ok) {
      const data = featuresRes.json();
      const featureFilesData = await data;
      const validFeatureList = featureFilesData.features;
      const userArray = [
        ...validFeatureList[LOCATION.IN_PROGRESS],
        ...validFeatureList[LOCATION.REVIEW],
      ];
      dispatch(
        userFilesUpdate({
          userFiles: userArray,
        })
      );
      setFeatureData(userArray);
    }
  };
  useEffect(() => {
    setFeatureData(undefined);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.productCode]);

  const handleItemsPerPageChange = (event: any) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handleSelect = (fileName: string) => {
    const index = selectedFeatures.indexOf(fileName);
    if (index !== -1) {
      const updatedFeatures = [...selectedFeatures];
      updatedFeatures.splice(index, 1);
      setSelectedFeatures(updatedFeatures);
    } else {
      setSelectedFeatures([...selectedFeatures, fileName]);
    }
  };

  const getCurrentSelectList = () => {
    const currentList: string[] = [];
    currentPageItems &&
      currentPageItems.forEach((file) => {
        if (file.location !== LOCATION.REVIEW) {
          currentList.push(file.name.replaceAll(" ", "_"));
        }
      });
    return currentList;
  };

  const handleSelectAll = () => {
    const currentList: string[] = getCurrentSelectList();
    const newSelectedFeatures = currentList.filter(
      (fileName) => !selectedFeatures.includes(fileName)
    );
    setSelectedFeatures([...selectedFeatures, ...newSelectedFeatures]);
  };
  const handleDeSelectAll = () => {
    const currentList: string[] = getCurrentSelectList();
    const updatedSelectedFeatures = selectedFeatures.filter(
      (fileName) => !currentList.includes(fileName)
    );
    setSelectedFeatures(updatedSelectedFeatures);
  };

  const handleCommentOk = async () => {
    setCommentPopup(false);
    isLoading(true);
    const publishDataDtoList = await (
      await getAllPublishData(user.productCode, user.auth)
    ).json();

    let message = "";
    lodash.some(publishDataDtoList, (publishDataDto) => {
      if (
        lodash.includes(selectedFeatures, publishDataDto.featureName) &&
        publishDataDto.featureStatus === PublishRequestStatus.IN_PROGRESS
      ) {
        message = message + publishDataDto.featureName + ",";
      }
    });
    if (message.length > 0) {
      toast.error(
        message.substring(0, message.length - 1) +
          " test scripts are already in progress"
      );
      isLoading(false);
    } else {
      const publishFeaturesToReviewResponse = await publishFeaturesToReview(
        selectedFeatures,
        user.productCode,
        user.auth
      );
      if (publishFeaturesToReviewResponse.ok) {
        publishData.publishStatus = PublishRequestStatus.CREATED;
        publishData.publishComment = PRComment;
        publishData.product = user.productCode;
        publishData.clientId = user.clientId;
        for (let i = 0; i < selectedFeatures.length; i++) {
          const newPublishFeature: PublishFeatures = {
            featureName: selectedFeatures[i],
            featureStatus: PublishRequestStatus.IN_PROGRESS,
            featureComment: "",
          };
          publishData.publishFeatures.push(newPublishFeature);
        }
        const savePublishedDataResponse = await savePublishedData(
          publishData,
          user.auth
        );
        if (savePublishedDataResponse.ok) {
          let successmessage = "";
          selectedFeatures.forEach((feature: string) => {
            successmessage =
              successmessage + feature.replaceAll(" ", "_") + ", ";
          });
          const mailResponse = await sendReviewRequestMail(
            selectedFeatures,
            user.auth
          );
          if (mailResponse.ok) {
            fetchData();
            toast.success(
              successmessage.substring(0, successmessage.length - 2) +
                " published successfully"
            );
            setSelectedFeatures([]);
            isLoading(false);
          } else {
            toast.error("Failed to send Publish mail");
            isLoading(false);
          }
        } else {
          toast.error("Failed to save PublishData");
          isLoading(false);
        }
      } else {
        toast.error("Failed to Publish the script file to review");
        isLoading(false);
      }
      setPRComment("");
    }
  };

  const handleCommentCancel = () => {
    setCommentPopup(false);
    setPRComment("");
  };

  const handlePublish = async () => {
    setCommentPopup(true);
  };

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentPageItems = featureData?.slice(firstIndex, lastIndex);

  if (!featureData) {
    return (
      <Box className="loaderIconStylings">
        <CircularProgress disableShrink />
      </Box>
    );
  }
  return (
    <Box>
      {isDisable && <Box className={publishstyles.overlay}></Box>}
      {isDisable && (
        <Box className={publishstyles.loaderContainer}>
          <CircularProgress disableShrink />
        </Box>
      )}
      {!lodash.isEmpty(currentPageItems) ? (
        <Box>
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              paddingTop: "2.5%",
            }}
          >
            <Box></Box>
            <Box>
              <Typography variant="h5">Publish Test Scripts</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                visibility: selectedFeatures.length > 0 ? "visible" : "hidden",
              }}
            >
              {currentPageItems &&
              currentPageItems
                .filter((item) => item.location !== LOCATION.REVIEW)
                .every((item) =>
                  selectedFeatures.includes(item.name.replaceAll(" ", "_"))
                ) ? (
                <Tooltip
                  title="Deselectall"
                  placement="bottom"
                >
                  <IconButton
                    size="large"
                    disableRipple={true}
                    onClick={handleDeSelectAll}
                    sx={{ padding: "0 2.5rem" }}
                  >
                    <DeselectIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title="Selectall"
                  placement="bottom"
                >
                  <IconButton
                    size="large"
                    disableRipple={true}
                    onClick={handleSelectAll}
                    sx={{ padding: "0 2.5rem" }}
                  >
                    <SelectAllIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
          <Box className={publishstyles.featureItemsOuterBox}>
            {currentPageItems?.map((file: Feature, key: number) => (
              <FeatureCard
                key={key}
                id={key}
                feature={file}
                totalSelected={selectedFeatures.length}
                handleSelect={handleSelect}
                isChecked={
                  selectedFeatures.indexOf(file.name.replaceAll(" ", "_")) !==
                  -1
                }
                actions={[]}
              ></FeatureCard>
            ))}
          </Box>
        </Box>
      ) : (
        <Box className={publishstyles.NoFilesMessage}>
          <Typography variant="subtitle1">No Test Scripts present</Typography>
        </Box>
      )}
      <Box className={publishstyles.actionBox}>
        {/* pagination */}
        <Pagination
          rows={String(itemsPerPage)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalDataLength={featureData?.length}
          dataPerPage={itemsPerPage}
          handleItemsPerPageChange={(e) => handleItemsPerPageChange(e)}
          fieldName="Test scripts"
        />
        {/* action buttons */}
        <Button
          variant="outlined"
          startIcon={<PublishIcon />}
          onClick={handlePublish}
          disabled={selectedFeatures.length === 0}
        >
          Publish
        </Button>
      </Box>
      {commentPopup && (
        <WarningPopup
          isOpen={true}
          onClose={handleCommentCancel}
          actionButtons={[
            {
              name: "Confirm",
              onButtonClick: handleCommentOk,
              isDisabled: PRComment === "",
            },
            {
              name: "Cancel",
              onButtonClick: handleCommentCancel,
            },
          ]}
        >
          <Typography sx={{ paddingBottom: "5%" }}>
            Please add commit message for the PR.
          </Typography>
          <TextareaAutosize
            style={{ width: "102%", height: "90px" }}
            required
            id="PRcomment"
            maxRows={10}
            minRows={4}
            placeholder="Enter comment"
            value={PRComment}
            onChange={(e) => setPRComment(e.target.value)}
          />
        </WarningPopup>
      )}
    </Box>
  );
};

export default Publish;
