import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button, Link, Stack, TextField, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getByUsernameAndPassword } from "../../clients/UserService";
import { login } from "../../redux/slices/userSlice";
import loginstyles from "./Login.module.css";

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setUserNameError(false);
    setPasswordError(false);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    getByUsernameAndPassword(username, password)
      .then(async (res) => {
        if (res.ok) {
          const userData = await res.json();
          if (userData.username !== null) {
            dispatch(
              login({
                userId: username,
                password: password,
                email: userData.email,
                role: userData.role.roleName,
                permissionsList: userData.permissionsList,
                productCode: userData.defaultProduct,
                firstName: userData.firstName,
                lastName: userData.lastName,
                products: userData.products,
                filesStatus: true,
                clientId: userData.clientId,
                clientCode: userData.clientCode,
                auth: `Basic ${btoa(`${username}:${password}`)}`,
              })
            );
            navigate("/");
          } else {
            setPasswordError(false);
            toast.error("Attempting to log in with an inactive user account");
          }
        } else if (res.status === 401) {
          setPasswordError(true);
          toast.error("Invalid username or password");
        }
      })
      .catch(() => {
        setPasswordError(true);
        toast.error("Invalid username or password");
      });
  };

  return (
    <>
      <Container className={loginstyles.logincontainer}>
        <Container className={loginstyles.loginformcontainer}>
          <form onSubmit={handleSubmit}>
            <Stack
              direction="column"
              alignItems="center"
              spacing={2}
            >
              <Typography variant="h5">Sign In</Typography>
              <Container className={loginstyles.passworderrorcontainer}>
                {passwordError && (
                  <Typography className={loginstyles.passwordmessage}>
                    The username or password isnt right
                  </Typography>
                )}
              </Container>
              <TextField
                type="text"
                value={username}
                fullWidth
                id="filled-basic"
                size="small"
                label="Username"
                variant="outlined"
                onChange={handleUserNameChange}
                required
                error={userNameError}
                helperText={
                  userNameError ? (
                    <span className={loginstyles.errormessage}>
                      Username not found
                    </span>
                  ) : (
                    ""
                  )
                }
              />

              <TextField
                id="password-input"
                label="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
                size="small"
                value={password}
                onChange={handlePasswordChange}
                autoComplete="current-password"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
                error={passwordError}
              />
            </Stack>
            <Stack
              direction="column"
              sx={{ mt: 2 }}
              alignItems="center"
              my={1}
            >
              <Link
                href="forgotPassword"
                className={loginstyles.loginlink}
              >
                Forgot password
              </Link>
              <Button
                variant="outlined"
                type="submit"
              >
                Sign In
              </Button>
            </Stack>
            <Stack
              direction="column"
              sx={{ mt: 2 }}
              alignItems="center"
            >
              <Typography>Copyright © 2024 Red-Shift.</Typography>
              <Typography>All rights reserved.</Typography>
            </Stack>
          </form>
        </Container>
      </Container>
    </>
  );
};
