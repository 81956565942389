import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Button, IconButton, Typography } from "@mui/material";
import lodash from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import RunForm from "src/components/Configure/RunForm";
import { LOCATION } from "src/components/constants/Constants";
import { getBaseFeatureFiles } from "src/redux/slices/baseFilesSlice";
import {
  getUserFeatureFiles,
  userFilesUpdate,
} from "src/redux/slices/userFilesSlice";
import {
  deleteFeatureFiles,
  saveFeatureFile,
} from "../../../clients/FeatureService";
import { selectUser } from "../../../redux/slices/userSlice";
import { Datatable, Feature, Scenario, Step } from "../../../types/Feature";
import WarningPopup from "../../modal/WarningPopup";
import Popup from "../Popup";
import ReuseComponent from "../ReuseComponent";
import editfeaturestyles from "./EditFeature.module.css";
import EditFeatureStep from "./EditFeatureStep";
import EditScenario from "./EditScenario";

interface EditFeatureProps {
  fileIndex: number;
  featureData: Feature;
  stepDefList: string[];
  handleOnBack: () => void;
}

const EditFeature = ({
  fileIndex,
  featureData,
  handleOnBack,
  stepDefList,
}: EditFeatureProps) => {
  const [editedFeatureData, setEditedFeatureData] = useState<Feature>({
    ...featureData,
    background: {
      name: featureData.background.name,
      steps: featureData.background.steps.map((step) => ({
        ...step,
        datatable: step.datatable
          ? {
              ...step.datatable,
              headers: [...step.datatable.headers],
              rows: [...step.datatable.rows],
            }
          : null,
      })),
    },
    scenarios: featureData.scenarios.map((scenario) => ({
      tags: scenario.tags,
      name: scenario.name,
      steps: scenario.steps.map((step) => ({
        ...step,
        datatable: step.datatable
          ? {
              ...step.datatable,
              headers: [...step.datatable.headers],
              rows: [...step.datatable.rows],
            }
          : null,
      })),
    })),
  });
  const [popupOpen, setPopupOpen] = useState(false);
  const [mode, setMode] = useState<string | null>(null);
  const [scenario, setScenario] = useState<Scenario | null>(null);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const basefiles = useSelector(getBaseFeatureFiles) || [];
  const userfiles = useSelector(getUserFeatureFiles) || [];
  const [currentScenarioIndex, setCurrentScenarioIndex] = useState(-1);
  const [popupStepIndex, setPopupStepIndex] = useState<number>(-1);
  const [backToFeatures, setBackToFeatures] = useState<boolean>(false);
  const [scenarioDelete, setScenarioDelete] = useState<boolean>(false);
  const [runPopup, setRunPopup] = useState<boolean>(false);
  const [isConfigTestLoading, setIsConfigTestLoading] = useState(false);
  const [deleteScenarioIndex, setDeleteScenarioIndex] = useState<number>(-1);
  const disable =
    lodash.isEqual(featureData, editedFeatureData) ||
    lodash.isEmpty(editedFeatureData.name) ||
    lodash.isEmpty(editedFeatureData.scenarios) ||
    lodash.some(editedFeatureData.background.steps, (step) => {
      return (
        lodash.includes(step.values, "") ||
        lodash.includes(step.datatable?.headers, "") ||
        (step.datatable?.rows &&
          lodash.some(step.datatable.rows, (row) =>
            lodash.every(row, (cell) => lodash.isEmpty(cell))
          ))
      );
    }) ||
    lodash.some(editedFeatureData.scenarios, (scenario) => {
      return (
        lodash.isEmpty(scenario.name) ||
        lodash.isEmpty(scenario.tags) ||
        lodash.isEmpty(scenario.steps)
      );
    }) ||
    (featureData.name !== editedFeatureData.name &&
      lodash.some(basefiles.baseFiles, (feature) => {
        return lodash.isEqual(feature.name, editedFeatureData.name);
      })) ||
    (featureData.name !== editedFeatureData.name &&
      lodash.some(userfiles.userFiles, (feature) => {
        return lodash.isEqual(feature.name, editedFeatureData.name);
      }));

  const handleOnSaveFeatureFile = async () => {
    const response = await saveFeatureFile(
      editedFeatureData,
      user.auth,
      user.productCode
    );
    if (response.ok) {
      const updatedFeatureList = Array.isArray(userfiles.userFiles)
        ? [...userfiles.userFiles]
        : [];
      const existingIndex = updatedFeatureList.findIndex(
        (feature) => feature.name === featureData.name
      );
      if (existingIndex !== -1 && editedFeatureData.name !== featureData.name) {
        updatedFeatureList.splice(existingIndex, 1);
        editedFeatureData.location = LOCATION.IN_PROGRESS;
        updatedFeatureList.push(editedFeatureData);
      } else if (existingIndex !== -1) {
        editedFeatureData.location = LOCATION.IN_PROGRESS;
        updatedFeatureList.splice(existingIndex, 1);
        updatedFeatureList.push(editedFeatureData);
      } else {
        editedFeatureData.location = LOCATION.IN_PROGRESS;
        updatedFeatureList.push(editedFeatureData);
      }
      dispatch(userFilesUpdate({ userFiles: updatedFeatureList }));
      toast.success(editedFeatureData.name + " saved successfully");
    } else if (response.status === 500) {
      toast.error("Internal server error");
    }
    if (
      featureData.name !== "" &&
      editedFeatureData.name.trim() !== featureData.name.trim()
    ) {
      const featureNameArray = [featureData.name];
      deleteFeatureFiles(featureNameArray, user.auth, user.productCode);
    }
    handleOnBack();
  };

  const handleBack = () => {
    setBackToFeatures(true);
    if (lodash.isEqual(featureData, editedFeatureData)) {
      handleOnBack();
    }
  };

  const handleDeleteIcon = (scenarioIndex: number) => {
    setScenarioDelete(true);
    setDeleteScenarioIndex(scenarioIndex);
  };

  const handleDeleteCancel = () => {
    setScenarioDelete(false);
    setDeleteScenarioIndex(-1);
  };

  const stringList = (value: string) => {
    let count =
      (value?.match(new RegExp("{string}", "g")) || []).length +
      (value?.match(new RegExp("{word}", "g")) || []).length +
      (value?.match(new RegExp("{int}", "g")) || []).length;
    let stringValues: string[] = [];
    while (count-- > 0) {
      stringValues = [...stringValues, ""];
    }
    return stringValues;
  };

  const handleAddStep = (data: any) => {
    setEditedFeatureData((prevFeatureData) => {
      const updatedFeatureData = { ...prevFeatureData };
      const background = { ...updatedFeatureData.background };
      const backgroundSteps = [...background.steps];
      const headers: string[] = [];
      const rows: string[][] = [];
      if (data.isChecked) {
        let columnCount = data.columns;
        let rowCount = data.rows;
        while (columnCount--) {
          headers.push("");
        }
        while (rowCount--) {
          rows.push(headers);
        }
      }
      const newDataTable: Datatable = { headers: headers, rows: rows };
      const newStep: Step = {
        keyword: data.keyword,
        text: data.text,
        values: stringList(data.text),
        datatable: data.isChecked ? newDataTable : null,
      };
      backgroundSteps.splice(popupStepIndex + 1, 0, newStep);
      // backgroundSteps.push(newStep);
      background.steps = backgroundSteps;
      updatedFeatureData.background = background;
      return updatedFeatureData;
    });
  };

  const handleAddIcon = (stepIndex: number) => {
    setPopupOpen(true);
    setPopupStepIndex(stepIndex);
  };

  const handleDeleteStep = (stepIndex: number) => {
    setEditedFeatureData((prevFeatureData) => {
      const updatedFeatureData = { ...prevFeatureData };
      const background = { ...updatedFeatureData.background };
      const backgroundSteps = [...background.steps];
      backgroundSteps.splice(stepIndex, 1);
      background.steps = backgroundSteps;
      updatedFeatureData.background = background;
      return updatedFeatureData;
    });
  };

  const handleOnChange = (
    value: string,
    stepIndex: number,
    stringValues: string[],
    dataTable: Datatable | null,
    keyword?: string
  ) => {
    setEditedFeatureData((prevFeatureData) => {
      const updatedFeatureData = { ...prevFeatureData };
      const backgroundSteps = [...updatedFeatureData.background.steps];
      if (stepIndex === -2) {
        updatedFeatureData.name = value;
      } else {
        if (stepIndex > -1) {
          if (keyword !== undefined)
            backgroundSteps[stepIndex].keyword = keyword;
          backgroundSteps[stepIndex].text = value;
          backgroundSteps[stepIndex].values = stringValues;
          backgroundSteps[stepIndex].datatable = dataTable;
        } else {
          updatedFeatureData.background.name = value;
        }
        updatedFeatureData.background = {
          ...updatedFeatureData.background,
          steps: backgroundSteps,
        };
      }
      return updatedFeatureData;
    });
  };

  const handleOnDataTableChange = (
    headers: string[],
    rows: string[][],
    stepIndex: number
  ) => {
    setEditedFeatureData((prevFeatureData) => {
      const updatedFeatureData = { ...prevFeatureData };
      const backgroundSteps = [...updatedFeatureData.background.steps];
      if (stepIndex > -1) {
        const step = updatedFeatureData.background.steps[stepIndex];
        if (step.datatable) {
          if (headers.length === 0 && rows.length === 0) {
            step.datatable = null;
          } else {
            step.datatable = {
              headers: [...headers],
              rows: [...rows],
            };
          }
        } else {
          step.datatable = {
            headers: [...headers],
            rows: [...rows],
          };
        }
        backgroundSteps[stepIndex] = step;
      }
      updatedFeatureData.background = {
        ...updatedFeatureData.background,
        steps: backgroundSteps,
      };
      return updatedFeatureData;
    });
  };

  const handleScenarioEdit = (scenarioIndex: number) => {
    setMode("edit");
    setCurrentScenarioIndex(scenarioIndex);
    editedFeatureData &&
      setScenario(editedFeatureData.scenarios[scenarioIndex]);
  };

  const handleScenarioDelete = () => {
    setEditedFeatureData((prevFeatureData) => {
      const updatedFeatureData = { ...prevFeatureData };
      const scenarios = [...updatedFeatureData.scenarios];
      scenarios.splice(deleteScenarioIndex, 1);
      updatedFeatureData.scenarios = scenarios;
      return updatedFeatureData;
    });
    setScenarioDelete(false);
    setDeleteScenarioIndex(-1);
  };

  const handleScenarioCopy = (scenarioIndex: number) => {
    setEditedFeatureData((prevFeatureData) => {
      const updatedFeatureData = { ...prevFeatureData };
      const scenarioList = [...updatedFeatureData.scenarios];
      const name = scenarioList[scenarioIndex].name;
      const tags = scenarioList[scenarioIndex].tags;
      const steps = scenarioList[scenarioIndex].steps;
      updatedFeatureData.scenarios.push({
        tags: tags,
        name: name,
        steps: steps,
      });
      return updatedFeatureData;
    });
  };

  const handleOnScenarioBack = () => {
    if (scenario !== null) {
      if (
        scenario.name !== "" &&
        scenario.tags.length !== 0 &&
        scenario.steps.length !== 0
      ) {
        setEditedFeatureData((prevFeatureData) => {
          const updatedFeatureData = { ...prevFeatureData };
          updatedFeatureData.scenarios[currentScenarioIndex] = scenario;
          return updatedFeatureData;
        });
      } else if (currentScenarioIndex !== 0) {
        setEditedFeatureData((prevFeatureData) => {
          const updatedFeatureData = { ...prevFeatureData };
          updatedFeatureData.scenarios.splice(currentScenarioIndex, 1);
          return updatedFeatureData;
        });
      }
    }
    setScenario(null);
    setCurrentScenarioIndex(-1);
  };

  const handleOnScenarioSave = (editedScenario: Scenario) => {
    setEditedFeatureData((prevFeatureData) => {
      const updatedFeatureData = { ...prevFeatureData };
      const scenarioList = [...updatedFeatureData.scenarios];
      scenarioList[currentScenarioIndex].name = editedScenario.name;
      scenarioList[currentScenarioIndex].steps = editedScenario.steps;
      scenarioList[currentScenarioIndex].tags = editedScenario.tags;

      updatedFeatureData.scenarios = [...scenarioList];
      return updatedFeatureData;
    });
    setScenario(null);
    setCurrentScenarioIndex(-1);
  };

  const handleOnAddNewScenario = () => {
    setEditedFeatureData((prevFeatureData) => {
      const updatedFeatureData = { ...prevFeatureData };
      updatedFeatureData.scenarios.push({
        tags: [],
        name: "",
        steps: [],
      });
      return updatedFeatureData;
    });
    handleScenarioEdit(editedFeatureData.scenarios.length - 1);
  };
  const handleCloseIcon = () => {
    setBackToFeatures(false);
  };
  const handleRunFeatureFile = () => {
    setRunPopup(true);
  };
  const handleRunPopupCancel = () => {
    setRunPopup(false);
  };
  const configTestLoadingOnBack = (booleanValue: boolean) => {
    setIsConfigTestLoading(booleanValue);
  };
  return (
    <>
      {!scenario && (
        <>
          <Box className={editfeaturestyles.editFeatureOuterBox}>
            <ReuseComponent
              Name="Module"
              Value={editedFeatureData.name}
              editable={true}
              onChange={(val: string) => {
                handleOnChange(val, -2, [], null);
              }}
            />
            {/* Background */}
            <Box className={editfeaturestyles.backgroundOuterBox}>
              <Box className={editfeaturestyles.backgroundInnerBox}>
                <ReuseComponent
                  key={"editBackground"}
                  Name="Background"
                  Value={editedFeatureData.background.name}
                  editable={true}
                  onChange={(val: string) => {
                    handleOnChange(val, -1, [], null);
                  }}
                />
                {editedFeatureData.background.steps.length === 0 && (
                  <IconButton onClick={() => handleAddIcon(-1)}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                )}
              </Box>
              {/* Background Steps */}
              <Box className={editfeaturestyles.backgroundStepsBox}>
                {editedFeatureData.background.steps.map((step, stepIndex) => (
                  <EditFeatureStep
                    key={`background_step_${stepIndex}`}
                    step={step}
                    stepIndex={stepIndex}
                    stepDefList={stepDefList}
                    handleOnChange={handleOnChange}
                    isLastIndex={
                      stepIndex ===
                      editedFeatureData.background.steps.length - 1
                    }
                    handleAddIcon={handleAddIcon}
                    handleDeleteStep={handleDeleteStep}
                    handleOnDataTableChange={handleOnDataTableChange}
                  />
                ))}
                {popupOpen && (
                  <Popup
                    isOpen={popupOpen}
                    menuList={stepDefList}
                    onClose={() => setPopupOpen(false)}
                    onDataSubmit={(data) => {
                      handleAddStep(data);
                    }}
                  />
                )}
              </Box>
            </Box>
            {/* Scenario's */}
            <Box className={editfeaturestyles.scenarioListBox}>
              {editedFeatureData.scenarios.map((Scenario, scenarioIndex) => (
                <Box
                  className={editfeaturestyles.scenarioBox}
                  key={`editfeature ${scenarioIndex}`}
                >
                  <Box className={editfeaturestyles.scenarioText}>
                    <Typography
                      sx={{ whiteSpace: "nowrap" }}
                      variant="h3"
                    >
                      Test Scenario {scenarioIndex + 1} :
                    </Typography>
                    <Typography>{Scenario.name}</Typography>
                  </Box>
                  <Box className={editfeaturestyles.scenarioBox}>
                    <IconButton
                      className={editfeaturestyles.svgIcon}
                      onClick={() => handleScenarioEdit(scenarioIndex)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      className={editfeaturestyles.svgIcon}
                      onClick={() => handleScenarioCopy(scenarioIndex)}
                    >
                      <FileCopyIcon />
                    </IconButton>
                    <IconButton
                      className={editfeaturestyles.svgIcon}
                      onClick={() => handleDeleteIcon(scenarioIndex)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}
              <Button
                variant="outlined"
                onClick={handleOnAddNewScenario}
                disabled={lodash.some(
                  editedFeatureData.scenarios,
                  (scenario) => {
                    return (
                      lodash.isEmpty(scenario.name) ||
                      lodash.isEmpty(scenario.tags) ||
                      lodash.isEmpty(scenario.steps)
                    );
                  }
                )}
              >
                Add Scenario
              </Button>
            </Box>
          </Box>
          {/* Action Button's */}
          <Box className={editfeaturestyles.actionButtonsBox}>
            <Button
              type="submit"
              variant="outlined"
              onClick={handleRunFeatureFile}
              // disabled={disable}
            >
              Run
            </Button>
            <Button
              type="submit"
              variant="outlined"
              onClick={handleOnSaveFeatureFile}
              disabled={disable}
            >
              save
            </Button>
            <Button
              type="submit"
              variant="outlined"
              onClick={handleBack}
            >
              Back
            </Button>
          </Box>
        </>
      )}
      {mode === "edit" && scenario !== null && (
        <EditScenario
          scenarioData={scenario}
          stepDefList={stepDefList}
          handleOnScenarioSave={handleOnScenarioSave}
          handleOnScenarioBack={handleOnScenarioBack}
        />
      )}
      {backToFeatures && !lodash.isEqual(featureData, editedFeatureData) && (
        <WarningPopup
          isOpen={true}
          onClose={handleCloseIcon}
          actionButtons={[
            {
              name: "Save",
              isDisabled: disable,
              onButtonClick: handleOnSaveFeatureFile,
            },
            {
              name: "Discard",
              onButtonClick: handleOnBack,
            },
          ]}
        >
          <Box className={editfeaturestyles.warningPopUpBox}>
            <WarningIcon />
            <Typography variant="body2">
              There are changes in the data do you want to save it.
            </Typography>
          </Box>
        </WarningPopup>
      )}
      {scenarioDelete && (
        <WarningPopup
          isOpen={true}
          onClose={handleDeleteCancel}
          actionButtons={[
            {
              name: "Confirm",
              onButtonClick: handleScenarioDelete,
            },
            {
              name: "Cancel",
              onButtonClick: handleDeleteCancel,
            },
          ]}
        >
          <Box className={editfeaturestyles.warningPopUpBox}>
            <WarningIcon />
            <Typography variant="body2">
              Do you want to delete the scenario.
            </Typography>
          </Box>
        </WarningPopup>
      )}
      {runPopup && (
        <WarningPopup
          isOpen={true}
          header="Execute Test"
          onClose={!isConfigTestLoading ? handleRunPopupCancel : undefined}
        >
          <Box sx={{ width: "35rem" }}>
            <RunForm
              featureFile={editedFeatureData}
              configTestLoadingOnBack={configTestLoadingOnBack}
            />
          </Box>
        </WarningPopup>
      )}
    </>
  );
};

export default EditFeature;
