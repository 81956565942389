import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

import userReducer from "./slices/userSlice";
import baseFilesReducer from "./slices/baseFilesSlice";
import userFilesReducer from "./slices/userFilesSlice";

const reducers = combineReducers({
  user: userReducer,
  baseFiles: baseFilesReducer,
  userFiles: userFilesReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const config = {
  blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
};
const synckStateMiddleware = [createStateSyncMiddleware(config)];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(synckStateMiddleware),
});

initMessageListener(store);

export const persistor = persistStore(store);
