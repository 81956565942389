import { Box, TextField, Typography } from "@mui/material";
import lodash from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getBaseFeatureFiles } from "src/redux/slices/baseFilesSlice";
import { getUserFeatureFiles } from "src/redux/slices/userFilesSlice";
import reusecomponentstyles from "./ReuseComponent.module.css";

interface ReuseComponentProps {
  Name: string;
  Value: string;
  editable?: boolean;
  onChange?: (val: string) => void;
}
const ReuseComponent = ({
  Name,
  Value,
  editable = false,
  onChange,
}: ReuseComponentProps) => {
  const basefiles = useSelector(getBaseFeatureFiles) || [];
  const userfiles = useSelector(getUserFeatureFiles) || [];
  const [value, setValue] = useState(Value);
  const disable =
    lodash.some(basefiles.baseFiles, (feature) => {
      return lodash.isEqual(feature.name, Value) && value !== Value;
    }) ||
    lodash.some(userfiles.userFiles, (feature) => {
      return lodash.isEqual(feature.name, Value) && value !== Value;
    });

  return (
    <Box className={reusecomponentstyles.reuseComponentOuterBox}>
      <Typography
        variant="h3"
        sx={{
          width:
            Name === "Given" || Name === "And" || Name === "Then"
              ? "45px"
              : "auto",
          whiteSpace: "nowrap",
        }}
        pl={1}
      >
        {Name}
      </Typography>
      {!editable && <Typography padding={1}>{Value}</Typography>}
      {editable && (
        <TextField
          id="outlined-textarea"
          size="small"
          value={Value}
          fullWidth
          variant="standard"
          onChange={(e) => {
            onChange && onChange(e.target.value);
          }}
          helperText={
            Name !== "Background" && Name !== "Test Scenario" && disable
              ? "The test script name is already present"
              : ""
          }
        />
      )}
    </Box>
  );
};

export default ReuseComponent;
