import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { jobTypes } from "src/types/ScheduleJobStatus";
import { configformdata } from "../../types/Configuretypes";
import { formatDateStr } from "../../utils/FormatDateStr";
import "./ScheduleForm.css";

interface ScheduleFormProps {
  configForm: configformdata;
  setConfigForm: Dispatch<SetStateAction<configformdata>>;
}
const ScheduleForm: React.FC<ScheduleFormProps> = ({
  configForm,
  setConfigForm,
}) => {
  const handleChipClick = (day: string) => {
    setConfigForm((prevData) => {
      const updatedDaysOfWeek = prevData.recurrenceDaysOfWeek.includes(day)
        ? prevData.recurrenceDaysOfWeek.filter((d) => d !== day)
        : [...prevData.recurrenceDaysOfWeek, day];
      return { ...prevData, recurrenceDaysOfWeek: updatedDaysOfWeek };
    });
  };

  useEffect(() => {
    configForm.scheduleJobId === null &&
      setConfigForm({
        ...configForm,
        jobStartDate: formatDateStr(new Date(), "YYYY-MM-DD", true),
        jobExecutionTime: formatDateStr(
          new Date(Date.now() + 10 * 60000),
          "YYYY-MM-DD HH:mm",
          true
        ),
        jobEndDate: formatDateStr(new Date(), "YYYY-MM-DD", true),
        recurrenceDaysOfWeek: [formatDateStr(new Date(), "ddd", true)],
        jobFrequency: formatDateStr(
          new Date(Date.now() - 10 * 3600000),
          "YYYY-MM-DD HH:mm",
          true
        ),
        jobType: "Weekly",
      });
  }, []);

  return (
    <form className="scheduling-form">
      <Box className="spacebetween">
        <Typography
          variant="h6"
          className="typography"
        >
          Frequency:
        </Typography>
        <FormControl sx={{ minWidth: "54%" }}>
          <Select
            size="small"
            required
            id="jobType"
            value={configForm.jobType}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
              justifyContent: "center",
              "& .MuiSelect-root": {
                alignSelf: "flex-start",
              },
            }}
            onChange={(e) =>
              setConfigForm({ ...configForm, jobType: e.target.value })
            }
          >
            {jobTypes.map((jobtype: string) => (
              <MenuItem
                key={jobtype}
                value={jobtype}
              >
                {jobtype}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box className="spacebetween">
        <Typography
          variant="h6"
          className="typography"
        >
          Job Start Date:
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            slotProps={{
              textField: { placeholder: "Job Start Date" },
            }}
            value={dayjs(configForm.jobStartDate)}
            onChange={(date: dayjs.Dayjs | null) => {
              setConfigForm({
                ...configForm,
                jobStartDate: date
                  ? formatDateStr(date.toDate(), "YYYY-MM-DD", true)
                  : null,
              });
            }}
            disablePast
            views={["year", "month", "day"]}
            sx={{
              "& .MuiInputBase-root": {
                height: "40px",
              },
            }}
          />
        </LocalizationProvider>
      </Box>
      <Box className="spacebetween">
        <Typography
          variant="h6"
          sx={{ display: "contents" }}
          className="typography"
        >
          Job Execution Time:
        </Typography>
        <span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              slotProps={{
                textField: { placeholder: "Job Execution Time" },
              }}
              value={
                configForm.jobExecutionTime
                  ? dayjs(configForm.jobExecutionTime)
                  : null
              }
              onChange={(timevalue) => {
                if (timevalue) {
                  setConfigForm({
                    ...configForm,
                    jobExecutionTime:
                      dayjs(timevalue).format("YYYY-MM-DD HH:mm"),
                  });
                }
              }}
              views={["hours", "minutes"]}
              disablePast={
                configForm.jobStartDate ===
                dayjs(new Date()).format("YYYY-MM-DD")
              }
              ampm={false}
              sx={{
                "& .MuiInputBase-root": {
                  height: "40px",
                },
              }}
            />
          </LocalizationProvider>
        </span>
      </Box>
      {configForm.jobType === jobTypes[0] && (
        <Box className="spacebetween">
          <Typography
            variant="h6"
            className="typography"
          >
            Interval (in HH:mm):
          </Typography>
          <span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                slotProps={{
                  textField: { placeholder: "Job Frequency" },
                }}
                value={
                  configForm.jobExecutionTime
                    ? dayjs(configForm.jobFrequency)
                    : null
                }
                onChange={(timevalue) => {
                  if (timevalue) {
                    setConfigForm({
                      ...configForm,
                      jobFrequency: dayjs(timevalue).format("YYYY-MM-DD HH:mm"),
                    });
                  }
                }}
                views={["hours", "minutes"]}
                ampm={false}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "40px",
                  },
                }}
              />
            </LocalizationProvider>
          </span>
        </Box>
      )}

      {configForm.jobType === jobTypes[2] && (
        <Box className="days-of-week">
          {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
            <Chip
              key={day}
              label={day}
              variant="outlined"
              className={`chip ${
                configForm.recurrenceDaysOfWeek.includes(day) ? "selected" : ""
              }`}
              onClick={() => handleChipClick(day)}
              clickable
            />
          ))}
        </Box>
      )}
      <Box className="spacebetween">
        <Typography
          variant="h6"
          className="typography"
        >
          Job End Date:
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            slotProps={{
              textField: { placeholder: "Job End Date" },
            }}
            disablePast
            views={["year", "month", "day"]}
            value={dayjs(configForm.jobEndDate)}
            onChange={(date: dayjs.Dayjs | null) => {
              setConfigForm({
                ...configForm,
                jobEndDate: date
                  ? formatDateStr(date.toDate(), "YYYY-MM-DD", true)
                  : null,
              });
            }}
            sx={{
              "& .MuiInputBase-root": {
                height: "40px",
              },
            }}
          />
        </LocalizationProvider>
      </Box>
    </form>
  );
};

export default ScheduleForm;
