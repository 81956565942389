import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import RunScenariosScreen from "src/components/Configure/RunScenariosScreen";
import ReportsScreen from "src/components/Reports/ReportsScreen";
import ScheduleScreen from "src/components/ScheduleJob/ScheduleScreen";
import ListOfClients from "src/components/admin/ListOfClients";
import Roles from "src/components/admin/Roles";
import Features from "src/components/features/Features";
import ChangePassword from "src/components/login/ChangePassword";
import { ForgotPassword } from "src/components/login/ForgotPassword";
import { Login } from "src/components/login/Login";
import { ResetPassword } from "src/components/login/ResetPassword";
import NotFound from "src/components/modal/NotFound";
import Publish from "src/components/publish/Publish";
import Review from "src/components/review/Review";
import { selectUser } from "src/redux/slices/userSlice";

export interface RouteItem {
  navItemId: number;
  label: string;
  path: string;
  permissions: boolean;
  element: React.ReactNode;
}

const RouteItems = () => {
  const user = useSelector(selectUser);
  const AuthorizedData: RouteItem[] = [
    {
      navItemId: 1,
      label: "Test Scripts",
      path: "/features",
      element: <Features />,
      permissions: user?.permissionsList.includes("VIEW FEATURE"),
    },
    {
      navItemId: 2,
      label: "Publish",
      path: "/publish",
      element: <Publish />,
      permissions: user?.permissionsList.includes("PUBLISH FEATURE"),
    },
    {
      navItemId: 3,
      label: "Review",
      path: "/review",
      element: <Review />,
      permissions: user?.permissionsList.includes("REVIEW FEATURE"),
    },
    {
      navItemId: 4,
      label: "Test Run",
      path: "/configure",
      element: <RunScenariosScreen />,
      permissions: user?.permissionsList.includes("EXECUTE FEATURE"),
    },
    {
      navItemId: 5,
      label: "Reports",
      path: "/reports",
      element: <ReportsScreen />,
      permissions: user?.permissionsList.includes("VIEW REPORTS"),
    },
    {
      navItemId: 5,
      label: "Schedule",
      path: "/schedule",
      element: <ScheduleScreen />,
      permissions: user?.permissionsList.includes("VIEW SCHEDULES"),
    },
    {
      navItemId: 6,
      label: "Set-Up",
      path: "/listOfClients",
      element: <ListOfClients />,
      permissions: user?.permissionsList.includes("VIEW CONFIG DATA"),
    },
    {
      navItemId: 7,
      label: "Roles and Permissions",
      path: "/rolesAndPermissions",
      element: <Roles />,
      permissions: user?.permissionsList.includes("ROLES AND PERMISSIONS"),
    },
    {
      navItemId: 8,
      label: "Change Password",
      path: "/changePassword",
      element: <ChangePassword />,
      permissions: user !== null,
    },
    {
      navItemId: 9,
      label: "featureDefault",
      path: "/",
      element: <Navigate to="/features" />,
      permissions: user?.permissionsList.includes("VIEW FEATURE"),
    },
    {
      navItemId: 10,
      label: "reviewDefault",
      path: "/",
      element: <Navigate to="/review" />,
      permissions: user?.permissionsList.includes("REVIEW FEATURE"),
    },
    // {
    //   navItemId: 11,
    //   label: "editUser",
    //   path: "/editUser",
    //   element: <EditUser />,
    //   permissions: user?.role === "USER" || user?.role === "ADMIN",
    // },
    {
      navItemId: 12,
      label: "notFound",
      path: "/*",
      element: <NotFound />,
      permissions: true,
    },
  ];
  const UnAuthorizedData: RouteItem[] = [
    {
      navItemId: 1,
      label: "Login",
      path: "/login",
      element: <Login />,
      permissions: user === null,
    },
    {
      navItemId: 2,
      label: "Forgot Password",
      path: "/forgotPassword",
      element: <ForgotPassword />,
      permissions: user === null,
    },
    {
      navItemId: 3,
      label: "Reset Password",
      path: "/resetPassword",
      element: <ResetPassword />,
      permissions: user === null,
    },
    {
      navItemId: 4,
      label: "LoginPath",
      path: "/*",
      element: <Navigate to="/login" />,
      permissions: user === null,
    },
  ];
  if (user === null) return UnAuthorizedData;
  else return AuthorizedData;
};

export default RouteItems;
