import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import WarningPopup from "src/components/modal/WarningPopup";
import { Datatable, Step } from "../../../types/Feature";
import EditTable from "../../templates/EditTable";
import ReuseComponentDropDown from "../ReuseComponentDropDown";
import editfeaturestepstyles from "./EditFeatureStep.module.css";

interface EditFeatureStepProps {
  stepIndex: number;
  step: Step;
  stepDefList: string[];
  handleOnChange: (
    value: string,
    stepIndex: number,
    stringValues: string[],
    dataTable: Datatable | null,
    keyword?: string
  ) => void;
  isLastIndex: boolean;
  handleAddIcon: (stepIndex: number) => void;
  handleDeleteStep: (stepIndex: number) => void;
  handleOnDataTableChange: (
    headers: string[],
    rows: string[][],
    stepIndex: number
  ) => void;
}

const EditFeatureStep = ({
  stepIndex,
  step,
  stepDefList,
  handleOnChange,
  handleAddIcon,
  handleDeleteStep,
  handleOnDataTableChange,
}: EditFeatureStepProps) => {
  const [currentDataTable, setcurrentDataTable] = useState<Datatable | null>(
    step.datatable
  );
  const [dataTableEdit, setDataTableEdit] = useState<boolean>(false);
  const [radioValue, setradioValue] = useState<string>("ADD");
  const [isRowChecked, setIsRowChecked] = useState<boolean>(false);
  const [rowPosition, setRowPosition] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [isColumnChecked, setIsColumnChecked] = useState<boolean>(false);
  const [columnPosition, setColumnPosition] = useState<number>(0);
  const [columnCount, setColumnCount] = useState<number>(0);
  const [selectedAction, setSelectedAction] = useState<string | null>(
    "Select Action"
  );
  useEffect(() => {
    setSelectedAction("Select Action");
  }, [selectedAction]);
  useEffect(() => {
    setcurrentDataTable(step.datatable);
  }, [step.datatable]);
  const handleDataTableAdd = () => {
    if (isRowChecked && isColumnChecked) {
      if (currentDataTable === null) {
        const newRows = Array.from({ length: rowCount }, () => {
          return Array.from({ length: columnCount }, () => "");
        });
        const headers = Array.from({ length: columnCount }, () => "");
        setcurrentDataTable({
          headers: headers,
          rows: newRows,
        });
        handleOnDataTableChange(headers, newRows, stepIndex);
      } else {
        const currentRowList = currentDataTable?.rows ?? [];
        const currentColumnList = currentDataTable?.headers ?? [];
        if (rowPosition >= 0 && rowPosition <= currentRowList.length + 1) {
          const newRows = Array.from({ length: rowCount }, () => {
            return Array.from(
              { length: currentDataTable?.headers.length ?? 0 },
              () => ""
            );
          });
          const currentRowListCopy = [...currentRowList];
          currentRowListCopy.splice(
            rowPosition === 0 || Number.isNaN(rowPosition)
              ? currentRowList.length
              : rowPosition - 1,
            0,
            ...newRows
          );
          const updatedRowList = currentRowListCopy.map((row) =>
            row.slice(0, currentDataTable?.headers.length ?? 0)
          );
          if (
            columnPosition >= 0 &&
            columnPosition <= currentColumnList.length + 1
          ) {
            const finalUpdatedRowList = updatedRowList.map((row) => [
              ...row.slice(
                0,
                columnPosition === 0 || Number.isNaN(columnPosition)
                  ? currentColumnList.length
                  : columnPosition - 1
              ),
              ...Array(columnCount).fill(""),
              ...row.slice(
                columnPosition === 0 || Number.isNaN(columnPosition)
                  ? currentColumnList.length
                  : columnPosition - 1
              ),
            ]);
            const updatedColumnList = [
              ...currentColumnList.slice(
                0,
                columnPosition === 0 || Number.isNaN(columnPosition)
                  ? currentColumnList.length
                  : columnPosition - 1
              ),
              ...Array(columnCount).fill(""),
              ...currentColumnList.slice(
                columnPosition === 0 || Number.isNaN(columnPosition)
                  ? currentColumnList.length
                  : columnPosition - 1
              ),
            ];
            setcurrentDataTable({
              headers: updatedColumnList,
              rows: finalUpdatedRowList,
            });
            handleOnDataTableChange(
              updatedColumnList,
              finalUpdatedRowList,
              stepIndex
            );
          } else {
            toast.error(
              "Column Position should in the limit i.e., 1-" +
                (currentColumnList.length + 1)
            );
          }
        } else {
          toast.error(
            "Row Position should in the limit i.e., 1-" +
              (currentRowList.length + 1)
          );
        }
      }
    } else if (isRowChecked) {
      const currentRowList = currentDataTable?.rows ?? [];
      if (rowPosition >= 0 && rowPosition <= currentRowList.length + 1) {
        const newRows = Array.from({ length: rowCount }, () => {
          return Array.from(
            { length: currentDataTable?.headers.length ?? 0 },
            () => ""
          );
        });
        const currentRowListCopy = [...currentRowList];
        currentRowListCopy.splice(
          rowPosition === 0 || Number.isNaN(rowPosition)
            ? currentRowList.length
            : rowPosition - 1,
          0,
          ...newRows
        );
        const updatedRowList = currentRowListCopy.map((row) =>
          row.slice(0, currentDataTable?.headers.length ?? 0)
        );
        setcurrentDataTable({
          headers: currentDataTable?.headers ?? [],
          rows: updatedRowList,
        });
        handleOnDataTableChange(
          currentDataTable?.headers ?? [],
          updatedRowList,
          stepIndex
        );
      } else {
        toast.error(
          "Row Position should in the limit i.e., 1-" +
            (currentRowList.length + 1)
        );
      }
    } else if (isColumnChecked) {
      const currentColumnList = currentDataTable?.headers ?? [];
      const currentRowList = currentDataTable?.rows ?? [];
      if (
        columnPosition >= 0 &&
        columnPosition <= currentColumnList.length + 1
      ) {
        const updatedRowList = currentRowList.map((row) => [
          ...row.slice(
            0,
            columnPosition === 0 || Number.isNaN(columnPosition)
              ? currentColumnList.length
              : columnPosition - 1
          ),
          ...Array(columnCount).fill(""),
          ...row.slice(
            columnPosition === 0 || Number.isNaN(columnPosition)
              ? currentColumnList.length
              : columnPosition - 1
          ),
        ]);
        const updatedColumnList = [
          ...currentColumnList.slice(
            0,
            columnPosition === 0 || Number.isNaN(columnPosition)
              ? currentColumnList.length
              : columnPosition - 1
          ),
          ...Array(columnCount).fill(""),
          ...currentColumnList.slice(
            columnPosition === 0 || Number.isNaN(columnPosition)
              ? currentColumnList.length
              : columnPosition - 1
          ),
        ];
        setcurrentDataTable({
          headers: updatedColumnList,
          rows: updatedRowList,
        });
        handleOnDataTableChange(updatedColumnList, updatedRowList, stepIndex);
      } else {
        toast.error(
          "Column Position should in the limit i.e., 1-" +
            (currentColumnList.length + 1)
        );
      }
    }
    setDataTableEdit(false);
    setIsColumnChecked(false);
    setIsRowChecked(false);
    setradioValue("ADD");
    setColumnPosition(0);
    setRowPosition(0);
    setRowCount(0);
    setColumnCount(0);
  };
  const handleDataTableDelete = () => {
    if (radioValue === "DELETE ALL") {
      setcurrentDataTable(null);
      handleOnDataTableChange([], [], stepIndex);
    } else {
      if (isRowChecked && isColumnChecked) {
        const currentRowList = currentDataTable?.rows ?? [];
        if (currentRowList.length - rowCount > 0) {
          if (rowPosition > 0 && rowPosition <= currentRowList.length) {
            if (rowPosition + rowCount - 1 <= currentRowList.length) {
              const currentRowListCopy = currentRowList.map((row) => [...row]);
              currentRowListCopy.splice(rowPosition - 1, rowCount);
              const currentColumnList = currentDataTable?.headers ?? [];
              if (currentColumnList.length - columnCount > 0) {
                if (
                  columnPosition > 0 &&
                  columnPosition <= currentColumnList.length
                ) {
                  if (
                    columnPosition + columnCount - 1 <=
                    currentColumnList.length
                  ) {
                    currentColumnList.splice(columnPosition - 1, columnCount);
                    currentRowListCopy.forEach((row) => {
                      row.splice(columnPosition - 1, columnCount);
                    });
                    setcurrentDataTable({
                      headers: currentColumnList,
                      rows: currentRowListCopy,
                    });
                    handleOnDataTableChange(
                      currentColumnList,
                      currentRowListCopy,
                      stepIndex
                    );
                  } else {
                    toast.error(
                      "Column Count should not exceed it's limit i.e.," +
                        (currentColumnList.length - columnPosition + 1)
                    );
                  }
                } else {
                  toast.error(
                    "Column Position should in the limit i.e., 1-" +
                      currentColumnList.length
                  );
                }
              } else {
                toast.error(
                  "Column deletion not allowed as column count (" +
                    columnCount +
                    ") exceeded it's limit"
                );
              }
            } else {
              toast.error(
                "Row Count should not exceed it's limit i.e.," +
                  (currentRowList.length - rowPosition + 1)
              );
            }
          } else {
            toast.error(
              "Row Position should in the limit i.e., 1-" +
                currentRowList.length
            );
          }
        } else {
          toast.error(
            "Row deletion not allowedas row count (" +
              rowCount +
              ") exceeded it's limit"
          );
        }
      } else if (isRowChecked) {
        const currentRowList = currentDataTable?.rows ?? [];
        if (currentRowList.length - rowCount > 0) {
          if (rowPosition > 0 && rowPosition <= currentRowList.length) {
            if (rowPosition + rowCount - 1 <= currentRowList.length) {
              const currentRowListCopy = [...currentRowList];
              currentRowListCopy.splice(rowPosition - 1, rowCount);
              setcurrentDataTable({
                headers: currentDataTable?.headers ?? [],
                rows: currentRowListCopy,
              });
              handleOnDataTableChange(
                currentDataTable?.headers ?? [],
                currentRowListCopy,
                stepIndex
              );
            } else {
              toast.error(
                "Row Count should not exceed it's limit i.e.," +
                  (currentRowList.length - rowPosition + 1)
              );
            }
          } else {
            toast.error(
              "Row Position should in the limit i.e., 1-" +
                currentRowList.length
            );
          }
        } else {
          toast.error(
            "Row deletion not allowed as row count (" +
              rowCount +
              ") exceeded it's limit"
          );
        }
      } else if (isColumnChecked) {
        const currentColumnList = currentDataTable?.headers ?? [];
        const currentRowList = currentDataTable?.rows ?? [];
        if (currentColumnList.length - columnCount > 0) {
          if (
            columnPosition > 0 &&
            columnPosition <= currentColumnList.length
          ) {
            if (columnPosition + columnCount - 1 <= currentColumnList.length) {
              const currentRowListCopy = currentRowList.map((row) => [...row]);
              const currentColumnListCopy = [...currentColumnList];
              currentColumnListCopy.splice(columnPosition - 1, columnCount);
              currentRowListCopy.forEach((row) => {
                row.splice(columnPosition - 1, columnCount);
              });
              setcurrentDataTable({
                headers: currentColumnListCopy,
                rows: currentRowListCopy,
              });
              handleOnDataTableChange(
                currentColumnListCopy,
                currentRowListCopy,
                stepIndex
              );
            } else {
              toast.error(
                "Column Count should not exceed the limit i.e.," +
                  (currentColumnList.length - columnPosition + 1)
              );
            }
          } else {
            toast.error(
              "Column Position should in the limit i.e., 1-" +
                currentColumnList.length
            );
          }
        } else {
          toast.error(
            "Column deletion not allowed as column count (" +
              columnCount +
              ") exceeded it's limit"
          );
        }
      }
    }
    setDataTableEdit(false);
    setIsColumnChecked(false);
    setIsRowChecked(false);
    setradioValue("ADD");
    setColumnPosition(0);
    setRowPosition(0);
    setRowCount(0);
    setColumnCount(0);
  };
  const handleDataTableCancel = () => {
    setDataTableEdit(false);
    setradioValue("ADD");
    setColumnCount(0);
    setColumnPosition(0);
    setRowCount(0);
    setRowPosition(0);
  };
  const handleActionDropDown = (
    e: SelectChangeEvent<any>,
    stepIndex: number
  ) => {
    setSelectedAction(e.target.value);
    if (e.target.value === "add") {
      handleAddIcon(stepIndex);
    } else if (e.target.value === "delete") {
      handleDeleteStep(stepIndex);
    } else if (e.target.value === "table") {
      setDataTableEdit(true);
    }
  };
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Box
          className={editfeaturestepstyles.editFeatureStepOuterBox}
          key={`editstep__${stepIndex}`}
        >
          <ReuseComponentDropDown
            key={stepIndex}
            keyword={step.keyword}
            Value={step.text}
            stepIndex={stepIndex}
            StringValues={step.values}
            stepDefList={stepDefList}
            DataTable={step.datatable}
            onChange={handleOnChange}
          />
          <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <IconButton
              className="dropdownbutton"
              onClick={() => setDataTableEdit(true)}
            >
              <AppRegistrationIcon />
            </IconButton>
            <IconButton
              className="dropdownbutton"
              onClick={() => handleDeleteStep(stepIndex)}
            >
              <RemoveOutlinedIcon />
            </IconButton>
            <IconButton
              className="dropdownbutton"
              onClick={() => handleAddIcon(stepIndex)}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
        </Box>
        {step.datatable != null && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginLeft: "3.8%",
            }}
          >
            <Box className={editfeaturestepstyles.editFeatureStepInnerBox}>
              <Typography
                variant="h3"
                sx={{ whiteSpace: "nowrap" }}
              >
                Test Data
              </Typography>
              <Box className={editfeaturestepstyles.datatableBox}>
                <EditTable
                  headers={step.datatable.headers}
                  rows={step.datatable.rows}
                  onEdit={(headers: string[], rows: string[][]) => {
                    handleOnDataTableChange(headers, rows, stepIndex);
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {dataTableEdit && (
        <WarningPopup
          isOpen={true}
          onClose={handleDataTableCancel}
          actionButtons={[
            {
              name: "Add",
              onButtonClick: handleDataTableAdd,
              isDisabled:
                radioValue !== "ADD" ||
                (!currentDataTable && (!isRowChecked || !isColumnChecked)) ||
                (radioValue === "ADD" && !isColumnChecked && !isRowChecked) ||
                (isRowChecked && (rowCount === 0 || Number.isNaN(rowCount))) ||
                (isColumnChecked &&
                  (columnCount === 0 || Number.isNaN(columnCount))),
            },
            {
              name: "Delete",
              onButtonClick: handleDataTableDelete,
              isDisabled:
                radioValue === "ADD" ||
                (radioValue === "DELETE" &&
                  !isColumnChecked &&
                  !isRowChecked) ||
                (isRowChecked &&
                  (rowCount === 0 ||
                    Number.isNaN(rowCount) ||
                    rowPosition === 0 ||
                    Number.isNaN(rowPosition))) ||
                (isColumnChecked &&
                  (columnCount === 0 ||
                    Number.isNaN(columnCount) ||
                    columnPosition === 0 ||
                    Number.isNaN(columnPosition))),
            },
            {
              name: "Cancel",
              onButtonClick: handleDataTableCancel,
            },
          ]}
        >
          <Box>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                focused
              >
                Action
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e) => setradioValue(e.target.value)}
                value={radioValue}
              >
                <FormControlLabel
                  value="ADD"
                  control={<Radio />}
                  label="ADD"
                />
                <FormControlLabel
                  value="DELETE"
                  control={
                    <Radio
                      disabled={!currentDataTable}
                      className="radiosvg"
                    />
                  }
                  label="DELETE"
                />
                <FormControlLabel
                  value="DELETE ALL"
                  control={
                    <Radio
                      disabled={!currentDataTable}
                      className="radiosvg"
                    />
                  }
                  label="DELETE ALL"
                />
              </RadioGroup>
            </FormControl>
            <Divider />
            {radioValue !== "DELETE ALL" ? (
              <Box className={editfeaturestepstyles.selectBox}>
                <Box className={editfeaturestepstyles.selectOutterBox}>
                  <Box className={editfeaturestepstyles.selectInnerLeft}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => setIsRowChecked(e.target.checked)}
                        />
                      }
                      label="ROW"
                    />
                  </Box>
                  <Box className={editfeaturestepstyles.selectInnerRight}>
                    <TextField
                      id="outlined-number"
                      placeholder="Row position"
                      type="number"
                      size="small"
                      onChange={(e) => {
                        setRowPosition(parseInt(e.target.value, 10));
                      }}
                      disabled={!isRowChecked || !currentDataTable}
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                      value={
                        currentDataTable === null
                          ? 1
                          : rowPosition === 0
                            ? ""
                            : rowPosition
                      }
                    />
                    <TextField
                      id="outlined-number"
                      placeholder="no.of rows"
                      type="number"
                      size="small"
                      onChange={(e) => {
                        setRowCount(parseInt(e.target.value, 10));
                      }}
                      disabled={!isRowChecked}
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                    />
                  </Box>
                </Box>
                <Box className={editfeaturestepstyles.selectOutterBox}>
                  <Box className={editfeaturestepstyles.selectInnerLeft}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => setIsColumnChecked(e.target.checked)}
                        />
                      }
                      label="COLUMN"
                    />
                  </Box>
                  <Box className={editfeaturestepstyles.selectInnerRight}>
                    <TextField
                      id="outlined-number"
                      placeholder="Column position"
                      type="number"
                      size="small"
                      onChange={(e) => {
                        setColumnPosition(parseInt(e.target.value, 10));
                      }}
                      disabled={!isColumnChecked || !currentDataTable}
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                      value={
                        currentDataTable === null
                          ? 1
                          : columnPosition === 0
                            ? ""
                            : columnPosition
                      }
                    />
                    <TextField
                      id="outlined-number"
                      placeholder="no.of columns"
                      type="number"
                      size="small"
                      onChange={(e) => {
                        setColumnCount(parseInt(e.target.value, 10));
                      }}
                      disabled={!isColumnChecked}
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            ) : (
              <Typography
                variant="body2"
                sx={{ paddingTop: "8px" }}
              >
                Do you like to delete complete table.
              </Typography>
            )}
          </Box>
        </WarningPopup>
      )}
    </>
  );
};

export default EditFeatureStep;
