import { HttpMethod } from "src/types/HttpMethod";
import { ClientData } from "src/types/UserData";

const apiUrl = `${process.env.REACT_APP_BASE_URL}/rs/automation/client`;

export const getAllClients = async (username: string, password: string) => {
  const url = `${apiUrl}/getAllClientData`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Get,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
    },
  });
};

export const getClientData = async (
  username: string,
  password: string,
  clientCode: string
) => {
  const url = `${apiUrl}/${clientCode}/getClientData`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Get,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
    },
  });
};

export const saveClient = async (
  clientData: ClientData,
  username: string,
  password: string
) => {
  const url = `${apiUrl}/saveClientData`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(clientData),
  });
};

export const clientStatusUpdate = async (
  clientData: ClientData,
  username: string,
  password: string
) => {
  const url = `${apiUrl}/clientStatusUpdate`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(clientData),
  });
};

export const setUpClient = async (
  clientCode: string,
  username: string,
  password: string
) => {
  const url = `${apiUrl}/ftp/setup?clientCode=${encodeURIComponent(
    clientCode
  )}`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
      "Content-Type": "application/json",
    },
  });
};
