import { TextField } from "@mui/material";

interface EditTableProps {
  headers: string[];
  rows: string[][];
  onEdit: (headers: string[], rows: string[][]) => void;
}

const EditTable = ({ headers, rows, onEdit }: EditTableProps) => {
  let headerData: string[] = [...headers];
  let rowData: string[][] = rows.map((innerArray) => [...innerArray]);

  const handleOnColumnEdit = (
    rowIndex: number,
    colIndex: number,
    val: string
  ) => {
    if (rowIndex === -1) {
      headerData[colIndex] = val;
    } else {
      rowData[rowIndex][colIndex] = val;
    }
    onEdit(headerData, rowData);
  };

  return (
    <table>
      <thead>
        <tr>
          {headers.map((header, colIndex) => (
            <th key={`header_${colIndex}`}>
              <TextField
                variant="standard"
                size="small"
                placeholder="Header"
                InputProps={{
                  style: {
                    background: "#faefef",
                    padding: "8px",
                    borderRadius: "4px",
                  },
                }}
                sx={{
                  width: "max-content",
                }}
                key={`header_${colIndex}`}
                type={"text"}
                value={header}
                onChange={(e) =>
                  handleOnColumnEdit(-1, colIndex, e.target.value)
                }
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={`row_${rowIndex}`}>
            {row.map((column, colIndex) => (
              <td key={`cell_${rowIndex}_${colIndex}`}>
                <TextField
                  variant="standard"
                  size="small"
                  key={`row_col_${rowIndex}_${colIndex}`}
                  type={"text"}
                  InputProps={{
                    style: {
                      backgroundColor: "#e0e0e08a",
                      padding: "8px",
                      borderRadius: "4px",
                    },
                  }}
                  value={column}
                  onChange={(e) =>
                    handleOnColumnEdit(rowIndex, colIndex, e.target.value)
                  }
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EditTable;
