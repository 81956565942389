import { HttpMethod } from "../types/HttpMethod";

const apiUrl = `${process.env.REACT_APP_BASE_URL}/rs/automation/process`;

export const checkinprogressrecords = (auth: string, username: string) => {
  let url = `${apiUrl}/checkInProgressRecords?username=${username}`;
  return fetch(url, {
    method: HttpMethod.Get,
    headers: { Authorization: auth },
  });
};
