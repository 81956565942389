import { Box, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Datatable } from "../../types/Feature";
import { stepItem } from "../../utils/Common";
import Popup from "./Popup";
import reusedropdownstyles from "./ReuseComponentDropDown.module.css";

interface ReuseComponentDropDownProps {
  keyword: string;
  Value: string;
  stepIndex: number;
  StringValues: string[];
  DataTable: Datatable | null;
  stepDefList: string[];
  onChange: (
    value: string,
    stepInder: number,
    stringValue: string[],
    dataTable: Datatable | null,
    keyword?: string
  ) => void;
}

const ReuseComponentDropDown = ({
  Value,
  stepIndex,
  StringValues,
  DataTable,
  stepDefList,
  keyword,
  onChange,
}: ReuseComponentDropDownProps) => {
  let dataTable: Datatable | null = DataTable;
  let stringValues: string[] = [...StringValues];
  const [popupOpen, setPopupOpen] = useState(false);
  const [updatedDropdownValue, setUpdatedDropdownValue] = useState("");

  const handleTextOnChange = (newTextValue: string, index: number) => {
    stringValues[index] = newTextValue;
    onChange(Value, stepIndex, stringValues, dataTable);
  };

  const handleDropdownOnChange = (newDropdownValue: string) => {
    setPopupOpen(true);
    setUpdatedDropdownValue(newDropdownValue);
  };

  const handleDataChange = (data: any) => {
    keyword = data.keyword;
    Value = data.text;
    let count =
      (Value?.match(new RegExp("{string}", "g")) || []).length +
      (Value?.match(new RegExp("{word}", "g")) || []).length +
      (Value?.match(new RegExp("{int}", "g")) || []).length;
    stringValues = [];
    while (count-- > 0) {
      stringValues = [...stringValues, ""];
    }
    const headers: string[] = [];
    const rows: string[][] = [];
    if (data.isChecked) {
      let columnCount = data.columns;
      let rowCount = data.rows;
      while (columnCount--) {
        headers.push("");
      }
      while (rowCount--) {
        rows.push(headers);
      }
    }
    const newDataTable: Datatable = { headers: headers, rows: rows };
    dataTable = data.isChecked ? newDataTable : null;
    onChange(Value, stepIndex, stringValues, dataTable, keyword);
  };

  return (
    <>
      <Box className={reusedropdownstyles.reuseDropDownOuterBox}>
        <Box className={reusedropdownstyles.reuseDropDownInnerBox}>
          <Typography
            variant="h3"
            sx={{ width: "40px" }}
          >
            {keyword}
          </Typography>
          <Select
            fullWidth
            size="small"
            onChange={(e) => handleDropdownOnChange(e.target.value)}
            value={Value}
            variant="standard"
          >
            {stepDefList.map((stepDef, stepDefIndex) => (
              <MenuItem
                key={stepDefIndex}
                value={stepDef}
              >
                {stepItem(stepDef)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className={reusedropdownstyles.reuseDropDownTectFieldBox}>
          {stringValues.map((stringValue, index) => (
            <TextField
              className={reusedropdownstyles.reuseTextFieldCss}
              key={index}
              variant="standard"
              // size="small"
              InputProps={{
                style: {
                  // padding: "4px",
                  paddingTop: "6px",
                  borderRadius: "4px",
                },
              }}
              onChange={(e) => handleTextOnChange(e.target.value, index)}
              value={stringValue}
              placeholder="Enter Value"
              fullWidth
            />
          ))}
        </Box>
      </Box>
      {popupOpen && (
        <Popup
          isOpen={popupOpen}
          menuList={stepDefList}
          keywordName={keyword}
          textName={updatedDropdownValue}
          onClose={() => setPopupOpen(false)}
          onDataSubmit={(data) => {
            handleDataChange(data);
          }}
        />
      )}
    </>
  );
};

export default ReuseComponentDropDown;
