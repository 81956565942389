import { HttpMethod } from "src/types/HttpMethod";
import { RolesAndPermissions } from "src/types/RolesAndPermissions";

const apiUrl = `${process.env.REACT_APP_BASE_URL}/rs/automation/rolePermissions`;

export const getAllRoles = (username: string, password: string) => {
  const url = `${apiUrl}/getAllRoles`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Get,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
    },
  });
};

export const getPermissionsByRoleId = (
  username: string,
  password: string,
  roleId: number
) => {
  const url = `${apiUrl}/getPermissionsByRoleId/${roleId}`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Get,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
    },
  });
};

export const saveRolesAndPermissions = (
  username: string,
  password: string,
  editedRolesAndPermissions: RolesAndPermissions[]
) => {
  const url = `${apiUrl}/saveRolePermissions`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(editedRolesAndPermissions),
  });
};

export const addRole = (
  username: string,
  password: string,
  roleName: string
) => {
  const url = `${apiUrl}/addRole?roleName=${roleName}`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
      "Content-Type": "application/json",
    },
  });
};

export const deleteRoles = (
  username: string,
  password: string,
  roleIdList: number[]
) => {
  const url = `${apiUrl}/deleteRole`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Delete,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(roleIdList),
  });
};
