import { Box } from "@mui/material";
import footerStyle from "./Footer.module.css";

const Footer = () => {
  return (
    <Box className={footerStyle.copyright}>
      © {new Date().getFullYear()} {"Red-Shift"}
    </Box>
  );
};

export default Footer;
