import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import PauseIcon from "@mui/icons-material/Pause";
import PlayCircleFilled from "@mui/icons-material/PlayCircleFilled";
import ReplayIcon from "@mui/icons-material/Replay";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { getOrgs } from "src/types/Organizations";
import { Report } from "src/types/Report";
import { jobTypes, status } from "src/types/ScheduleJobStatus";
import {
  deleteJob,
  getScheduledJobs,
  pause,
  resume,
} from "../../clients/ScheduleJobService";
import { selectUser } from "../../redux/slices/userSlice";
import { ScheduleJobTypes } from "../../types/ScheduleJobTypes";
import { formatDateStr } from "../../utils/FormatDateStr";
import { convertUTCtoLocal } from "../../utils/convertUTCtoLocal";
import { useInterval } from "../../utils/useInterval";
import reportScreenStylings from "../Reports/ReportsScreen.module.css";
import Pagination from "../templates/Pagination";

const ScheduleScreen: React.FC = () => {
  const user = useSelector(selectUser);
  const scheduleFormData: ScheduleJobTypes = {
    updatedBy: "",
    scheduleJobId: null,
    updatedTimeStamp: "",
    reportUser: user.userId,
    createdTimeStamp: "",
    tags: "",
    countryCode: "",
    status: "",
    jobDescription: null,
    recipientsEmailIds: null,
    scheduleJobStartDateTime: null,
    scheduleJobEndDate: null,
    recurrenceDaysOfWeek: null,
    jobType: "",
    jobFrequency: null,
    userSpecificScheduleJobId: null,
    deletedFlag: false,
    productCode: user.productCode,
    clientCode: user.clientCode,
  };
  const reportFilterData: Report = {
    updatedBy: "",
    runId: null,
    jobId: 0,
    updatedTimeStamp: "",
    reportUser: user.userId,
    createdTimeStamp: "",
    tags: "",
    reportStartDate: null,
    reportEndDate: null,
    dockerContainerName: "",
    countryCode: "",
    status: "",
    jobDescription: null,
    productCode: user.productCode,
    clientCode: user.clientCode,
    scheduleJob: scheduleFormData,
  };

  const [scheduleJobRecords, setScheduleJobRecords] = useState<
    ScheduleJobTypes[] | undefined
  >(undefined);
  const [clearTriger, setclearTriger] = useState<boolean>(false);
  const [applybutton, setapplybutton] = useState<boolean>(false);
  const [filterScheduleJobRecords, setFilterScheduleJobRecords] =
    useState<ScheduleJobTypes>(scheduleFormData);
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState("5");
  const [currentPageScheduleScreen, setCurrentPageScheduleScreen] = useState(1);
  const reportsPerPage = Number(rows);
  const indexOfLastScheduleRecord = currentPageScheduleScreen * reportsPerPage;
  const indexOfFirstScheduleRecord = indexOfLastScheduleRecord - reportsPerPage;
  const currentScheduleRecords = scheduleJobRecords?.slice(
    indexOfFirstScheduleRecord,
    indexOfLastScheduleRecord
  );
  const navigate = useNavigate();
  const fetchScheduleJobsData = async (scheduleFormData: ScheduleJobTypes) => {
    try {
      const response = await getScheduledJobs(user.auth, scheduleFormData);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      data.reports.sort(
        (a: Report, b: Report) =>
          new Date(b.createdTimeStamp ?? "").getTime() -
          new Date(a.createdTimeStamp ?? "").getTime()
      );
      const filterData = data.reports.filter(
        (job: ScheduleJobTypes) => job.deletedFlag === false
      );
      for (let i = 0; i < data.reports.length; i++) {}
      setScheduleJobRecords(filterData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (event: any) => {
    setRows(event.target.value);
    setCurrentPageScheduleScreen(1);
  };

  const [rendercount, setRenderCount] = useState<number>(0);

  const handleapplyfilterfn = () => {
    setclearTriger(true);
    fetchScheduleJobsData({
      ...filterScheduleJobRecords,
      scheduleJobStartDateTime:
        filterScheduleJobRecords.scheduleJobStartDateTime
          ? formatDateStr(
              new Date(filterScheduleJobRecords.scheduleJobStartDateTime),
              "YYYY-MM-DD HH:mm:ss",
              false
            )
          : null,
      scheduleJobEndDate: filterScheduleJobRecords.scheduleJobEndDate
        ? formatDateStr(
            new Date(filterScheduleJobRecords.scheduleJobEndDate),
            "YYYY-MM-DD HH:mm:ss",
            false
          )
        : null,
    });
    setCurrentPageScheduleScreen(1);
  };

  const handleremovefilters = () => {
    setclearTriger(!clearTriger);
    setapplybutton(false);
    setFilterScheduleJobRecords(scheduleFormData);
    fetchScheduleJobsData(scheduleFormData);
  };

  const pauseOrResumeOrDeleteScheduledJob = async (
    ScheduleData: ScheduleJobTypes,
    operation: string
  ) => {
    try {
      setLoading(true);
      setRenderCount((prevCount) => prevCount + 1);
      const formdata = new FormData();
      formdata.append(
        "scheduleJobId",
        (ScheduleData.scheduleJobId ?? 0).toString()
      );
      const response =
        operation === "Pause"
          ? await pause(user.auth, formdata)
          : operation === "Resume"
            ? await resume(user.auth, formdata)
            : await deleteJob(user.auth, formdata);
      const data = await response.json();
      data &&
        fetchScheduleJobsData({
          ...filterScheduleJobRecords,
          scheduleJobStartDateTime:
            filterScheduleJobRecords.scheduleJobStartDateTime
              ? formatDateStr(
                  new Date(filterScheduleJobRecords.scheduleJobStartDateTime),
                  "YYYY-MM-DD HH:mm",
                  false
                )
              : null,
          scheduleJobEndDate: filterScheduleJobRecords.scheduleJobEndDate
            ? formatDateStr(
                new Date(filterScheduleJobRecords.scheduleJobEndDate),
                "YYYY-MM-DD",
                false
              )
            : null,
        });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleItemsPerPageChange = (event: any) => {
    setRows(event.target.value);
    setCurrentPageScheduleScreen(1);
  };
  useEffect(() => {
    setFilterScheduleJobRecords({
      ...filterScheduleJobRecords,
      productCode: user.productCode,
    });
    fetchScheduleJobsData({ ...filterScheduleJobRecords });
    setCurrentPageScheduleScreen(1);
  }, [user.productCode]);
  useInterval(
    () => {
      fetchScheduleJobsData({
        ...filterScheduleJobRecords,
        scheduleJobStartDateTime:
          filterScheduleJobRecords.scheduleJobStartDateTime
            ? formatDateStr(
                new Date(filterScheduleJobRecords.scheduleJobStartDateTime),
                "YYYY-MM-DD HH:mm",
                false
              )
            : null,
        scheduleJobEndDate: filterScheduleJobRecords.scheduleJobEndDate
          ? formatDateStr(
              new Date(filterScheduleJobRecords.scheduleJobEndDate),
              "YYYY-MM-DD",
              false
            )
          : null,
      });
    },
    parseInt(process.env.REFRESH_INTERVAL ?? "60000", 10)
  );
  useEffect(() => {
    fetchScheduleJobsData({
      ...filterScheduleJobRecords,
      scheduleJobStartDateTime:
        filterScheduleJobRecords.scheduleJobStartDateTime
          ? formatDateStr(
              new Date(filterScheduleJobRecords.scheduleJobStartDateTime),
              "YYYY-MM-DD HH:mm",
              false
            )
          : null,
      scheduleJobEndDate: filterScheduleJobRecords.scheduleJobEndDate
        ? formatDateStr(
            new Date(filterScheduleJobRecords.scheduleJobEndDate),
            "YYYY-MM-DD",
            false
          )
        : null,
    });
    setCurrentPageScheduleScreen(1);
  }, []);

  useEffect(() => {
    fetchScheduleJobsData({
      ...filterScheduleJobRecords,
      scheduleJobStartDateTime:
        filterScheduleJobRecords.scheduleJobStartDateTime
          ? formatDateStr(
              new Date(filterScheduleJobRecords.scheduleJobStartDateTime),
              "YYYY-MM-DD HH:mm",
              false
            )
          : null,
      scheduleJobEndDate: filterScheduleJobRecords.scheduleJobEndDate
        ? formatDateStr(
            new Date(filterScheduleJobRecords.scheduleJobEndDate),
            "YYYY-MM-DD",
            false
          )
        : null,
    });
    setCurrentPageScheduleScreen(1);
  }, [scheduleJobRecords && scheduleJobRecords.length]);

  useEffect(() => {
    fetchScheduleJobsData({
      ...filterScheduleJobRecords,
      scheduleJobStartDateTime:
        filterScheduleJobRecords.scheduleJobStartDateTime
          ? formatDateStr(
              new Date(filterScheduleJobRecords.scheduleJobStartDateTime),
              "YYYY-MM-DD HH:mm",
              false
            )
          : null,
      scheduleJobEndDate: filterScheduleJobRecords.scheduleJobEndDate
        ? formatDateStr(
            new Date(filterScheduleJobRecords.scheduleJobEndDate),
            "YYYY-MM-DD",
            false
          )
        : null,
    });
  }, [rendercount]);

  if (loading || !scheduleJobRecords) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    scheduleJobRecords && (
      <Container>
        <Typography
          variant="h5"
          mb={"6px"}
          sx={{ paddingTop: "2.5%" }}
        >
          Schedule Job Details
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            gap: "1rem",
            mt: "6px",
            overflowX: "scroll",
            overflowY: "hidden",
          }}
        >
          <TextField
            placeholder="Schedule Job Id"
            size="small"
            id="RunID"
            value={
              filterScheduleJobRecords.userSpecificScheduleJobId !== null
                ? filterScheduleJobRecords.userSpecificScheduleJobId
                : ""
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFilterScheduleJobRecords({
                ...filterScheduleJobRecords,
                userSpecificScheduleJobId: parseInt(e.target.value) || null,
              });

              setclearTriger(true);
              setapplybutton(true);
            }}
            sx={{
              width: "15%",
              minWidth: "10rem",
              marginBottom: "4px",
            }}
          />
          <FormControl
            sx={{
              width: "15%",
              minWidth: "10rem",
            }}
          >
            <Select
              required
              id="frequencyrepeatson"
              size="small"
              value={filterScheduleJobRecords.jobType}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                "& .MuiSelect-root": {
                  alignSelf: "flex-start",
                },
              }}
              onChange={(e) => {
                setFilterScheduleJobRecords({
                  ...filterScheduleJobRecords,
                  jobType: e.target.value,
                });
                setclearTriger(true);
                setapplybutton(true);
              }}
              displayEmpty
              renderValue={(value) =>
                value !== "" ? (
                  value
                ) : (
                  <span style={{ color: "#ACAFAE" }}>Frequency</span>
                )
              }
            >
              {jobTypes.map((org) => (
                <MenuItem
                  key={org}
                  value={org}
                >
                  {org}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              width: "18%",
              minWidth: "10rem",
            }}
          >
            <Select
              required
              id="orgnitioncode"
              size="small"
              value={filterScheduleJobRecords.countryCode}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                "& .MuiSelect-root": {
                  alignSelf: "flex-start",
                },
              }}
              onChange={(e) => {
                setFilterScheduleJobRecords({
                  ...filterScheduleJobRecords,
                  countryCode: e.target.value,
                });
                setclearTriger(true);
                setapplybutton(true);
              }}
              displayEmpty
              renderValue={(value) =>
                value !== "" ? (
                  value
                ) : (
                  <span style={{ color: "#ACAFAE" }}>Organization</span>
                )
              }
            >
              {getOrgs(user.productCode).map((org) => (
                <MenuItem
                  key={org}
                  value={org}
                >
                  {org}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              width: "15%",
              minWidth: "10rem",
            }}
          >
            <Select
              required
              size="small"
              id="statuscode"
              value={filterScheduleJobRecords.status}
              sx={{
                display: "flex",
                minWidth: "10rem",
                alignItems: "flex-start",
                justifyContent: "center",
                "& .MuiSelect-root": {
                  alignSelf: "flex-start",
                },
              }}
              onChange={(e) => {
                setFilterScheduleJobRecords({
                  ...filterScheduleJobRecords,
                  status: e.target.value,
                });
                setclearTriger(true);
                setapplybutton(true);
              }}
              displayEmpty
              renderValue={(value) =>
                value && value.length > 0 ? (
                  value
                ) : (
                  <span style={{ color: "#ACAFAE" }}>Status</span>
                )
              }
            >
              {status.map((status) => (
                <MenuItem
                  key={status}
                  value={status}
                >
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              slotProps={{
                textField: { placeholder: "Job Start Date" },
              }}
              sx={{
                width: "22%",
                minWidth: "10rem",
                "& .MuiInputBase-root": {
                  height: "40px",
                },
              }}
              ampm={false}
              value={
                filterScheduleJobRecords.scheduleJobStartDateTime
                  ? dayjs(filterScheduleJobRecords.scheduleJobStartDateTime)
                  : null
              }
              onChange={(date: dayjs.Dayjs | null) => {
                setFilterScheduleJobRecords({
                  ...filterScheduleJobRecords,
                  scheduleJobStartDateTime: date
                    ? formatDateStr(date.toDate(), "YYYY-MM-DD HH:mm", true)
                    : null,
                });
                setclearTriger(true);
                setapplybutton(true);
              }}
            />
            <DateTimePicker
              slotProps={{
                textField: { placeholder: "Job End Date" },
              }}
              value={
                filterScheduleJobRecords.scheduleJobEndDate
                  ? dayjs(filterScheduleJobRecords.scheduleJobEndDate)
                  : null
              }
              sx={{
                width: "22%",
                minWidth: "10rem",
                "& .MuiInputBase-root": {
                  height: "40px",
                },
              }}
              ampm={false}
              onChange={(date: dayjs.Dayjs | null) => {
                setFilterScheduleJobRecords({
                  ...filterScheduleJobRecords,
                  scheduleJobEndDate: date
                    ? formatDateStr(date.toDate(), "YYYY-MM-DD HH:mm:ss", true)
                    : null,
                });
                setclearTriger(true);
                setapplybutton(true);
              }}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            aliginContent: "center",
            justifyContent: "flex-end",
            mt: 1,
            mb: 1,
          }}
        >
          <Button
            variant="outlined"
            size="small"
            disabled={!applybutton}
            onClick={handleapplyfilterfn}
            startIcon={<FilterAltIcon />}
          >
            Apply
          </Button>
          <Button
            variant="outlined"
            size="small"
            disabled={!clearTriger}
            onClick={handleremovefilters}
            sx={{
              ml: 2,
            }}
            startIcon={<FilterAltOffIcon />}
          >
            Clear
          </Button>
        </Box>

        <Paper sx={{ width: "100%" }}>
          <TableContainer
            component={Paper}
            sx={{ mt: "2px", maxHeight: ["328px"] }}
          >
            <Table
              aria-label="Schedule table"
              stickyHeader
            >
              <TableHead>
                <TableRow sx={{ fontWeight: "600" }}>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "600" }}
                  >
                    Schedule Job Id
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "600" }}
                  >
                    Frequency
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "600" }}
                  >
                    Job Time
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "600" }}
                  >
                    Organization
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontWeight: "600" }}
                  >
                    Status
                  </TableCell>

                  <TableCell
                    align="left"
                    sx={{ fontWeight: "600" }}
                  >
                    Job Start Date
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontWeight: "600" }}
                  >
                    Job End Date
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontWeight: "600" }}
                  >
                    Job Description
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: "600", paddingRight: "18px" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              {currentScheduleRecords &&
                currentScheduleRecords.length === 0 && (
                  <TableBody
                    sx={{
                      overflowY: "scroll",
                      overflowX: "scroll",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={10}
                      >
                        <p>No Schedule Jobs</p>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              {currentScheduleRecords && currentScheduleRecords.length > 0 && (
                <TableBody sx={{ overflowY: "scroll", overflowX: "scroll" }}>
                  {currentScheduleRecords.length > 0 &&
                    currentScheduleRecords.map(
                      (scheduleJobRecord: ScheduleJobTypes) =>
                        !scheduleJobRecord.deletedFlag && (
                          <TableRow
                            key={scheduleJobRecord.scheduleJobId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center">
                              <Tooltip
                                title={scheduleJobRecord.tags}
                                placement="top"
                              >
                                <span>
                                  {scheduleJobRecord.userSpecificScheduleJobId}
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="center">
                              {scheduleJobRecord.jobType}
                            </TableCell>
                            <TableCell align="center">
                              {scheduleJobRecord.jobFrequency}
                            </TableCell>
                            <TableCell align="center">
                              {scheduleJobRecord.countryCode}
                            </TableCell>
                            <TableCell align="left">
                              {scheduleJobRecord.status}
                            </TableCell>
                            <TableCell align="left">
                              {scheduleJobRecord.scheduleJobStartDateTime &&
                                formatDateStr(
                                  convertUTCtoLocal(
                                    scheduleJobRecord.scheduleJobStartDateTime
                                  ),
                                  "YYYY-MM-DD HH:mm",
                                  true
                                )}
                            </TableCell>
                            <TableCell align="left">
                              {scheduleJobRecord.scheduleJobEndDate !== null ? (
                                formatDateStr(
                                  convertUTCtoLocal(
                                    scheduleJobRecord.scheduleJobEndDate
                                  ),
                                  "YYYY-MM-DD HH:mm",
                                  true
                                )
                              ) : (
                                <p>Loading...</p>
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                maxWidth: "200px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {scheduleJobRecord.jobDescription}
                            </TableCell>
                            <TableCell align="right">
                              {scheduleJobRecord.status &&
                                (scheduleJobRecord.status.includes(status[5]) ||
                                  scheduleJobRecord.status.includes(
                                    status[6]
                                  ) ||
                                  scheduleJobRecord.status.includes(
                                    status[7]
                                  )) && (
                                  <Tooltip
                                    title="Pause Job"
                                    placement="top"
                                  >
                                    <span>
                                      <IconButton
                                        sx={{ padding: "4px" }}
                                        disabled={
                                          scheduleJobRecord.status === "Failed"
                                        }
                                        onClick={(e: any) =>
                                          pauseOrResumeOrDeleteScheduledJob(
                                            scheduleJobRecord,
                                            "Pause"
                                          )
                                        }
                                      >
                                        <PauseIcon />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                )}
                              {scheduleJobRecord.status &&
                                scheduleJobRecord.status.includes(
                                  status[4]
                                ) && (
                                  <Tooltip
                                    title="Resume Job"
                                    placement="top"
                                  >
                                    <span>
                                      <IconButton
                                        sx={{ padding: "4px" }}
                                        onClick={(e: any) =>
                                          pauseOrResumeOrDeleteScheduledJob(
                                            scheduleJobRecord,
                                            "Resume"
                                          )
                                        }
                                      >
                                        <PlayCircleFilled />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                )}
                              <Tooltip
                                title={
                                  scheduleJobRecord.status === status[0]
                                    ? "Reschedule Job"
                                    : "Edit Job"
                                }
                                placement="top"
                              >
                                <span>
                                  <IconButton
                                    sx={{ padding: "4px" }}
                                    onClick={(e: any) => {
                                      reportFilterData.scheduleJob &&
                                        (reportFilterData.scheduleJob.scheduleJobId =
                                          scheduleJobRecord.userSpecificScheduleJobId !==
                                          null
                                            ? parseInt(
                                                scheduleJobRecord.userSpecificScheduleJobId as string
                                              )
                                            : null);
                                      navigate("/configure", {
                                        state: {
                                          editformdata: {
                                            tags: scheduleJobRecord.tags,
                                            countrycode:
                                              scheduleJobRecord.countryCode,
                                            emails:
                                              scheduleJobRecord.recipientsEmailIds &&
                                              scheduleJobRecord.recipientsEmailIds.split(
                                                ","
                                              ),
                                            testDataFiles: null,
                                            featureFiles: null,
                                            jobDescription:
                                              scheduleJobRecord.jobDescription,
                                            username: user.userId,
                                            scheduleJobId:
                                              scheduleJobRecord.status ===
                                              status[0]
                                                ? null
                                                : scheduleJobRecord.userSpecificScheduleJobId,
                                            jobStartDate:
                                              scheduleJobRecord.scheduleJobStartDateTime
                                                ? formatDateStr(
                                                    convertUTCtoLocal(
                                                      scheduleJobRecord.scheduleJobStartDateTime
                                                    ),
                                                    "YYYY-MM-DD HH:mm:ss",
                                                    true
                                                  )
                                                : null,
                                            jobExecutionTime:
                                              scheduleJobRecord.scheduleJobStartDateTime
                                                ? formatDateStr(
                                                    convertUTCtoLocal(
                                                      scheduleJobRecord.scheduleJobStartDateTime
                                                    ),
                                                    "YYYY-MM-DD HH:mm:ss",
                                                    true
                                                  )
                                                : null,
                                            recurrenceDaysOfWeek:
                                              scheduleJobRecord.recurrenceDaysOfWeek &&
                                              scheduleJobRecord.recurrenceDaysOfWeek.split(
                                                ","
                                              ),
                                            jobEndDate:
                                              scheduleJobRecord.scheduleJobEndDate
                                                ? formatDateStr(
                                                    convertUTCtoLocal(
                                                      scheduleJobRecord.scheduleJobEndDate
                                                    ),
                                                    "YYYY-MM-DD HH:mm:ss",
                                                    true
                                                  )
                                                : null,
                                            jobFrequency:
                                              formatDateStr(
                                                new Date(),
                                                "YYYY-MM-DD",
                                                true
                                              ) +
                                              " " +
                                              scheduleJobRecord.jobFrequency,
                                            jobType: scheduleJobRecord.jobType,
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    {scheduleJobRecord.status === status[0] ? (
                                      <ReplayIcon />
                                    ) : (
                                      <EditIcon />
                                    )}
                                  </IconButton>
                                </span>
                              </Tooltip>
                              <Tooltip
                                title="View Scheduled Reports"
                                placement="top"
                              >
                                <span>
                                  <IconButton
                                    sx={{ padding: "4px" }}
                                    onClick={(e: any) => {
                                      reportFilterData.scheduleJob &&
                                        (reportFilterData.scheduleJob.userSpecificScheduleJobId =
                                          scheduleJobRecord.userSpecificScheduleJobId);
                                      navigate("/reports", {
                                        state: {
                                          filterTableData: reportFilterData,
                                        },
                                      });
                                    }}
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                              <Tooltip
                                title="Delete Job"
                                placement="top"
                              >
                                <span>
                                  <IconButton
                                    sx={{ padding: "4px" }}
                                    onClick={(e: any) =>
                                      pauseOrResumeOrDeleteScheduledJob(
                                        scheduleJobRecord,
                                        "Delete"
                                      )
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        )
                    )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
        <Box className={reportScreenStylings.actionBox}>
          <Box>
            <Pagination
              rows={rows}
              currentPage={currentPageScheduleScreen}
              setCurrentPage={setCurrentPageScheduleScreen}
              totalDataLength={scheduleJobRecords.length}
              dataPerPage={reportsPerPage}
              handleItemsPerPageChange={(e) => handleItemsPerPageChange(e)}
              fieldName="Schedule Jobs"
            />
          </Box>
        </Box>
      </Container>
    )
  );
};

export default ScheduleScreen;
