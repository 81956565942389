import { UserData } from "src/types/UserData";
import { HttpMethod } from "../types/HttpMethod";

const apiUrl = `${process.env.REACT_APP_BASE_URL}/rs/automation/user`;
const ftpUrl = `${process.env.REACT_APP_BASE_URL}/rs/automator/ftp/user`;

export const getByUsernameAndPassword = async (
  username: string,
  password: string
) => {
  const url = `${apiUrl}/${username}`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Get,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
    },
  });
};

export const getAllUsers = async (username: string, password: string) => {
  const url = `${apiUrl}/get`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Get,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
    },
  });
};

export const createUserFTP = async (
  username: string,
  password: string,
  userData: UserData
) => {
  const url = `${ftpUrl}/create`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};

export const createUserDB = async (
  username: string,
  password: string,
  userData: UserData
) => {
  const url = `${apiUrl}/create`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};

export const updateUserFTP = async (
  username: string,
  password: string,
  userData: UserData
) => {
  const url = `${ftpUrl}/password/update?username=${encodeURIComponent(
    userData.userId
  )}&newPassword=${encodeURIComponent(userData.password)}`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Put,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateUserDB = async (
  username: string,
  password: string,
  userData: UserData
) => {
  const url = `${apiUrl}/updateUser`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Patch,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};

export const deleteUserFTP = async (
  username: string,
  password: string,
  userData: UserData
) => {
  const url = `${ftpUrl}/delete?username=${encodeURIComponent(
    userData.userId
  )}`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Delete,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
      "Content-Type": "application/json",
    },
  });
};

export const deleteUserDB = async (
  username: string,
  password: string,
  userData: UserData
) => {
  const url = `${apiUrl}/deleteUser/${userData.userId}`;
  const encodedCredentials = btoa(`${username}:${password}`);
  return fetch(url, {
    method: HttpMethod.Delete,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
      "Content-Type": "application/json",
    },
  });
};

export const decryptString = async (secretKey: any, inputStr: string) => {
  const url = `${apiUrl}/decrypt/${secretKey}/${inputStr}`;
  return fetch(url, {
    method: HttpMethod.Get,
  });
};

export const resetPassword = async (username: string, newPassword: string) => {
  const url = `${ftpUrl}/reset/password?username=${encodeURIComponent(
    username
  )}&newPassword=${encodeURIComponent(newPassword)}`;
  return fetch(url, {
    method: HttpMethod.Put,
  });
};

export const changePassword = async (
  username: string,
  currentPassword: string,
  newPassword: string
) => {
  const url = `${apiUrl}/changePassword?username=${encodeURIComponent(
    username
  )}&newPassword=${encodeURIComponent(newPassword)}`;
  const encodedCredentials = btoa(`${username}:${currentPassword}`);
  return fetch(url, {
    method: HttpMethod.Put,
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
      "Content-Type": "application/json",
    },
  });
};
