import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "src/redux/slices/userSlice";
import { getOrgs } from "src/types/Organizations";
import { ScheduleJobTypes } from "src/types/ScheduleJobTypes";
import { formatDateStr } from "src/utils/FormatDateStr";
import { Report } from "../../types/Report";
import filterComponentStyles from "./FilterComponent.module.css";

interface FilterInterface {
  filterData: Report;
  handleOnBack: (filterReportRecords: Report) => void;
}
const FilterComponent = ({ filterData, handleOnBack }: FilterInterface) => {
  const [filterReportRecords, setFilterReportRecords] = useState<Report>({
    ...filterData,
  });
  const user = useSelector(selectUser);
  const scheduleFormData: ScheduleJobTypes = {
    updatedBy: null,
    scheduleJobId: null,
    updatedTimeStamp: null,
    reportUser: user.userId,
    createdTimeStamp: null,
    tags: null,
    countryCode: null,
    status: null,
    jobDescription: null,
    recipientsEmailIds: null,
    scheduleJobStartDateTime: null,
    scheduleJobEndDate: null,
    recurrenceDaysOfWeek: null,
    jobType: null,
    jobFrequency: null,
    userSpecificScheduleJobId: null,
    deletedFlag: false,
    productCode: null,
    clientCode: null,
  };
  const defaulttabledata: Report = {
    updatedBy: "",
    runId: null,
    jobId: 0,
    updatedTimeStamp: "",
    createdTimeStamp: "",
    tags: "",
    reportStartDate: null,
    reportEndDate: null,
    dockerContainerName: "",
    countryCode: "",
    status: "",
    jobDescription: null,
    productCode: user.productCode,
    scheduleJob: scheduleFormData || null,
  };

  const initialData = () => {
    let initialData = { ...defaulttabledata };
    if (user.role !== "SYSTEM_ADMIN") {
      initialData.clientCode = user.clientCode;
    }
    if (user.role === "USER") {
      initialData.reportUser = user.userId;
    }
    return initialData;
  };

  const [initialDefaultData, setInitialDefaultData] =
    useState<Report>(initialData);

  const [clearTriger, setclearTriger] = useState<boolean>(
    filterData.scheduleJob?.userSpecificScheduleJobId ? true : false
  );
  const [applybutton, setapplybutton] = useState<boolean>(false);

  const handleapplyfilterfn = () => {
    setclearTriger(true);
    const filteredReport = { ...filterReportRecords };
    filteredReport.reportStartDate = filterReportRecords.reportStartDate
      ? formatDateStr(
          new Date(filterReportRecords.reportStartDate),
          "YYYY-MM-DD HH:mm:ss",
          false
        )
      : null;
    filteredReport.reportEndDate = filterReportRecords.reportEndDate
      ? formatDateStr(
          new Date(filterReportRecords.reportEndDate),
          "YYYY-MM-DD HH:mm:ss",
          false
        )
      : null;
    handleOnBack(filteredReport);
  };
  const handleremovefilters = () => {
    setclearTriger(!clearTriger);
    setapplybutton(false);
    if (initialDefaultData.scheduleJob != null) {
      initialDefaultData.scheduleJob.userSpecificScheduleJobId = "";
    }
    setFilterReportRecords(initialDefaultData);
    handleOnBack(initialDefaultData);
  };

  return (
    <>
      <Box className={filterComponentStyles.filtersOuterBox}>
        <TextField
          placeholder="Enter Run Id"
          size="small"
          id="RunID"
          value={
            filterReportRecords.runId !== null ? filterReportRecords.runId : ""
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFilterReportRecords({
              ...filterReportRecords,
              runId: parseInt(e.target.value) || null,
            });

            setclearTriger(true);
            setapplybutton(true);
          }}
          sx={{
            width: "21%",
            minWidth: "10rem",
            marginBottom: "4px",
          }}
        />
        <TextField
          placeholder="Schedule Job Id"
          size="small"
          id="RunID"
          value={
            filterReportRecords.scheduleJob &&
            filterReportRecords.scheduleJob.userSpecificScheduleJobId
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFilterReportRecords({
              ...filterReportRecords,
              scheduleJob: {
                ...filterReportRecords.scheduleJob,
                userSpecificScheduleJobId: parseInt(e.target.value) || null,
                updatedBy: null,
                scheduleJobId: null,
                updatedTimeStamp: null,
                reportUser: user.userId,
                createdTimeStamp: null,
                tags: null,
                countryCode: null,
                status: null,
                jobDescription: null,
                recipientsEmailIds: null,
                scheduleJobStartDateTime: null,
                scheduleJobEndDate: null,
                recurrenceDaysOfWeek: null,
                jobType: null,
                jobFrequency: null,
                deletedFlag: false,
                productCode: null,
                clientCode: null,
              },
            });

            setclearTriger(true);
            setapplybutton(true);
          }}
          sx={{
            width: "15%",
            minWidth: "10rem",
            marginBottom: "4px",
          }}
        />

        <FormControl className={filterComponentStyles.formControlCss}>
          <Select
            className={filterComponentStyles.selectComponentCss}
            required
            size="small"
            id="statuscode"
            value={filterReportRecords.status}
            sx={{
              "& .MuiSelect-root": {
                alignSelf: "flex-start",
              },
            }}
            onChange={(e) => {
              setFilterReportRecords({
                ...filterReportRecords,
                status: e.target.value,
              });
              setclearTriger(true);
              setapplybutton(true);
            }}
            displayEmpty
            renderValue={(value) =>
              value.length > 0 ? (
                value
              ) : (
                <span style={{ color: "#ACAFAE" }}>Status</span>
              )
            }
          >
            {["Completed", "Created", "Inprogress", "Failed"].map((org) => (
              <MenuItem
                key={org}
                value={org}
              >
                {org}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={filterComponentStyles.formControlCss}>
          <Select
            className={filterComponentStyles.selectComponentCss}
            required
            id="orgnitioncode"
            size="small"
            value={filterReportRecords.countryCode}
            sx={{
              "& .MuiSelect-root": {
                alignSelf: "flex-start",
              },
            }}
            onChange={(e) => {
              setFilterReportRecords({
                ...filterReportRecords,
                countryCode: e.target.value,
              });
              setclearTriger(true);
              setapplybutton(true);
            }}
            displayEmpty
            renderValue={(value) =>
              value !== "" ? (
                value
              ) : (
                <span style={{ color: "#ACAFAE" }}>Organization</span>
              )
            }
          >
            {getOrgs(user.productCode).map((org) => (
              <MenuItem
                key={org}
                value={org}
              >
                {org}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            className={filterComponentStyles.dateTimePickerCss}
            slotProps={{
              textField: { placeholder: "Job Start Date" },
            }}
            sx={{
              "& .MuiInputBase-root": {
                height: "40px",
              },
            }}
            ampm={false}
            value={
              filterReportRecords.reportStartDate
                ? dayjs(filterReportRecords.reportStartDate)
                : null
            }
            onChange={(date: dayjs.Dayjs | null) => {
              setFilterReportRecords({
                ...filterReportRecords,
                reportStartDate: date
                  ? formatDateStr(date.toDate(), "YYYY-MM-DD HH:mm:ss", true)
                  : null,
              });
              setclearTriger(true);
              setapplybutton(true);
            }}
          />
          <DateTimePicker
            className={filterComponentStyles.dateTimePickerCss}
            slotProps={{
              textField: { placeholder: "Job End Date" },
            }}
            value={
              filterReportRecords.reportEndDate
                ? dayjs(filterReportRecords.reportEndDate)
                : null
            }
            sx={{
              // width: "22%",
              // minWidth: "10rem",
              "& .MuiInputBase-root": {
                height: "40px",
              },
            }}
            ampm={false}
            onChange={(date: dayjs.Dayjs | null) => {
              setFilterReportRecords({
                ...filterReportRecords,
                reportEndDate: date
                  ? formatDateStr(date.toDate(), "YYYY-MM-DD HH:mm:ss", true)
                  : null,
              });
              setclearTriger(true);
              setapplybutton(true);
            }}
          />
        </LocalizationProvider>
      </Box>
      <Box className={filterComponentStyles.buttonsOuterBox}>
        <Button
          variant="outlined"
          size="small"
          disabled={!applybutton}
          onClick={handleapplyfilterfn}
          startIcon={<FilterAltIcon />}
        >
          Apply
        </Button>
        <Button
          variant="outlined"
          size="small"
          disabled={!clearTriger}
          onClick={handleremovefilters}
          sx={{
            ml: 2,
          }}
          startIcon={<FilterAltOffIcon />}
        >
          Clear
        </Button>
      </Box>
    </>
  );
};

export default FilterComponent;
