import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import paginationStyles from "./Pagination.module.css";

interface PaginationProps {
  rows: string;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  totalDataLength: number;
  dataPerPage: number;
  handleItemsPerPageChange: (event: any) => void;
  fieldName: string;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  useEffect(() => {
    if (props.totalDataLength === 0) {
      props.setCurrentPage(0);
    }
  }, [props.totalDataLength, props.setCurrentPage]);

  const handlePreviousPage = () => {
    if (props.currentPage > 1) props.setCurrentPage((prevPage) => prevPage - 1);
  };

  const handlePaginationPageNumber = (event: any) => {
    const inputPageNumber = Number(event.target.value);
    if (
      inputPageNumber <= Math.ceil(props.totalDataLength / props.dataPerPage)
    ) {
      props.setCurrentPage(Math.ceil(inputPageNumber));
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(props.totalDataLength / props.dataPerPage);
    if (props.currentPage < totalPages)
      props.setCurrentPage((prevPage) => prevPage + 1);
  };

  const lastIndex = props.currentPage * props.dataPerPage;
  const firstIndex = lastIndex - props.dataPerPage;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          // overflowX: "auto",
          whiteSpace: "nowrap",
          alignItems: "end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            mt: "6px",
            mr: "1rem",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#191F1C" }}>
            {props.fieldName} Per Page
          </Typography>
          <FormControl variant="standard">
            <Select
              disableUnderline={true}
              value={props.dataPerPage}
              label={`${props.fieldName} Per Page`}
              onChange={(e) => props.handleItemsPerPageChange(e)}
              sx={{
                fontSize: "13px",
              }}
            >
              {[5, 10, 15].map((value, index) => (
                <MenuItem
                  value={value}
                  key={value + String(index)}
                >
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography sx={{ color: "#191F1C" }}>
            {lastIndex > 0 ? firstIndex + 1 : 0} - {"  "}
            {firstIndex + Number(props.dataPerPage) > props.totalDataLength
              ? props.totalDataLength
              : firstIndex + Number(props.dataPerPage)}{" "}
            out of {props.totalDataLength}
          </Typography>
        </Box>
        <Box className={paginationStyles.paginationOuterStack}>
          <KeyboardDoubleArrowLeftIcon
            className={
              props.currentPage > 1
                ? paginationStyles.paginationEnable
                : paginationStyles.paginationDisable
            }
            onClick={() => props.setCurrentPage(1)}
          />

          <ChevronLeftOutlinedIcon
            className={
              props.currentPage > 1
                ? paginationStyles.paginationEnable
                : paginationStyles.paginationDisable
            }
            onClick={handlePreviousPage}
          />

          <TextField
            className={paginationStyles.paginationTextFieldCss}
            variant="standard"
            // size="small"
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            value={String(props.currentPage)}
            onChange={(event) => {
              handlePaginationPageNumber(event);
            }}
            type="number"
          />
          <Typography>
            of &ensp; {Math.ceil(props.totalDataLength / props.dataPerPage)}
          </Typography>
          <ChevronRightOutlinedIcon
            className={
              props.currentPage < props.totalDataLength / props.dataPerPage
                ? paginationStyles.paginationEnable
                : paginationStyles.paginationDisable
            }
            onClick={handleNextPage}
          />
          <KeyboardDoubleArrowRightIcon
            className={
              props.currentPage < props.totalDataLength / props.dataPerPage
                ? paginationStyles.paginationEnable
                : paginationStyles.paginationDisable
            }
            onClick={() => {
              props.setCurrentPage(
                Math.ceil(props.totalDataLength / props.dataPerPage)
              );
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Pagination;
