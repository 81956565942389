import { createSlice } from "@reduxjs/toolkit";

export const dockercontid = createSlice({
  name: "docker",
  initialState: {
    containerid: null,
  },
  reducers: {
    setContainerId: (state, action) => {
      state.containerid = action.payload;
    },
    setContainerIdnull: (state) => {
      state.containerid = null;
    },
  },
});

export const { setContainerId, setContainerIdnull } = dockercontid.actions;
export const selectContainerId = (state: any) => state.docker.containerid;

export default dockercontid.reducer;
