import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import lodash from "lodash";
import { useState } from "react";
import WarningPopup from "src/components/modal/WarningPopup";
import { Datatable, Scenario, Step } from "../../../types/Feature";
import Popup from "../Popup";
import ReuseComponent from "../ReuseComponent";
import editfeaturestyles from "./EditFeature.module.css";
import EditFeatureStep from "./EditFeatureStep";
import scenarioStyle from "./EditScenario.module.css";

interface EditScenarioProps {
  scenarioData: Scenario;
  stepDefList: string[];
  handleOnScenarioSave: (editedScenarioData: Scenario) => void;
  handleOnScenarioBack: () => void;
}
const EditScenario = ({
  scenarioData,
  stepDefList,
  handleOnScenarioSave,
  handleOnScenarioBack,
}: EditScenarioProps) => {
  const [editedScenarioData, setEditedScenarioData] = useState<Scenario>({
    tags: scenarioData.tags,
    name: scenarioData.name,
    steps: scenarioData.steps.map((step) => ({
      ...step,
      datatable: step.datatable
        ? {
            ...step.datatable,
            headers: [...step.datatable.headers],
            rows: [...step.datatable.rows],
          }
        : null,
    })),
  });
  const [newTag, setNewTag] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [backToEditFeatures, setBackToEditFeatures] = useState<boolean>(false);
  const [isValidTag, setIsValidTag] = useState<boolean>(false);
  const [popupStepIndex, setPopupStepIndex] = useState<number>(-1);
  const disable =
    lodash.isEqual(scenarioData, editedScenarioData) ||
    lodash.isEmpty(editedScenarioData.name) ||
    lodash.isEmpty(editedScenarioData.tags) ||
    lodash.isEmpty(editedScenarioData.steps) ||
    lodash.some(editedScenarioData.steps, (step) => {
      return (
        lodash.includes(step.values, "") ||
        lodash.includes(step.datatable?.headers, "") ||
        (step.datatable?.rows &&
          lodash.some(step.datatable.rows, (row) =>
            lodash.every(row, (cell) => lodash.isEmpty(cell))
          ))
      );
    });

  const handleTagDelete = (tagIndex: number) => {
    const updatedScenario = { ...editedScenarioData };
    updatedScenario.tags = updatedScenario.tags.filter(
      (_, index: number) => index !== tagIndex
    );
    setEditedScenarioData(updatedScenario);
  };

  const handleKeyDown = (
    evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (evt.key === "Enter" && newTag.trim() !== "") {
      if (
        newTag.startsWith("@") &&
        /^@[a-zA-Z0-9]+([-_&][a-zA-Z0-9]+)*$/.test(newTag)
      ) {
        setEditedScenarioData((prevData) => {
          const updatedScenario = { ...prevData };
          const trimmedNewTag = newTag.trim();
          if (!updatedScenario.tags.includes(trimmedNewTag)) {
            updatedScenario.tags = [...prevData.tags, trimmedNewTag];
          }
          return updatedScenario;
        });
        setNewTag("");
      } else {
        setIsValidTag(true);
      }
    }
  };

  const handleTags = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setIsValidTag(false);
    setNewTag(evt.target.value);
  };

  const handleAddIcon = (stepIndex: number) => {
    setPopupOpen(true);
    setPopupStepIndex(stepIndex);
  };
  const handleOnChange = (
    value: string,
    stepIndex: number,
    stringValues: string[],
    dataTable: Datatable | null,
    keyword?: string
  ) => {
    setEditedScenarioData((prevFeatureData) => {
      const updatedScenarioData = { ...prevFeatureData };

      if (stepIndex > -1) {
        const scenarioSteps = [...updatedScenarioData.steps];
        if (keyword !== undefined) scenarioSteps[stepIndex].keyword = keyword;
        scenarioSteps[stepIndex].text = value;
        scenarioSteps[stepIndex].values = stringValues;
        scenarioSteps[stepIndex].datatable = dataTable;

        updatedScenarioData.steps = scenarioSteps;
      } else {
        updatedScenarioData.name = value;
      }

      return updatedScenarioData;
    });
  };
  const handleDeleteStep = (stepIndex: number) => {
    setEditedScenarioData((prevEditedScenarioData) => {
      const updatedScenarioData = { ...prevEditedScenarioData };
      const scenarioSteps = [...updatedScenarioData.steps];
      scenarioSteps.splice(stepIndex, 1);
      updatedScenarioData.steps = scenarioSteps;
      return updatedScenarioData;
    });
  };
  const handleOnDataTableChange = (
    headers: string[],
    rows: string[][],
    stepIndex: number
  ) => {
    setEditedScenarioData((prevFeatureData) => {
      const updatedScenarioData = { ...prevFeatureData };
      if (stepIndex > -1) {
        const scenarioSteps = [...updatedScenarioData.steps];
        const step = scenarioSteps[stepIndex];
        if (step.datatable) {
          if (headers.length === 0 && rows.length === 0) {
            step.datatable = null;
          } else {
            step.datatable = {
              headers: [...headers],
              rows: [...rows],
            };
          }
        } else {
          step.datatable = {
            headers: [...headers],
            rows: [...rows],
          };
        }
        scenarioSteps[stepIndex] = step;
        updatedScenarioData.steps = scenarioSteps;
      }
      return updatedScenarioData;
    });
  };

  const handleAddStep = (data: any) => {
    setEditedScenarioData((prevFeatureData) => {
      const updatedScenarioData = { ...prevFeatureData };
      const scenarioSteps = [...updatedScenarioData.steps];
      const headers: string[] = [];
      const rows: string[][] = [];
      if (data.isChecked) {
        let columnCount = data.columns;
        let rowCount = data.rows;
        while (columnCount--) {
          headers.push("");
        }
        while (rowCount--) {
          rows.push(headers);
        }
      }
      const newDataTable: Datatable = { headers: headers, rows: rows };
      const newStep: Step = {
        keyword: data.keyword,
        text: data.text,
        values: stringList(data.text),
        datatable: data.isChecked ? newDataTable : null,
      };
      scenarioSteps.splice(popupStepIndex + 1, 0, newStep);
      // scenarioSteps.push(newStep);
      updatedScenarioData.steps = scenarioSteps;
      return updatedScenarioData;
    });
  };

  const stringList = (value: string) => {
    let count =
      (value?.match(new RegExp("{string}", "g")) || []).length +
      (value?.match(new RegExp("{word}", "g")) || []).length +
      (value?.match(new RegExp("{int}", "g")) || []).length;
    let stringValues: string[] = [];
    while (count-- > 0) {
      stringValues = [...stringValues, ""];
    }
    return stringValues;
  };

  const handleOnBackEditedScenario = () => {
    setBackToEditFeatures(true);
    if (lodash.isEqual(scenarioData, editedScenarioData)) {
      handleOnScenarioBack();
    }
  };
  const handleOnSaveEditedScenario = () => {
    handleOnScenarioSave(editedScenarioData);
    handleOnScenarioBack();
  };
  const handleCloseIcon = () => {
    setBackToEditFeatures(false);
  };
  return (
    <>
      {
        <>
          <Box className={scenarioStyle.scenarioBox}>
            {/* tags */}
            <Autocomplete
              multiple
              freeSolo
              options={[]}
              value={editedScenarioData.tags}
              disableClearable
              renderTags={(value) =>
                value.map((tag, tagIndex) => (
                  <Chip
                    key={tagIndex}
                    label={tag}
                    onDelete={() => handleTagDelete(tagIndex)}
                    variant="outlined"
                    className={scenarioStyle.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={
                    editedScenarioData.tags.length === 0 ? "Tags" : ""
                  }
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    handleKeyDown(e)
                  }
                  onChange={handleTags}
                  error={isValidTag}
                  helperText={
                    isValidTag ? (
                      <span style={{ color: "#FE524A" }}>
                        Tags must start with "@" and may contain alphanumeric
                        characters as well as specific special characters like
                        (-_&).
                      </span>
                    ) : (
                      ""
                    )
                  }
                />
              )}
              sx={{ width: "100%" }}
            />
            {/* Scenario name */}
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <ReuseComponent
                Name="Test Scenario"
                Value={editedScenarioData.name}
                editable={true}
                onChange={(val: string) => {
                  handleOnChange(val, -1, [], null);
                }}
              />
              {editedScenarioData.steps.length === 0 && (
                <IconButton onClick={() => handleAddIcon(-1)}>
                  <AddCircleOutlineIcon />
                </IconButton>
              )}
            </Box>
            {/* Scenario Steps */}
            <Box
              sx={{
                marginLeft: "4%",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {editedScenarioData.steps.map((step: any, stepIndex: any) => (
                <EditFeatureStep
                  key={`scenario_step__${stepIndex}`}
                  step={step}
                  stepIndex={stepIndex}
                  stepDefList={stepDefList}
                  handleOnChange={handleOnChange}
                  isLastIndex={
                    stepIndex === editedScenarioData.steps.length - 1
                  }
                  handleAddIcon={handleAddIcon}
                  handleDeleteStep={handleDeleteStep}
                  handleOnDataTableChange={handleOnDataTableChange}
                />
              ))}
              {popupOpen && (
                <Popup
                  isOpen={popupOpen}
                  menuList={stepDefList}
                  onClose={() => setPopupOpen(false)}
                  onDataSubmit={(data) => handleAddStep(data)}
                />
              )}
            </Box>
          </Box>
          {/* Action Button's */}
          <Box className={scenarioStyle.actionButtonsBox}>
            <Button
              type="submit"
              variant="outlined"
              onClick={handleOnSaveEditedScenario}
              disabled={disable}
            >
              save
            </Button>
            <Button
              type="submit"
              variant="outlined"
              onClick={handleOnBackEditedScenario}
            >
              Back
            </Button>
          </Box>
        </>
      }
      {backToEditFeatures &&
        !lodash.isEqual(scenarioData, editedScenarioData) && (
          <WarningPopup
            isOpen={true}
            onClose={handleCloseIcon}
            actionButtons={[
              {
                name: "Save",
                isDisabled: disable,
                onButtonClick: handleOnSaveEditedScenario,
              },
              {
                name: "Discard",
                onButtonClick: handleOnScenarioBack,
              },
            ]}
          >
            <Box className={editfeaturestyles.warningPopUpBox}>
              <WarningIcon />
              <Typography variant="body2">
                There are changes in the data do you want to save it.
              </Typography>
            </Box>
          </WarningPopup>
        )}
    </>
  );
};

export default EditScenario;
