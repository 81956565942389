import { Datatable, Feature, Scenario, Step } from "../types/Feature";

const parseFileToString = (
  file: File | null,
  callback: (fileContent: string) => void
): void => {
  let fileContent = "";

  if (file) {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      fileContent = fileReader.result as string;
      callback(fileContent);
    };
    fileReader.readAsText(file);
  } else {
    console.error("File not found");
    callback(fileContent);
  }
};

export function parseGherkinToJSON(
  gherkinString: string,
  mode: string
): Feature {
  const feature: Feature = {
    name: "",
    background: { name: "", steps: [] },
    scenarios: [],
  };
  let currentScenario: Scenario | null = null;

  gherkinString.split("\n").forEach((line) => {
    line = line.trim();
    if (line.startsWith("Feature:")) {
      feature.name = line.replace("Feature:", "").trim();
    } else if (line.startsWith("Background:")) {
      feature.background.name = line.replace("Background:", "").trim();
    } else if (line.startsWith("@")) {
      const tags = line
        .split("@")
        .slice(1)
        .map((tag) => "@" + tag.trim());
      currentScenario = {
        tags: tags,
        name: "",
        steps: [],
      };
    } else if (line.startsWith("Scenario:")) {
      let tags = currentScenario?.tags ?? [];
      currentScenario = {
        tags,
        name: line.replace("Scenario:", "").trim(),
        steps: [],
      };
      feature.scenarios.push(currentScenario);
    } else if (
      line.startsWith("Given") ||
      line.startsWith("When") ||
      line.startsWith("Then") ||
      line.startsWith("And")
    ) {
      const stepParts = line.split(/\s(.+)/);
      const step: Step = {
        keyword: stepParts[0].trim(),
        values: (stepParts[1].match(/"([^"]*)"/g) || []).map(
          (match: any) => match.replace(/"/g, "")
          // (match: any) => match.substring(1,match.length-1)
        ),
        text:
          mode === "view"
            ? stepParts[1].trim()
            : stepParts[1].trim().replace(/"([^"]*?)"/g, "{string}"),
        datatable: null,
      };
      currentScenario
        ? currentScenario.steps.push(step)
        : feature.background.steps.push(step);
    } else if (line.startsWith("|") && currentScenario) {
      const stepIndex = currentScenario.steps.length - 1;
      const list: string[] = line
        .split("|")
        .slice(1, -1)
        .map((header) => header.trim());
      if (stepIndex !== undefined) {
        const step = currentScenario.steps[stepIndex];
        if (step.datatable === null) {
          step.datatable = {
            headers: [],
            rows: [],
          };
        }
        step.datatable.headers.length === 0
          ? step.datatable.headers.push(...list)
          : step.datatable.rows.push([...list]);
      }
    }
  });

  return feature;
}

export function convertJSONToGherkin(jsonData: Feature) {
  let featureFile = `Feature: ${jsonData.name}\n\n`;

  // Background
  featureFile += `  Background: ${jsonData.background.name ?? ""}\n`;
  jsonData.background.steps.forEach((step) => {
    const listText: string[] = convertStepsToStrings([step]);
    const text: string = listText[0];
    let featureFileLine: string = `    ${step.keyword} ${text}\n`;
    if (step.datatable !== undefined && step.datatable !== null) {
      featureFileLine += datatableToString(step.datatable) + "\n";
    }
    featureFile += featureFileLine;
  });

  // Scenarios
  jsonData.scenarios.forEach((scenario) => {
    featureFile += `\n  ${tagsToString(scenario.tags)}\n`;
    featureFile += `  Scenario: ${scenario.name}\n`;
    scenario.steps.forEach((step) => {
      const listText: string[] = convertStepsToStrings([step]);
      const text: string = listText[0];
      let featureFileLine: string = `    ${step.keyword} ${text}\n`;
      if (step.datatable !== undefined && step.datatable !== null) {
        featureFileLine += datatableToString(step.datatable) + "\n";
      }
      featureFile += featureFileLine;
    });
  });

  return featureFile;
}

const convertStepsToStrings = (allSteps: Step[]): string[] => {
  return allSteps.map((step) => {
    let actualStep: string = step.text;
    let list: string[] = actualStep.split(" ");
    let count: number = 0;

    for (let i = 0; i < list.length; i++) {
      if (list[i] === "{word}") {
        list[i] = step.values[count];
        count++;
      } else if (list[i] === "{string}") {
        list[i] = `"${step.values[count]}"`;
        count++;
      } else if (list[i] === "{int}") {
        list[i] = step.values[count];
        count++;
      }
    }
    actualStep = list.join(" ");
    return actualStep;
  });
};

export const tagsToString = (tags: string[]) => {
  let updatedTags = ``;
  tags.forEach((tag) => {
    updatedTags += ` ${tag}`;
  });
  return updatedTags.trim();
};

export const datatableToString = (datatable: Datatable) => {
  let headers = "|";
  let rows = "";
  datatable.headers.forEach((header) => {
    headers += ` ${header} |`;
  });
  datatable.rows.forEach((row) => {
    let currentRow = "|";
    row.forEach((rowVal) => {
      currentRow += ` ${rowVal} |`;
    });
    rows += `\n      ${currentRow}`;
  });
  return `      ${headers}${rows}`;
};

export const saveFile = (fileData: string, fileName: string) => {
  const blob = new Blob([fileData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = fileName + ".feature";
  link.href = url;
  link.click();
};

export default parseFileToString;
