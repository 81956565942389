import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addRole,
  deleteRoles,
  getAllRoles,
} from "src/clients/RolesAndPermissionsService";
import { selectUser } from "src/redux/slices/userSlice";
import { Role } from "src/types/RolesAndPermissions";
import rolesStyles from "../admin/Roles.module.css";
import WarningPopup from "../modal/WarningPopup";
import { Permissions } from "./Permissions";

const Roles = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [isCardClicked, setIsCardClicked] = useState<boolean>(false);
  const [isAddRoleClicked, setIsAddRoleClicked] = useState<boolean>(false);
  const [isDeleteRoleClicked, setIsDeleteRoleClicked] =
    useState<boolean>(false);
  const [isRoleNameExists, setIsRoleNameExists] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("");
  const [roleName, setRoleName] = useState<string>("");
  const [selectedRoleId, setSelectedRoleId] = useState<number>(-1);
  const [checkedRoleList, setCheckedRoleList] = useState<number[]>([]);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(-1);
  const user = useSelector(selectUser);

  const fetchRoles = () => {
    getAllRoles(user.userId, user.password).then(async (res) => {
      setRoles(await res.json());
    });
  };
  useEffect(() => {
    fetchRoles();
  }, []);

  const handleOnCardClick = (roleId: number) => {
    setIsCardClicked(true);
    setMode("view");
    setHoveredCardIndex(-1);
    setSelectedRoleId(roleId);
  };

  const handleOnRoleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newRoleName = event.target.value;
    roles.some(
      (role) =>
        role.roleName === newRoleName.toUpperCase().trim().replaceAll(" ", "_")
    )
      ? setIsRoleNameExists(true)
      : setIsRoleNameExists(false);
    setRoleName(newRoleName);
  };

  const handleCardMouseEnter = (index: number) => {
    setHoveredCardIndex(index);
  };

  const handleCardMouseLeave = () => {
    setHoveredCardIndex(-1);
  };

  const handleCardCheckBoxClick = (roleId: number) => {
    if (checkedRoleList.includes(roleId)) {
      const updatedCheckedRoleList = checkedRoleList.filter(
        (id) => id !== roleId
      );
      setCheckedRoleList(updatedCheckedRoleList);
    } else {
      setCheckedRoleList([...checkedRoleList, roleId]);
    }
  };

  const handleDeleteRoles = () => {
    deleteRoles(user.userId, user.password, checkedRoleList).then((res) => {
      if (res.ok) {
        setCheckedRoleList([]);
        fetchRoles();
        toast.success("Roles Deleted Successfully");
      } else {
        toast.error("Failed to delete the role(s)");
      }
    });
  };

  const handlePermissionsBack = (mode: string) => {
    if (mode === "view") {
      setIsCardClicked(false);
      setMode("");
    } else {
      setMode("view");
    }
  };

  const handlePermissionsEdit = () => {
    setMode("edit");
  };

  const handleOnAddRoleSave = () => {
    addRole(user.userId, user.password, roleName).then(async (res) => {
      if (res.ok) {
        fetchRoles();
        toast.success("Role added successfully");
      } else {
        toast.error("Failed to add role");
      }
      setRoleName("");
      setIsAddRoleClicked(false);
    });
  };

  const handleOnAddRoleCancel = () => {
    setRoleName("");
    setIsAddRoleClicked(false);
  };

  const handleOnDeleteRoleConfirm = () => {
    handleDeleteRoles();
    setIsDeleteRoleClicked(false);
  };

  const handleOnDeleteRoleCancel = () => {
    setIsDeleteRoleClicked(false);
  };

  if (roles.length === 0) {
    return (
      <Box className="loaderIconStylings">
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <>
      {!isCardClicked && (
        <Box>
          <Typography variant="h5">Roles</Typography>
          <Box className={rolesStyles.rolesBox}>
            {roles.map((role, roleIndex) => (
              <Box
                className={rolesStyles.roleCardCss}
                key={roleIndex}
                onMouseEnter={() => handleCardMouseEnter(roleIndex)}
                onMouseLeave={handleCardMouseLeave}
              >
                <Checkbox
                  size="small"
                  sx={{
                    visibility:
                      hoveredCardIndex === roleIndex ||
                      checkedRoleList.length > 0
                        ? "visible"
                        : "hidden",
                  }}
                  onClick={() => {
                    handleCardCheckBoxClick(role.roleId);
                  }}
                  checked={checkedRoleList.includes(role.roleId)}
                />

                <Box
                  sx={{ m: "0 2rem 2rem" }}
                  onClick={() => handleOnCardClick(role.roleId)}
                >
                  <Tooltip
                    placement="top-start"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [45, -10],
                            },
                          },
                        ],
                      },
                    }}
                    title={role.roleName}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textWrap: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Role : {role.roleName}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            ))}
          </Box>
          <Box className={rolesStyles.actionBox}>
            <Box className={rolesStyles.actionButtonsBox}>
              <Button
                variant="outlined"
                onClick={() => {
                  setIsAddRoleClicked(true);
                }}
                disabled={checkedRoleList.length > 0}
              >
                Add Role
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setIsDeleteRoleClicked(true);
                }}
                disabled={checkedRoleList.length === 0}
              >
                Delete Role
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {isCardClicked && (
        <Permissions
          mode={mode}
          roleId={selectedRoleId}
          handlePermissionsEdit={handlePermissionsEdit}
          handlePermissionsBack={handlePermissionsBack}
        />
      )}

      {isAddRoleClicked && (
        <WarningPopup
          isOpen={true}
          header="Add Role"
          actionButtons={[
            {
              name: "Save",
              isDisabled: isRoleNameExists || roleName === "",
              onButtonClick: handleOnAddRoleSave,
            },
            {
              name: "Cancel",
              onButtonClick: handleOnAddRoleCancel,
            },
          ]}
        >
          <Box>
            <Box sx={{ display: "flex", gap: "8px" }}>
              <Typography
                className={rolesStyles.typographyBox}
                variant="body1"
              >
                Role Name
              </Typography>
              <TextField
                type="text"
                placeholder="Role Name"
                fullWidth
                value={roleName}
                onChange={handleOnRoleNameChange}
                error={isRoleNameExists}
                helperText={isRoleNameExists ? "Role Name already exists" : ""}
              />
            </Box>
          </Box>
        </WarningPopup>
      )}

      {isDeleteRoleClicked && (
        <WarningPopup
          isOpen={true}
          onClose={handleOnDeleteRoleCancel}
          actionButtons={[
            {
              name: "Confirm",
              onButtonClick: handleOnDeleteRoleConfirm,
            },
            {
              name: "Cancel",
              onButtonClick: handleOnDeleteRoleCancel,
            },
          ]}
        >
          <Typography>Do you want to delete the Role(s)</Typography>
        </WarningPopup>
      )}
    </>
  );
};

export default Roles;
