export const jobTypes = ["Hourly", "Daily", "Weekly"];
export const status = [
  "Completed",
  "Created",
  "Inprogress",
  "Failed",
  "Paused",
  "Resumed",
  "Updated",
  "Scheduled",
];
export const responseStatus = ["ContainerNotFound", "Scheduled", "Updated"];
