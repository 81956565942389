import { Box, Typography } from "@mui/material";
import React from "react";
import { configformdata } from "../../types/Configuretypes";
import RunForm from "./RunForm";
import runScenarioStylings from "./RunScenariosScreen.module.css";

export interface RunScenariosScreenProps {
  editformdata?: configformdata | null;
}
const RunScenariosScreen: React.FC<RunScenariosScreenProps> = ({
  editformdata,
}) => {
  return (
    <Box className={runScenarioStylings.runScenarioOuterBox}>
      <Typography
        variant="h5"
        sx={{ padding: "0" }}
      >
        Execute Test
      </Typography>
      <Box className={runScenarioStylings.runScenarioInnerBox}>
        <RunForm editformdata={editformdata} />
      </Box>
    </Box>
  );
};

export default RunScenariosScreen;
