import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { ActionButton } from "../../types/ActionButton";

interface WarningPopupProps {
  isOpen: boolean;
  header?: string;
  children: React.ReactNode;
  onClose?: (input?: any) => void;
  actionButtons?: ActionButton[];
}

const WarningPopup = ({
  isOpen,
  header,
  children,
  onClose,
  actionButtons,
}: WarningPopupProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <IconButton sx={{ visibility: "hidden" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle>
          {header && <Typography variant="h5">{header}</Typography>}
        </DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{ visibility: !onClose ? "hidden" : "visible" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {actionButtons?.map((button: any, index: number) => (
          <Button
            key={index}
            variant="outlined"
            onClick={button.onButtonClick}
            disabled={button.isDisabled}
          >
            {button.name}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default WarningPopup;
