import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllClientsReviewFiles } from "src/clients/FeatureService";
import {
  getAllPublishData,
  publishCommitApprove,
  publishCommitReject,
} from "src/clients/PublishService";
import publishstyles from "src/components/publish/Publish.module.css";
import { login, selectUser } from "src/redux/slices/userSlice";
import { Feature } from "src/types/Feature";
import { PublishDataDto } from "src/types/PublishDataDto";
import ViewFeature from "../features/view/ViewFeature";
import WarningPopup from "../modal/WarningPopup";
import reviewStylings from "../review/Review.module.css";
import Pagination from "../templates/Pagination";

const Review = () => {
  const [selectedUser, setSelectedUser] = useState<string>("ALL");
  const [publishedUserData, setPublishedUserData] = useState<PublishDataDto[]>(
    []
  );
  const [selectedUserData, setSelectedUserData] = useState<PublishDataDto[]>(
    []
  );
  const [checkedList, setCheckedList] = useState<PublishDataDto[]>([]);
  const [userNameList, setUserNameList] = useState<Set<string>>(
    new Set(["ALL"])
  );
  const [reviewFeatures, setReviewFeatures] = useState<Feature[] | null>(null);
  const [featureData, setFeatureData] = useState<Feature | null>(null);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [commentPopup, setCommentPopup] = useState<boolean>(false);
  const [notFoundPopup, setNotFoundPopup] = useState<boolean>(false);
  const [reviewComment, setReviewComment] = useState<string>("");
  const [reviewStatus, setReviewStatus] = useState<string>("");
  const [isDisable, isLoading] = useState<boolean>(false);
  const [publishDataDto, setPublishDataDto] = useState<PublishDataDto | null>(
    null
  );
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const fetchPublishData = async () => {
    setUserNameList(new Set(["ALL"]));
    setCheckedList([]);
    setSelectedUserData([]);
    getAllPublishData(user.productCode, user.auth).then(async (res) => {
      const resData = await res.json();
      setPublishedUserData(resData);
      if (selectedUser === "ALL") {
        setSelectedUserData(resData);
      } else {
        let count = 0;
        resData.forEach((userData: PublishDataDto) => {
          if (userData.createdBy === selectedUser) {
            setSelectedUserData((prev) => [...prev, userData]);
            count += 1;
          }
        });
        if (count === 0) {
          setSelectedUser("ALL");
          setSelectedUserData(resData);
        }
      }
      const createdBySet = new Set<string>(
        resData.map((user: PublishDataDto) => user.createdBy)
      );
      setUserNameList(
        (prev) => new Set([...Array.from(prev), ...Array.from(createdBySet)])
      );
      setCurrentPage(1);
      getAllClientsReviewFiles(user.auth, user.productCode).then(
        async (res) => {
          if (res.ok) {
            const reviewData = await res.json();
            setReviewFeatures(reviewData);
          } else {
            setReviewFeatures([]);
          }
        }
      );
    });
    dispatch(
      login({
        userId: user.userId,
        password: user.password,
        email: user.email,
        role: user.role,
        permissionsList: user.permissionsList,
        productCode: user.productCode,
        firstName: user.firstName,
        lastName: user.lastName,
        products: user.products,
        filesStatus: false,
        clientId: user.clientId,
        clientCode: user.clientCode,
        auth: user.auth,
      })
    );
  };
  useEffect(() => {
    fetchPublishData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.productCode]);

  const handleUserSelect = (event: any) => {
    let { value } = event.target;
    setSelectedUser(value);
    setCurrentPage(1);
    if (value === "ALL") {
      setSelectedUserData(publishedUserData);
    } else {
      setSelectedUserData([]);
      publishedUserData.forEach((userData: PublishDataDto) => {
        if (userData.createdBy === value) {
          setSelectedUserData((prev) => [...prev, userData]);
        }
      });
    }
  };

  const handleSelect = (userIndex: number) => {
    const index = checkedList.indexOf(currentPageItems[userIndex]);
    if (index !== -1) {
      const updatedFeatures = [...checkedList];
      updatedFeatures.splice(index, 1);
      setCheckedList(updatedFeatures);
    } else {
      setCheckedList([...checkedList, currentPageItems[userIndex]]);
    }
  };

  const handleSelectAll = () => {
    const currentList: PublishDataDto[] = currentPageItems;
    if (currentPageItems.every((item) => checkedList.includes(item))) {
      // For deSelecting All
      const newSelectedList = checkedList.filter(
        (item) => !currentPageItems.includes(item)
      );
      setCheckedList(newSelectedList);
    } else {
      // For Selecting All
      const newSelectedList = currentList.filter(
        (featureName) => !checkedList.includes(featureName)
      );
      setCheckedList([...checkedList, ...newSelectedList]);
    }
  };

  const handleItemsPerPageChange = (event: any) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handleRequestReject = (publishDataDto?: PublishDataDto) => {
    setCommentPopup(true);
    setReviewStatus("reject");
    if (publishDataDto) setPublishDataDto(publishDataDto);
  };

  const handleRequestApprove = (publishDataDto?: PublishDataDto) => {
    setCommentPopup(true);
    setReviewStatus("approve");
    if (publishDataDto) setPublishDataDto(publishDataDto);
  };

  const handleCommentOk = () => {
    isLoading(true);
    if (reviewStatus === "approve") {
      if (checkedList.length === 0 && publishDataDto) {
        publishCommitApprove(
          [publishDataDto],
          reviewFeatures ?? [],
          reviewComment,
          user.productCode,
          user.auth
        ).then(async (res) => {
          if (res.ok) {
            fetchPublishData();
            setCheckedList([]);
            isLoading(false);
            const response = await res.json();
            if (response.length === 0) {
              toast.success("File Approved Successfully");
            } else {
              let errormessage = "";
              response.forEach((PRID: number) => {
                errormessage = errormessage + PRID + ", ";
              });
              toast.error(
                "Failed to Approve PRId " +
                  errormessage.substring(0, errormessage.length - 2)
              );
            }
          } else {
            setCheckedList([]);
            isLoading(false);
          }
        });
      } else {
        publishCommitApprove(
          checkedList,
          reviewFeatures ?? [],
          reviewComment,
          user.productCode,
          user.auth
        ).then(async (res) => {
          if (res.ok) {
            fetchPublishData();
            setCheckedList([]);
            isLoading(false);
            const response = await res.json();
            if (response.length === 0) {
              toast.success("Files Approved Successfully");
            } else {
              let errormessage = "";
              response.forEach((PRID: number) => {
                errormessage = errormessage + PRID + ", ";
              });
              toast.error(
                "Failed to Approve PRId's " +
                  errormessage.substring(0, errormessage.length - 2)
              );
            }
          } else {
            setCheckedList([]);
            isLoading(false);
          }
        });
      }
    } else if (reviewStatus === "reject") {
      if (checkedList.length === 0 && publishDataDto) {
        publishCommitReject(
          [publishDataDto],
          reviewComment,
          user.productCode,
          user.auth
        ).then(async (res) => {
          if (res.ok) {
            fetchPublishData();
            setCheckedList([]);
            isLoading(false);
            const response = await res.json();
            if (response.length === 0) {
              toast.success("File Rejected Successfully");
            } else {
              let errormessage = "";
              response.forEach((RequestId: number) => {
                errormessage = errormessage + RequestId + ", ";
              });
              toast.error(
                "Failed to Reject RequestId " +
                  errormessage.substring(0, errormessage.length - 2)
              );
            }
          } else {
            setCheckedList([]);
            isLoading(false);
          }
        });
      } else {
        publishCommitReject(
          checkedList,
          reviewComment,
          user.productCode,
          user.auth
        ).then(async (res) => {
          if (res.ok) {
            fetchPublishData();
            setCheckedList([]);
            isLoading(false);
            const response = await res.json();
            if (response.length === 0) {
              toast.success("Files Rejected Successfully");
            } else {
              let errormessage = "";
              response.forEach((RequestId: number) => {
                errormessage = errormessage + RequestId + ", ";
              });
              toast.error(
                "Failed to Reject RequestId's " +
                  errormessage.substring(0, errormessage.length - 2)
              );
            }
          } else {
            setCheckedList([]);
            isLoading(false);
          }
        });
      }
    }
    setCommentPopup(false);
    setReviewComment("");
    setReviewStatus("");
    setPublishDataDto(null);
  };

  const handleCommentCancel = () => {
    setCommentPopup(false);
    setReviewComment("");
    setReviewStatus("");
    setPublishDataDto(null);
  };

  const handleViewLink = (featureName: string) => {
    const findFeature =
      reviewFeatures &&
      reviewFeatures.find((feature: Feature) => {
        return feature.name === featureName.replaceAll("_", " ");
      });
    if (findFeature) {
      setFeatureData(findFeature);
    } else {
      setNotFoundPopup(true);
    }
  };

  const handleViewBack = () => {
    setFeatureData(null);
  };

  const handleNotFoundCancel = () => {
    setNotFoundPopup(false);
  };
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentPageItems = selectedUserData?.slice(firstIndex, lastIndex);

  if (reviewFeatures === null) {
    return (
      <Box className="loaderIconStylings">
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <Box>
      {isDisable && <Box className={publishstyles.overlay}></Box>}
      {isDisable && (
        <Box className={publishstyles.loaderContainer}>
          <CircularProgress disableShrink />
        </Box>
      )}
      {!featureData && (
        <>
          <Box sx={{ marginBottom: "5rem" }}>
            <FormControl sx={{ width: "18%", margin: "1rem" }}>
              <InputLabel>Username</InputLabel>
              <Select
                size="small"
                value={selectedUser}
                label="userList"
                name="userList"
                onChange={handleUserSelect}
                defaultValue="ALL"
              >
                {Array.from(userNameList).map((username) => (
                  <MenuItem
                    key={username}
                    value={username}
                  >
                    <ListItemText primary={username} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Container className={reviewStylings.mainContainer}>
              <Box height="64vh">
                <Typography
                  variant="h5"
                  sx={{ padding: "2%" }}
                >
                  Publish Requests
                </Typography>
                <TableContainer
                  sx={{ maxHeight: "52vh", position: "sticky" }}
                  className="tableBody"
                >
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="table-row-odd">
                      <TableRow>
                        <TableCell className="tableHeader">
                          <Checkbox
                            checked={
                              currentPageItems.length !== 0 &&
                              currentPageItems.every((item) =>
                                checkedList.includes(item)
                              )
                            }
                            disabled={currentPageItems.length === 0}
                            onChange={handleSelectAll}
                          />
                        </TableCell>
                        <TableCell className="tableHeader">
                          Request Id
                        </TableCell>
                        <TableCell className="tableHeader">File Name</TableCell>
                        <TableCell className="tableHeader">
                          Submitted By
                        </TableCell>
                        <TableCell className="tableHeader">
                          Submitted Date
                        </TableCell>
                        <TableCell className="tableHeader">Comment</TableCell>
                        <TableCell className="tableHeader"> Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentPageItems.length > 0 ? (
                        <>
                          {currentPageItems.map((userData, userIndex) => (
                            <TableRow key={userIndex}>
                              <TableCell>
                                <Checkbox
                                  checked={checkedList.includes(userData)}
                                  onChange={() => handleSelect(userIndex)}
                                />
                              </TableCell>
                              <TableCell>{userData.publishId}</TableCell>
                              <TableCell>
                                <Link
                                  sx={{
                                    color: "#fe524a",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() =>
                                    handleViewLink(userData.featureName)
                                  }
                                >
                                  {userData.featureName}
                                </Link>
                              </TableCell>
                              <TableCell>{userData.createdBy}</TableCell>
                              <TableCell>
                                {dayjs(userData.createdTimeStamp).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "200px",
                                  overflow: "hidden",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {userData.publishComment || " - "}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  size="large"
                                  disableRipple={true}
                                  disabled={
                                    !user.permissionsList.includes(
                                      "ACCEPT/REJECT FEATURE"
                                    ) || checkedList.length !== 0
                                  }
                                  onClick={() => handleRequestReject(userData)}
                                >
                                  <HighlightOffIcon />
                                </IconButton>
                                <IconButton
                                  size="large"
                                  disableRipple={true}
                                  disabled={
                                    !user.permissionsList.includes(
                                      "ACCEPT/REJECT FEATURE"
                                    ) || checkedList.length !== 0
                                  }
                                  onClick={() => handleRequestApprove(userData)}
                                >
                                  <CheckCircleOutlineIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      ) : (
                        <TableRow>
                          <TableCell
                            align="center"
                            height="300vh"
                            colSpan={7}
                          >
                            <Typography variant="body2">No Requests</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Container>
          </Box>
          <Box className={reviewStylings.actionBox}>
            {/* pagination */}
            <Pagination
              rows={String(itemsPerPage)}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalDataLength={selectedUserData.length}
              dataPerPage={itemsPerPage}
              handleItemsPerPageChange={(e) => handleItemsPerPageChange(e)}
              fieldName="Requests"
            />
            <Box className={reviewStylings.actionButtonsBox}>
              <Button
                variant="outlined"
                disabled={
                  !user.permissionsList.includes("ACCEPT/REJECT FEATURE") ||
                  checkedList.length === 0
                }
                onClick={() => handleRequestReject()}
              >
                Reject All
              </Button>
              <Button
                variant="outlined"
                disabled={
                  !user.permissionsList.includes("ACCEPT/REJECT FEATURE") ||
                  checkedList.length === 0
                }
                onClick={() => handleRequestApprove()}
              >
                Approve All
              </Button>
            </Box>
          </Box>
        </>
      )}
      {featureData && (
        <ViewFeature
          fileIndex={-1}
          featureData={featureData}
          stepDefList={[]}
          onBack={handleViewBack}
        ></ViewFeature>
      )}
      {commentPopup && (
        <WarningPopup
          isOpen={true}
          onClose={handleCommentCancel}
          actionButtons={[
            {
              name: "Confirm",
              onButtonClick: handleCommentOk,
              isDisabled: reviewComment === "",
            },
            {
              name: "Cancel",
              onButtonClick: handleCommentCancel,
            },
          ]}
        >
          <Typography sx={{ paddingBottom: "5%" }}>
            Please add comment message for Review.
          </Typography>
          <TextareaAutosize
            style={{ width: "102%", height: "90px" }}
            required
            id="PRcomment"
            maxRows={10}
            minRows={4}
            placeholder="Enter comment"
            value={reviewComment}
            onChange={(e) => setReviewComment(e.target.value)}
          />
        </WarningPopup>
      )}
      {notFoundPopup && (
        <WarningPopup
          isOpen={true}
          onClose={handleNotFoundCancel}
        >
          <Typography sx={{ paddingBottom: "5%", textAlign: "center" }}>
            The file you're trying to access is unavailable.
          </Typography>
        </WarningPopup>
      )}
    </Box>
  );
};

export default Review;
