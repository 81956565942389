import { createSlice } from "@reduxjs/toolkit";

export const baseFilesSlice = createSlice({
  name: "baseFiles",
  initialState: {
    baseFiles: [],
  },
  reducers: {
    baseFeatureFilesUpdate: (state, action) => {
      state.baseFiles = action.payload;
    },
    baseFeatureFilesReset: (state) => {
      state.baseFiles = [];
    },
  },
});

export const { baseFeatureFilesUpdate, baseFeatureFilesReset } =
  baseFilesSlice.actions;
export const getBaseFeatureFiles = (state: any) => state.baseFiles.baseFiles;

export default baseFilesSlice.reducer;
