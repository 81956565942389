import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  downloadserverreport,
  getreportsummarydatafrmdb,
} from "../../clients/ReportsService";
import { selectUser } from "../../redux/slices/userSlice";
import { Report } from "../../types/Report";
import { convertUTCtoLocal } from "../../utils/convertUTCtoLocal";
import { formatDateStr } from "../../utils/FormatDateStr";
import { useInterval } from "../../utils/useInterval";
import Pagination from "../templates/Pagination";
import FilterComponent from "./FilterComponent";
import reportScreenStylings from "./ReportsScreen.module.css";

interface ReportsScreenProps {
  filterTableData?: Report | null;
}
const ReportsScreen: React.FC<ReportsScreenProps> = ({ filterTableData }) => {
  const location = useLocation();
  const state = location.state as ReportsScreenProps | null;
  const user = useSelector(selectUser);
  filterTableData = state?.filterTableData;

  const defaulttabledata: Report = filterTableData ?? {
    updatedBy: "",
    runId: null,
    jobId: 0,
    updatedTimeStamp: "",
    createdTimeStamp: "",
    tags: "",
    reportStartDate: null,
    reportEndDate: null,
    dockerContainerName: "",
    countryCode: "",
    status: "",
    jobDescription: null,
    productCode: user.productCode,
    scheduleJob: null,
  };
  const [reportRecords, setReportRecords] = useState<Report[] | undefined>(
    undefined
  );
  const initialData = () => {
    let initialData = { ...defaulttabledata };
    if (user.role !== "SYSTEM_ADMIN") {
      initialData.clientCode = user.clientCode;
    }
    if (user.role === "USER") {
      initialData.reportUser = user.userId;
    }
    return initialData;
  };
  const [filterReportRecords, setFilterReportRecords] =
    useState<Report>(initialData);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [reportsPerPage, setReportsPerPage] = useState<number>(5);

  function reportPath(report: Report): string {
    return `${process.env.REACT_APP_BASE_URL}/reports/${report.clientCode}/${
      report.productCode
    }/${report.reportUser}/${report?.runId?.toString()}/overview-features.html`;
  }
  const handleItemsPerPageChange = (event: any) => {
    setReportsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const filterdata = async (filterReports: Report) => {
    try {
      const serverReportsResponse = await getreportsummarydatafrmdb(
        user.auth,
        filterReports
      );
      const serverReportsData = await serverReportsResponse.json();
      if (!serverReportsResponse.ok) {
        throw new Error(
          `Server reports request failed with status ${serverReportsResponse.status}`
        );
      }
      if (serverReportsData.reports.length > 0) {
        serverReportsData.reports.sort((a: Report, b: Report) => {
          return b.runId !== null && a.runId !== null && b.runId - a.runId;
        });
        if (
          reportRecords &&
          reportRecords.length === serverReportsData.reports.length
        ) {
          setCurrentPage(currentPage);
        } else {
          setCurrentPage(1);
        }
        setReportRecords(serverReportsData.reports);
      } else {
        setReportRecords(serverReportsData.reports);
        setCurrentPage(1);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    filterdata({
      ...filterReportRecords,
      reportStartDate: filterReportRecords.reportStartDate
        ? formatDateStr(
            new Date(filterReportRecords.reportStartDate),
            "YYYY-MM-DD HH:mm:ss",
            false
          )
        : null,
      reportEndDate: filterReportRecords.reportEndDate
        ? formatDateStr(
            new Date(filterReportRecords.reportEndDate),
            "YYYY-MM-DD HH:mm:ss",
            false
          )
        : null,
    });
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    filterdata({ ...filterReportRecords, productCode: user.productCode });
    setCurrentPage(1);
  }, [user.productCode]);

  useInterval(
    () => {
      filterdata({
        ...filterReportRecords,
        reportStartDate: filterReportRecords.reportStartDate
          ? formatDateStr(
              new Date(filterReportRecords.reportStartDate),
              "YYYY-MM-DD HH:mm:ss",
              false
            )
          : null,
        reportEndDate: filterReportRecords.reportEndDate
          ? formatDateStr(
              new Date(filterReportRecords.reportEndDate),
              "YYYY-MM-DD HH:mm:ss",
              false
            )
          : null,
      });
    },
    parseInt(process.env.REFRESH_INTERVAL ?? "60000", 10)
  );

  if (loading || !reportRecords) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress disableShrink />
      </Box>
    );
  }

  const downloadreport = async (
    e: React.MouseEvent<HTMLButtonElement>,
    runId: number,
    reportUser: string,
    productCode: string
  ) => {
    e.preventDefault();
    setLoading(true);
    try {
      const downloadresponse = await downloadserverreport(
        user.auth,
        runId,
        reportUser,
        user.productCode
      );
      const blob = await downloadresponse.blob();
      if (downloadresponse.ok) {
        setLoading(false);
        toast.success("Downloaded Report: " + runId);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download =
          downloadresponse.headers
            .get("Content-Disposition")
            ?.split("filename=")[1] || `${runId}.zip`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        toast.error("Error Downloading Report" + downloadresponse.statusText);
      }
    } catch (error) {
      toast.error("Error Downloading Report" + error);
    }
  };

  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = reportRecords?.slice(
    indexOfFirstReport,
    indexOfLastReport
  );
  return (
    <>
      {reportRecords && (
        <Box>
          <Box sx={{ marginRight: "8%", marginLeft: "8%" }}>
            <Typography
              variant="h5"
              mb={"6px"}
              sx={{ paddingTop: "2.5%" }}
            >
              Reports Summary Details
            </Typography>
            <FilterComponent
              filterData={filterReportRecords}
              handleOnBack={(filterReportRecords: Report) => {
                setFilterReportRecords({ ...filterReportRecords });
                filterdata({ ...filterReportRecords });
              }}
            ></FilterComponent>

            <Paper sx={{ width: "100%" }}>
              <TableContainer
                component={Paper}
                sx={{ mt: "2px", maxHeight: ["328px"] }}
              >
                <Table
                  aria-label="Reports table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow sx={{ fontWeight: "600" }}>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: "600" }}
                      >
                        Run Id
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: "600" }}
                      >
                        Organization
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "600" }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "600" }}
                      >
                        Start Date
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "600" }}
                      >
                        End Date
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "600" }}
                      >
                        Job Description
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: "600" }}
                      >
                        Schedule Job Id
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: "600" }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {currentReports?.length === 0 && (
                    <TableBody
                      sx={{
                        overflowY: "scroll",
                        overflowX: "scroll",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={10}
                        >
                          <p>No Reports</p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {currentReports && currentReports.length > 0 && (
                    <TableBody
                      sx={{ overflowY: "scroll", overflowX: "scroll" }}
                    >
                      {currentReports &&
                        currentReports.length > 0 &&
                        currentReports.map((report: Report) => (
                          <TableRow
                            key={report.jobId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center">
                              {report.status === "Completed" ? (
                                <a
                                  href={reportPath(report)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {report.runId !== null ? (
                                    <Tooltip
                                      title={report.tags}
                                      placement="top"
                                    >
                                      <span>{report.runId}</span>
                                    </Tooltip>
                                  ) : null}
                                </a>
                              ) : (
                                <>
                                  <Tooltip
                                    title={report.tags}
                                    placement="top"
                                  >
                                    <span>{report.runId}</span>
                                  </Tooltip>
                                </>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {report.countryCode}
                            </TableCell>
                            <TableCell align="left">
                              {report.status.includes("InProgress")
                                ? report.status.split(" ")[0]
                                : report.status}
                            </TableCell>
                            <TableCell align="left">
                              {report.reportStartDate &&
                                formatDateStr(
                                  convertUTCtoLocal(report.reportStartDate),
                                  "YYYY-MM-DD HH:mm:ss",
                                  true
                                )}
                            </TableCell>
                            <TableCell align="left">
                              {report.reportEndDate !== null ? (
                                formatDateStr(
                                  convertUTCtoLocal(report.reportEndDate),
                                  "YYYY-MM-DD HH:mm:ss",
                                  true
                                )
                              ) : (
                                <p>Loading...</p>
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                maxWidth: "200px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {report.jobDescription}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                maxWidth: "200px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {report.scheduleJob &&
                                report.scheduleJob.userSpecificScheduleJobId}
                            </TableCell>
                            <TableCell align="center">
                              {report.reportEndDate != null ? (
                                <>
                                  <Tooltip
                                    title="View Report"
                                    placement="top"
                                  >
                                    <span>
                                      {report.status === "Failed" ? (
                                        <Link
                                          href={reportPath(report)}
                                          target="_blank"
                                          style={{
                                            pointerEvents: "none",
                                            cursor: "not-allowed",
                                            textDecoration: "none",
                                            color: "inherit",
                                          }}
                                        >
                                          <IconButton
                                            sx={{ padding: "4px" }}
                                            disabled={true}
                                          >
                                            <VisibilityIcon />
                                          </IconButton>
                                        </Link>
                                      ) : (
                                        <Link
                                          href={reportPath(report)}
                                          target="_blank"
                                        >
                                          <IconButton sx={{ padding: "4px" }}>
                                            <VisibilityIcon />
                                          </IconButton>
                                        </Link>
                                      )}
                                    </span>
                                  </Tooltip>
                                  <Tooltip
                                    title="Download Report"
                                    placement="top"
                                  >
                                    <span>
                                      <IconButton
                                        sx={{ padding: "4px" }}
                                        disabled={report.status === "Failed"}
                                        onClick={(e: any) =>
                                          downloadreport(
                                            e,
                                            report?.runId ?? 0,
                                            report
                                              ? report.reportUser
                                              : user.userId,
                                            report.productCode
                                          )
                                        }
                                      >
                                        <FileDownloadIcon />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                </>
                              ) : (
                                <CircularProgress
                                  size="30px"
                                  disableShrink
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Paper>
          </Box>

          <Box className={reportScreenStylings.actionBox}>
            <Box sx={{ marginLeft: "7rem" }}>
              <Pagination
                rows={String(reportsPerPage)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalDataLength={reportRecords.length}
                dataPerPage={reportsPerPage}
                handleItemsPerPageChange={(e) => handleItemsPerPageChange(e)}
                fieldName="Reports"
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ReportsScreen;
