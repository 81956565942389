import { HttpMethod } from "src/types/HttpMethod";
const apiUrl = `${process.env.REACT_APP_BASE_URL}/rs/automation`;

export const sendReviewRequestMail = async (
  featureArray: string[],
  auth: string
) => {
  const url = `${apiUrl}/publish/review/mail`;
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(featureArray),
  });
};

export const forgotPassword = async (username: string, email: string) => {
  const url = `${apiUrl}/forgotPassword/email/${username}/${email}`;
  return fetch(url, {
    method: HttpMethod.Post,
  });
};
