import { Feature } from "../types/Feature";
import { HttpMethod } from "../types/HttpMethod";

const featureApiUrl = `${process.env.REACT_APP_BASE_URL}/rs/automation/feature`;

export const getAllFeatureFiles = (
  locationlist: string[],
  auth: string,
  productCode: string
) => {
  const url = `${featureApiUrl}/${productCode}/get`;
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(locationlist),
  });
};

export const getReviewFiles = (
  userlist: Set<string>,
  auth: string,
  productCode: string
) => {
  const url = `${featureApiUrl}/${productCode}/getReviewFiles`;
  const userListArray = Array.from(userlist);
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userListArray),
  });
};

export const getAllClientsReviewFiles = (auth: string, productCode: string) => {
  const url = `${featureApiUrl}/${productCode}/getAllReviewFiles`;
  return fetch(url, {
    method: HttpMethod.Get,
    headers: {
      Authorization: auth,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const saveFeatureFile = (
  featureData: Feature,
  auth: string,
  productCode: string
) => {
  const url = `${featureApiUrl}/${productCode}/update`;

  return fetch(url, {
    method: HttpMethod.Patch,
    headers: {
      Authorization: auth,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(featureData),
  });
};

export const saveMultipleFeatureFile = (
  featureFiles: File[],
  auth: string,
  productCode: string
) => {
  const url = `${featureApiUrl}/${productCode}/featureFilesUpload`;
  const formData = new FormData();
  Array.from(featureFiles).forEach((file) => {
    formData.append(`featureFiles`, file);
  });
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
      Accept: "application/json",
    },
    body: formData,
  });
};

export const getAllStepDefs = (auth: string, productCode: string) => {
  const url = `${featureApiUrl}/${productCode}/step-def/get`;
  return fetch(url, {
    method: HttpMethod.Get,
    headers: { Authorization: auth },
  });
};

export const getAllCategories = (auth: string) => {
  const url = `${featureApiUrl}/category/get`;
  return fetch(url, {
    method: HttpMethod.Get,
    headers: { Authorization: auth },
  });
};

export const getFeatureNamesByCategoryId = (
  categoryId: number[],
  auth: string
) => {
  const url = `${featureApiUrl}/categoryBasedFeatures`;
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(categoryId),
  });
};

export const downLoadFeatureFiles = (
  features: string[],
  auth: string,
  productCode: string
) => {
  const url = `${featureApiUrl}/${productCode}/download`;

  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(features),
  });
};

export const deleteFeatureFiles = (
  features: string[],
  auth: string,
  productCode: string
) => {
  const url = `${featureApiUrl}/${productCode}/delete`;

  return fetch(url, {
    method: HttpMethod.Delete,
    headers: {
      Authorization: auth,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(features),
  });
};
