import { Visibility, VisibilityOff } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import lodash from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getClientData, setUpClient } from "src/clients/ClientService";
import { selectUser } from "src/redux/slices/userSlice";
import { AppCredentials, ClientData, Products } from "src/types/UserData";
import { useClientDataContext } from "../context/Context";
import WarningPopup from "../modal/WarningPopup";
import createClientStyles from "./CreateClient.module.css";

interface createClientProps {
  mode: string;
  initialData: ClientData;
  productsList: Products[];
  handleOnPopUpSave: () => Promise<ClientData>;
  handleOnCreateClientCancel: () => void;
}
const CreateClient = ({
  mode,
  initialData,
  productsList,
  handleOnPopUpSave,
  handleOnCreateClientCancel,
}: createClientProps) => {
  const [initialClientData, setInitialClientData] = useState(initialData);
  const { clientData, setClientData } = useClientDataContext();
  const [isDiscardPopUpOpen, setIsDiscardPopUpOpen] = useState<boolean>(false);
  const [clientNameError, setClientNameError] = useState<boolean>(false);
  const [clientCodeError, setClientCodeError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [ftpServerError, setFtpServerError] = useState<boolean>(false);
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);
  const [isDisable, isLoading] = useState<boolean>(false);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (clientNameError === true) {
      setClientNameError(false);
    }
    if (clientCodeError === true) {
      setClientCodeError(false);
    }
    if (ftpServerError === true) {
      setFtpServerError(false);
    }
  }, [clientData.clientName, clientData.clientCode, clientData.ftpServer]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setClientData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAppCredentialsInputChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const [, index, field] = name.split(".");
    setClientData((prevState) => {
      const appCredentialsList = [...prevState.appCredentialsList];
      const appCredentials = {
        ...appCredentialsList[parseInt(index, 10)],
        [field]: value,
      };
      appCredentialsList[parseInt(index, 10)] = appCredentials;
      return { ...prevState, appCredentialsList };
    });
  };

  const validateClientFields = async () => {
    if (
      clientData.clientName === "" ||
      clientData.clientCode === "" ||
      clientData.ftpServer === ""
    ) {
      if (clientData.clientName === "") {
        setClientNameError(true);
      }
      if (clientData.clientCode === "") {
        setClientCodeError(true);
      }
      if (clientData.ftpServer === "") {
        setFtpServerError(true);
      }
    } else if (clientData.appCredentialsList.length === 0) {
      toast.error("Atleast One Product Required");
    } else {
      handleOnPopUpSave().then((res) => {
        setInitialClientData(res);
      });
    }
  };

  const handleClientSetup = () => {
    isLoading(true);
    setUpClient(clientData.clientCode, user.userId, user.password).then(
      (res) => {
        if (res.ok) {
          toast.success("Client setup successfully");
        } else {
          toast.error("Client setup Failed");
        }
        getClientData(user.userId, user.password, clientData.clientCode).then(
          async (clientRes) => {
            const resData = await clientRes.json();
            setInitialClientData({ ...resData });
            setClientData({ ...resData });
          }
        );
        isLoading(false);
        handleOnCreateClientCancel();
      }
    );
  };

  const handleOnAddProducts = () => {
    setClientData((prevState) => ({
      ...prevState,
      appCredentialsList: [
        ...prevState.appCredentialsList,
        {
          url: "",
          authUrl: "",
          authToken: "",
          grantType: "",
          prdUsername: "",
          prdPassword: "",
          repoName: "",
          products: { productId: 0, productName: "" },
          isActive: true,
        },
      ],
    }));
    setExpandedIndex(clientData.appCredentialsList.length);
  };

  const handleTogglePasswordVisibility = (index: number) => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // const handleAppCredentialRemoveIcon = (index: number) => {
  //   setClientData((prevState) => {
  //     const appCredentialsList = [...prevState.appCredentialsList];
  //     appCredentialsList.splice(index, 1);
  //     return { ...prevState, appCredentialsList };
  //   });
  // };

  const handleAppCredentialSwitchIcon = (index: number, event: any) => {
    setClientData((prevState) => {
      const appCredentialsList = [...prevState.appCredentialsList];
      const currentAppCredential = { ...appCredentialsList[index] };
      currentAppCredential.isActive = event.target.checked;
      appCredentialsList[index] = currentAppCredential;
      const userDataList = [...prevState.userDataList];
      if (event.target.checked) {
        userDataList.map((user) => {
          const userAppCred = [...user.userAppCredentials];
          userAppCred.map((userApp) => {
            if (
              userApp.products.productId ===
              currentAppCredential.products.productId
            ) {
              if (user.status === "INACTIVE" && user.onBoarded) {
                user.status = "ACTIVE";
              }
            }
          });
        });
      }
      return { ...prevState, appCredentialsList, userDataList };
    });
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClientData((prevState) => ({
      ...prevState,
      isActive: event.target.checked,
    }));
  };

  const handleOnProductChange = (index: number, product: Products) => {
    setClientData((prevState) => {
      const appCredentialsList = [...prevState.appCredentialsList];
      appCredentialsList[index] = {
        ...appCredentialsList[index],
        products: product,
      };
      return { ...prevState, appCredentialsList };
    });
  };

  const handleOnClientSave = () => {
    validateClientFields();
    setIsDiscardPopUpOpen(false);
  };

  const handleOnClientCancel = () => {
    if (!lodash.isEqual(initialClientData, clientData)) {
      setIsDiscardPopUpOpen(true);
    } else {
      handleOnCreateClientCancel();
    }
  };

  const handleOnPopUpDiscard = () => {
    setIsDiscardPopUpOpen(false);
    handleOnCreateClientCancel();
  };

  const handleOnWarningPopUpClose = () => {
    setIsDiscardPopUpOpen(false);
  };

  const hasEmptyOrNullValues = (appCredential: AppCredentials): boolean => {
    return (
      !appCredential.url ||
      !appCredential.authUrl ||
      !appCredential.authToken ||
      !appCredential.grantType ||
      !appCredential.prdUsername ||
      !appCredential.prdPassword ||
      appCredential.products.productId === 0 ||
      !appCredential.products.productName
    );
  };

  const handleChangeAccordion =
    (index: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedIndex(isExpanded ? index : -1);
    };

  return (
    <>
      {isDisable && <Box className={createClientStyles.overlay}></Box>}
      {isDisable && (
        <Box className={createClientStyles.loaderContainer}>
          <CircularProgress disableShrink />
        </Box>
      )}
      <Box className={createClientStyles.rootContainer}>
        <Box>
          <Typography
            variant="h5"
            sx={{ padding: "2%" }}
          >
            {mode === "Edit" ? "Edit Client" : "Create Client"}
          </Typography>
        </Box>
        <Box sx={{ padding: "0rem 2rem 4rem 2rem", height: "57vh" }}>
          <Stack
            direction="column"
            spacing={1}
          >
            <Stack
              direction="row"
              spacing={5}
            >
              <Box className={createClientStyles.stackBox}>
                <Typography
                  variant="body1"
                  className={createClientStyles.typographyBox}
                >
                  Client Name
                </Typography>
                <TextField
                  type="text"
                  size="small"
                  placeholder="Client Name"
                  fullWidth
                  name="clientName"
                  value={clientData.clientName}
                  onChange={handleInputChange}
                  disabled={initialClientData.clientName !== ""}
                  helperText={clientNameError ? "Invalid Client Name" : ""}
                />
              </Box>
              <Box className={createClientStyles.stackBox}>
                <Typography
                  variant="body1"
                  className={createClientStyles.typographyBox}
                >
                  Client Code
                </Typography>
                <TextField
                  type="text"
                  size="small"
                  placeholder="Client Code"
                  fullWidth
                  name="clientCode"
                  value={clientData.clientCode}
                  onChange={handleInputChange}
                  disabled={initialClientData.clientCode !== ""}
                  helperText={clientCodeError ? "Invalid Client Code" : ""}
                />
              </Box>
              <Box className={createClientStyles.stackBox}>
                <Typography
                  variant="body1"
                  className={createClientStyles.typographyBox}
                >
                  FTP Server
                </Typography>
                <TextField
                  type="text"
                  size="small"
                  placeholder="FTP Server"
                  fullWidth
                  name="ftpServer"
                  value={clientData.ftpServer}
                  onChange={handleInputChange}
                  helperText={ftpServerError ? "Invalid FTP Server" : ""}
                />
              </Box>
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              justifyContent={"flex-end"}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  variant="body1"
                  className={createClientStyles.typographyBox}
                >
                  Active
                </Typography>
                <Switch
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                  checked={clientData.isActive}
                  onChange={(e) => handleStatusChange(e)}
                />
              </Box>
            </Stack>

            <Stack direction="row">
              <Button
                sx={{ width: "100%" }}
                onClick={handleOnAddProducts}
                disabled={
                  clientData.appCredentialsList.length === productsList.length
                }
                endIcon={<AddCircleOutlineIcon />}
              >
                Add Products
              </Button>
            </Stack>

            <Box sx={{ maxHeight: "37vh", overflowY: "scroll" }}>
              {clientData.appCredentialsList.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: "30vh",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">No Products</Typography>
                </Box>
              ) : (
                clientData.appCredentialsList.map((appCredential, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                      margin: "1rem 0rem",
                    }}
                    key={index}
                  >
                    <Accordion
                      expanded={expandedIndex === index}
                      onChange={handleChangeAccordion(index)}
                      sx={{ width: "100%" }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                          variant="body1"
                          className={createClientStyles.typographyBox}
                        >
                          {appCredential.products.productName === ""
                            ? "Product Name"
                            : appCredential.products.productName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack
                          direction={"column"}
                          spacing={2}
                        >
                          <Stack
                            direction="row"
                            spacing={10}
                          >
                            <Box className={createClientStyles.stackBox}>
                              <Typography
                                variant="body1"
                                className={createClientStyles.typographyBox1}
                              >
                                Product
                              </Typography>
                              <FormControl
                                fullWidth
                                sx={{ alignSelf: "center" }}
                              >
                                <Select
                                  value={appCredential.products.productName}
                                  size="small"
                                  name={`appCredentialsList.${index}.products`}
                                  displayEmpty
                                  onChange={(event) => {
                                    const selectedProduct = productsList.find(
                                      (p) =>
                                        p.productName === event.target.value
                                    );
                                    if (selectedProduct)
                                      handleOnProductChange(
                                        index,
                                        selectedProduct
                                      );
                                  }}
                                  renderValue={(value) =>
                                    value !== "" ? (
                                      value
                                    ) : (
                                      <span style={{ color: "#aaa" }}>
                                        Product
                                      </span>
                                    )
                                  }
                                >
                                  {productsList.map(
                                    (product) =>
                                      !clientData.appCredentialsList.some(
                                        (appCred, appIndex) =>
                                          appIndex !== index &&
                                          appCred.products.productName ===
                                            product.productName
                                      ) && (
                                        <MenuItem
                                          key={product.productId}
                                          value={product.productName}
                                        >
                                          {product.productName}
                                        </MenuItem>
                                      )
                                  )}
                                </Select>
                              </FormControl>
                            </Box>
                            <Box className={createClientStyles.stackBox}>
                              <Typography
                                variant="body1"
                                className={createClientStyles.typographyBox1}
                              >
                                URL
                              </Typography>
                              <TextField
                                type="text"
                                size="small"
                                placeholder="URL"
                                fullWidth
                                name={`appCredentialsList.${index}.url`}
                                value={appCredential.url}
                                onChange={handleAppCredentialsInputChange}
                              />
                            </Box>
                          </Stack>

                          <Stack
                            direction="row"
                            spacing={10}
                          >
                            <Box className={createClientStyles.stackBox}>
                              <Typography
                                variant="body1"
                                className={createClientStyles.typographyBox1}
                              >
                                Auth URL
                              </Typography>
                              <TextField
                                type="text"
                                size="small"
                                placeholder="Auth URL"
                                fullWidth
                                name={`appCredentialsList.${index}.authUrl`}
                                value={appCredential.authUrl}
                                onChange={handleAppCredentialsInputChange}
                              />
                            </Box>
                            <Box className={createClientStyles.stackBox}>
                              <Typography
                                variant="body1"
                                className={createClientStyles.typographyBox1}
                              >
                                Grant Type
                              </Typography>
                              <TextField
                                type="text"
                                size="small"
                                placeholder="Grant Type"
                                fullWidth
                                name={`appCredentialsList.${index}.grantType`}
                                value={appCredential.grantType}
                                onChange={handleAppCredentialsInputChange}
                              />
                            </Box>
                          </Stack>

                          <Stack
                            direction="row"
                            spacing={10}
                          >
                            <Box className={createClientStyles.stackBox}>
                              <Typography
                                variant="body1"
                                className={createClientStyles.typographyBox1}
                              >
                                User Name
                              </Typography>
                              <TextField
                                type="text"
                                size="small"
                                placeholder="Product User Name"
                                fullWidth
                                name={`appCredentialsList.${index}.prdUsername`}
                                value={appCredential.prdUsername}
                                onChange={handleAppCredentialsInputChange}
                              />
                            </Box>

                            <Box className={createClientStyles.stackBox}>
                              <Typography
                                variant="body1"
                                className={createClientStyles.typographyBox1}
                              >
                                Password
                              </Typography>
                              <TextField
                                type={showPassword ? "text" : "password"}
                                size="small"
                                placeholder="Product Password"
                                fullWidth
                                name={`appCredentialsList.${index}.prdPassword`}
                                autoComplete="new-password"
                                value={appCredential.prdPassword}
                                onChange={handleAppCredentialsInputChange}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() =>
                                          handleTogglePasswordVisibility(index)
                                        }
                                        edge="end"
                                      >
                                        {showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                          </Stack>

                          <Stack
                            direction="row"
                            spacing={10}
                          >
                            <Box className={createClientStyles.stackBox}>
                              <Typography
                                variant="body1"
                                className={createClientStyles.typographyBox1}
                              >
                                Auth Token
                              </Typography>
                              <TextField
                                type="text"
                                size="small"
                                placeholder="Auth Token"
                                fullWidth
                                name={`appCredentialsList.${index}.authToken`}
                                value={appCredential.authToken}
                                onChange={handleAppCredentialsInputChange}
                              />
                            </Box>
                            <Box className={createClientStyles.stackBox}>
                              <Typography
                                variant="body1"
                                className={createClientStyles.typographyBox1}
                              >
                                Repo Name
                              </Typography>
                              <TextField
                                type="text"
                                size="small"
                                placeholder="Repo Name"
                                fullWidth
                                name={`appCredentialsList.${index}.repoName`}
                                value={appCredential.repoName}
                                onChange={handleAppCredentialsInputChange}
                              />
                            </Box>
                          </Stack>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    <Box sx={{ display: "flex" }}>
                      <IconButton
                        size="small"
                        onClick={(e) => handleAppCredentialSwitchIcon(index, e)}
                      >
                        <Switch checked={appCredential.isActive} />
                      </IconButton>
                      {/* <IconButton
                        size="small"
                        onClick={() => handleAppCredentialRemoveIcon(index)}
                      >
                        <RemoveIcon />
                      </IconButton> */}
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box className={createClientStyles.actionBox}>
        <Box className={createClientStyles.actionButtonsBox}>
          <Button
            variant="outlined"
            disabled={
              !(
                clientData.isActive &&
                ((clientData.status !== "ACTIVE" &&
                  clientData.userDataList.some(
                    (user) => user.role.roleName === "ADMIN"
                  )) ||
                  (clientData.status === "ACTIVE" &&
                    clientData.userDataList &&
                    (!clientData.userDataList.every((user) => user.onBoarded) ||
                      clientData.userDataList.some(
                        (user) =>
                          user.userAppCredentials &&
                          user.userAppCredentials.some(
                            (credential) => !credential.onBoarded
                          )
                      ))))
              )
            }
            onClick={handleClientSetup}
          >
            Client Setup
          </Button>
          <Button
            variant="outlined"
            onClick={handleOnClientSave}
            disabled={
              lodash.isEqual(initialClientData, clientData) ||
              clientData.appCredentialsList.some(hasEmptyOrNullValues)
            }
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={handleOnClientCancel}
          >
            Cancel
          </Button>
        </Box>
      </Box>
      {isDiscardPopUpOpen && (
        <WarningPopup
          isOpen={true}
          onClose={handleOnWarningPopUpClose}
          actionButtons={[
            {
              name: "Save",
              onButtonClick: handleOnClientSave,
            },
            {
              name: "Discard",
              onButtonClick: handleOnPopUpDiscard,
            },
          ]}
        >
          <Box className={createClientStyles.warningPopUpBox}>
            <WarningIcon />
            <Typography variant="body2">
              There are changes in the data do you want to save it.
            </Typography>
          </Box>
        </WarningPopup>
      )}
    </>
  );
};

export default CreateClient;
