import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { decryptString, resetPassword } from "../../clients/UserService";
import resetPasswordStyles from "./ResetPassword.module.css";

export const ResetPassword = () => {
  const [passwordValidations, setPasswordValidations] = useState<
    { valid: boolean; message: string }[]
  >([]);
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [Instructions, setInstructions] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [timestamp, setTimestamp] = useState<number>(-1);
  const [isButtondisable, setIsButtonDisable] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const location = useLocation();
  const key = null;

  const checkPasswordStrength = (password: string) => {
    const validations = [
      { regex: /[a-z]/, message: "At least one lowercase letter," },
      { regex: /[A-Z]/, message: "uppercase letter," },
      { regex: /[0-9]/, message: "digit," },
      {
        regex: /[!@#$%^&*()\-_=+[\]{}|;:'",.<>?/~`]/,
        message: "special character,",
      },
      { regex: /.{6,}/, message: "Minimum 6 characters" },
    ];

    let isValid = true;

    const validationResults = validations.map(({ regex, message }) => ({
      valid: regex.test(password),
      message,
    }));

    validationResults.forEach((error) => {
      if (!error.valid) {
        isValid = false;
      }
    });

    setIsButtonDisable(!isValid);
    return validationResults;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    var username = queryParams.get("username") || "";
    var timestamp = queryParams.get("timestamp") || "";
    decryptString(key, username)
      .then(async (res) => {
        const resData = await res.text();
        setUsername(resData);
      })
      .catch((error) => {
        console.error("Error decrypting string:", error);
      });
    decryptString(key, timestamp)
      .then(async (res) => {
        try {
          const resData = await res.text();
          const parsedTimestamp = parseInt(resData);
          setTimestamp(parsedTimestamp);
          if (parsedTimestamp < Date.now()) {
            setIsExpired(true);
          }
        } catch (error) {
          console.error("Error parsing decrypted string:", error);
        }
      })
      .catch((error) => {
        console.error("Error decrypting string:", error);
      });
  }, []);

  useEffect(() => {
    setPasswordValidations(checkPasswordStrength(password));
  }, [password]);

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword(event.target.value);
    setPasswordError(false);
    setInstructions(true);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordError(false);
    setConfirmPassword(event.target.value);
    setInstructions(false);
  };

  const handleNewPasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = () => {
    if (timestamp > Date.now()) {
      const passwordRegex =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_=+[\]{}|;:'",.<>?/~`]).{6,}$/;
      if (passwordRegex.test(password)) {
        if (password === confirmPassword) {
          resetPassword(username, password).then(async (res) => {
            if (res.status === 200) {
              const resData = await res.text();
              toast.success("Password updated successful for " + resData);
              setPassword("");
              setConfirmPassword("");
            } else {
              toast.error("Failed to update Password for " + username);
            }
          });
        } else {
          setPasswordError(true);
        }
      } else {
        setPasswordError(true);
      }
    } else {
      toast.error("Link Expired");
    }
  };

  if (isExpired) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "87vh",
          color: "red",
        }}
      >
        <Typography variant="h4">Link Expired</Typography>
      </Box>
    );
  }
  return (
    <Box>
      <Container className={resetPasswordStyles.changepasswordContainer}>
        <Container className={resetPasswordStyles.changepasswordFormContainer}>
          <Typography
            variant="h5"
            sx={{ paddingBottom: "3%" }}
          >
            Reset Password
          </Typography>

          <Box sx={{ paddingBottom: "6%" }}>
            {passwordError && (
              <Typography className={resetPasswordStyles.passwordMessage}>
                Ouch! please check your password
              </Typography>
            )}
          </Box>
          <Stack
            direction="column"
            alignItems="center"
            spacing={2}
          >
            <TextField
              type={showPassword ? "text" : "password"}
              fullWidth
              id="filled-basic"
              size="small"
              value={password}
              label="New Password"
              variant="outlined"
              onChange={handleNewPasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleNewPasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />
          </Stack>

          {Instructions && (
            <Grid
              container
              direction="row"
            >
              {passwordValidations.map((validation, index) => (
                <Grid
                  item
                  key={index}
                >
                  <Typography
                    variant="caption"
                    color={validation.valid ? "green" : "error"}
                  >
                    {validation.message + "\t"}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}

          <Stack
            direction="column"
            alignItems="center"
            spacing={2}
            paddingTop="5%"
          >
            <TextField
              id="standard-password-input"
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              value={confirmPassword}
              size="small"
              autoComplete="current-password"
              variant="outlined"
              onChange={handleConfirmPasswordChange}
              required
              disabled={isButtondisable}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleConfirmPasswordVisibility}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Link
              href="login"
              className={resetPasswordStyles.loginLink}
            >
              Back to Sign In
            </Link>
            <Button
              variant="outlined"
              disabled={isButtondisable}
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Stack>
        </Container>
      </Container>
    </Box>
  );
};
