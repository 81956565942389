import {
  Box,
  Button,
  CircularProgress,
  Link,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import lodash from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getClientData } from "src/clients/ClientService";
import { selectUser } from "src/redux/slices/userSlice";
import { ClientData, UserData } from "src/types/UserData";
import { useClientDataContext } from "../context/Context";
import Pagination from "../templates/Pagination";
import CreateUser from "./CreateUser";
import listOfUsersStyles from "./ListOfUsers.module.css";

interface listOfUsersProps {
  clientCode: string;
  handleOnPopUpSave: () => Promise<ClientData>;
}

const ListOfUsers = ({ clientCode, handleOnPopUpSave }: listOfUsersProps) => {
  const [initialUserList, setInitialUserList] = useState<UserData[]>([]);
  const { clientData } = useClientDataContext();
  const [selectedRow, setSelectedRow] = useState<number>(0);
  const [listOfUsers, setListOfUsers] = useState<UserData[]>(
    clientData.userDataList
  );
  const [actionBoolean, setActionBoolean] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const user = useSelector(selectUser);

  useEffect(() => {
    setListOfUsers(clientData.userDataList);
    if (clientData.userDataList.length > 0) {
      setCurrentPage(1);
    }
  }, [clientData.userDataList]);

  useEffect(() => {
    fetchClient();
  }, []);

  const fetchClient = () => {
    getClientData(user.userId, user.password, clientCode).then(
      async (clientRes) => {
        if (clientRes.ok) {
          const clientResData = await clientRes.json();
          setInitialUserList([...clientResData.userDataList]);
        }
      }
    );
  };

  const handleRowSelect = (id: number) => {
    if (selectedRow !== id) {
      setSelectedRow(id);
    } else {
      setSelectedRow(0);
    }
  };

  const handleItemsPerPageChange = (event: any) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const compareUserLists = (
    initialList: UserData[],
    updatedList: UserData[]
  ) => {
    if (initialList.length !== updatedList.length) {
      return false;
    }

    for (let i = 0; i < initialList.length; i++) {
      if (!lodash.isEqual(initialList[i], updatedList[i])) {
        return false;
      }
    }

    return true;
  };

  const handleOnSaveAllUsers = () => {
    handleOnPopUpSave().then((res) => {
      setInitialUserList(res.userDataList);
    });
  };

  const handleOnSuccessBack = () => {
    fetchClient();
    setListOfUsers(clientData.userDataList);
    handleOnCreateUserCancel();
  };

  const handleOnCreateUserCancel = () => {
    setActionBoolean(false);
    setMode("");
    setSelectedRow(0);
  };

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentPageItems = listOfUsers?.slice(firstIndex, lastIndex);

  if (!listOfUsers) {
    return (
      <Box className="loaderIconStylings">
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <>
      {!actionBoolean && (
        <Box>
          <Box className={listOfUsersStyles.mainContainer}>
            <Box height="60vh">
              <Typography
                variant="h5"
                sx={{ padding: "2%" }}
              >
                List Of users
              </Typography>
              <TableContainer
                sx={{
                  padding: "0rem 2rem",
                  maxHeight: "46vh",
                  position: "sticky",
                }}
                className="tableBody"
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead className="table-row-odd">
                    <TableRow>
                      <TableCell className="tableHeader">Select</TableCell>
                      <TableCell className="tableHeader">Full Name</TableCell>
                      <TableCell className="tableHeader">User Id</TableCell>
                      <TableCell className="tableHeader">Role</TableCell>
                      <TableCell className="tableHeader">
                        Default Product
                      </TableCell>
                      <TableCell className="tableHeader">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentPageItems?.length === 0 ? (
                      <TableRow sx={{ height: "40vh" }}>
                        <TableCell
                          align="center"
                          colSpan={6}
                        >
                          No Users
                        </TableCell>
                      </TableRow>
                    ) : (
                      currentPageItems?.map((user: UserData, index: number) => (
                        <TableRow
                          key={index}
                          className={
                            index % 2 === 0 ? "table-row-even" : "table-row-odd"
                          }
                        >
                          <TableCell>
                            <Radio
                              name="selectedUser"
                              checked={
                                selectedRow ===
                                (currentPage - 1) * itemsPerPage + index + 1
                              }
                              onClick={() =>
                                handleRowSelect(
                                  (currentPage - 1) * itemsPerPage + index + 1
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            {user.firstName} {user.lastName}
                          </TableCell>
                          <TableCell>
                            <Link
                              onClick={() => {
                                setSelectedRow(
                                  (currentPage - 1) * itemsPerPage + index + 1
                                );
                                setActionBoolean(true);
                                setMode("Edit");
                              }}
                            >
                              {user.userId}
                            </Link>
                          </TableCell>
                          <TableCell>{user.role.roleName}</TableCell>
                          <TableCell>{user.defaultProduct}</TableCell>
                          <TableCell>{user.status}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box className={listOfUsersStyles.actionBox}>
            {/* pagination */}
            <Pagination
              rows={String(itemsPerPage)}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalDataLength={listOfUsers.length}
              dataPerPage={itemsPerPage}
              handleItemsPerPageChange={(e) => handleItemsPerPageChange(e)}
              fieldName="Users"
            />
            {/* action buttons */}
            <Box className={listOfUsersStyles.actionButtonsBox}>
              <Button
                variant="outlined"
                disabled={compareUserLists(
                  initialUserList,
                  clientData.userDataList
                )}
                onClick={() => {
                  handleOnSaveAllUsers();
                }}
              >
                Save All
              </Button>
              <Button
                variant="outlined"
                disabled={selectedRow === 0}
                onClick={() => {
                  setActionBoolean(true);
                  setMode("Edit");
                }}
              >
                Edit User
              </Button>
              <Button
                variant="outlined"
                disabled={selectedRow !== 0}
                onClick={() => {
                  setActionBoolean(true);
                  setMode("Create");
                }}
              >
                Create New User
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {actionBoolean && (
        <CreateUser
          index={selectedRow - 1}
          mode={mode}
          handleOnCreateUserCancel={handleOnCreateUserCancel}
          handleOnSuccessBack={handleOnSuccessBack}
        />
      )}
    </>
  );
};

export default ListOfUsers;
