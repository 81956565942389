import { FileUploadOutlined } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import lodash from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  baseFeatureFilesUpdate,
  getBaseFeatureFiles,
} from "src/redux/slices/baseFilesSlice";
import {
  getUserFeatureFiles,
  userFilesUpdate,
} from "src/redux/slices/userFilesSlice";
import { stepDef } from "src/types/StepDef";
import {
  deleteFeatureFiles,
  downLoadFeatureFiles,
  getAllFeatureFiles,
  getAllStepDefs,
  saveFeatureFile,
  saveMultipleFeatureFile,
} from "../../clients/FeatureService";
import { login, selectUser } from "../../redux/slices/userSlice";
import { Feature } from "../../types/Feature";
import parseFileToString from "../../utils/FileUtil";
import RunForm from "../Configure/RunForm";
import { LOCATION } from "../constants/Constants";
import WarningPopup from "../modal/WarningPopup";
import NotifyWarning from "../notifications/NotifyWarning";
import FeatureCard from "../templates/FeatureCard";
import Pagination from "../templates/Pagination";
import featurestyles from "./Features.module.css";
import EditFeature from "./edit/EditFeature";
import ViewFeature from "./view/ViewFeature";

const Features = () => {
  const [stepDefData, setStepDefData] = useState<string[]>([]);
  const [featureData, setFeatureData] = useState<Feature | null>(null);
  // const [categoryList, setCategoryList] = useState<Category[]>([]);
  // const [selectedCategory, setSelectedCategory] = useState<string>("ALL");
  const [mode, setMode] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isCloneEnable, setIsCloneEnable] = useState<number | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [fileIndex, setFileIndex] = useState<number>(-1);
  const [inValidFiles, setInValidFiles] = useState<{ [key: string]: string }>(
    {}
  );
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [isConfigTestLoading, setIsConfigTestLoading] = useState(false);
  const [runFeatureIndex, setRunFeatureIndex] = useState<number>(-1);
  const [runPopup, setRunPopup] = useState<boolean>(false);
  const [isDisable, isLoading] = useState<boolean>(false);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(-1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(15);
  const user = useSelector(selectUser);
  const basefiles = useSelector(getBaseFeatureFiles) || [];
  const userfiles = useSelector(getUserFeatureFiles) || [];
  const [featureFiles, setFeatureFiles] = useState<Feature[] | undefined>(
    () => {
      if (
        basefiles.baseFiles === undefined ||
        userfiles.userFiles === undefined
      ) {
        return undefined;
      } else {
        let combinedFiles = [
          ...basefiles.baseFiles,
          ...lodash.filter(
            userfiles.userFiles,
            (file) => file.location !== LOCATION.REVIEW
          ),
        ];
        return combinedFiles;
      }
    }
  );
  const disableDelete = lodash.some(featureFiles, (feature) => {
    return (
      lodash.includes(selectedFeatures, feature.name.replaceAll(" ", "_")) &&
      (feature.location === LOCATION.BASE ||
        feature.location === LOCATION.CUSTOM)
    );
  });
  const [filteredFeatureNames, setFilteredFeatureNames] = useState();
  const dispatch = useDispatch();
  let uploadedFeatureFiles: Feature[] = [];
  let isFileNameUnique = true;

  featureFiles?.forEach((feature: Feature, index: number) => {
    if (feature?.name?.replaceAll(" ", "_") === fileName) {
      isFileNameUnique = false;
      return;
    }
  });

  const fetchData = async () => {
    try {
      dispatch(
        login({
          userId: user.userId,
          password: user.password,
          email: user.email,
          role: user.role,
          permissionsList: user.permissionsList,
          productCode: user.productCode,
          firstName: user.firstName,
          lastName: user.lastName,
          products: user.products,
          filesStatus: true,
          clientId: user.clientId,
          clientCode: user.clientCode,
          auth: user.auth,
        })
      );
      // setCategoryList([]);
      const featuresRes = await getAllFeatureFiles(
        [LOCATION.BASE, LOCATION.CUSTOM, LOCATION.IN_PROGRESS, LOCATION.REVIEW],
        user.auth,
        user.productCode
      );
      const stepDefRes = await getAllStepDefs(user.auth, user.productCode);
      // const categoryRes = await getAllCategories(user.auth);
      // feature files
      if (featuresRes.ok) {
        const data = featuresRes.json();
        const featureFilesData = await data;
        const validFeatureList = featureFilesData.features;
        const baseArray = [
          ...validFeatureList[LOCATION.BASE],
          ...validFeatureList[LOCATION.CUSTOM],
        ];
        dispatch(
          baseFeatureFilesUpdate({
            baseFiles: baseArray,
          })
        );
        const userArray = [
          ...validFeatureList[LOCATION.IN_PROGRESS],
          ...validFeatureList[LOCATION.REVIEW],
        ];
        dispatch(userFilesUpdate({ userFiles: userArray }));
        if (!lodash.isEmpty(featureFilesData.errorList)) {
          let message = "";
          Object.keys(featureFilesData.errorList).map(
            (file, index) => (message = message + file + ", ")
          );
          toast.warn(
            <NotifyWarning header={"Warning"}>
              <Typography variant="body2">
                Failed to load feature Files:
              </Typography>
              <Typography variant="body2">
                {message.substring(0, message.length - 2)}
              </Typography>
            </NotifyWarning>,
            {
              icon: false,
            }
          );
        }
        // if (featureFilesData.length === 0) {
        //   toast.success("No Test Scripts present");
        // } else {
        //   toast.success("Test Scripts retrived successfully");
        // }
      } else {
        dispatch(baseFeatureFilesUpdate({ baseFiles: basefiles.baseFiles }));
        dispatch(userFilesUpdate({ userFiles: userfiles.userFiles }));
        toast.error("Failed to fetch Test Scripts");
      }
      // stepDefs
      if (stepDefRes.ok) {
        const stepDefList: stepDef[] = await stepDefRes.json();
        stepDefList.forEach((stepDef) =>
          setStepDefData((prev) => [...prev, stepDef.value])
        );
        // if (stepDefList.length === 0) {
        //   toast.success(
        //     "The step definitions are either blank or have not been created."
        //   );
        // } else {
        //   toast.success("Step definitions were successfully retrieved.");
        // }
      } else {
        setStepDefData([]);
        toast.error("Encountered an error while fetching step definitions");
      }
      //  category list
      // if (categoryRes.ok) {
      //   const categoryList: Category[] = await categoryRes.json();
      //   categoryList.forEach((category) =>
      //     setCategoryList((prev) => [...prev, category])
      //   );
      //   if (categoryList.length === 0) {
      //     toast.success(
      //       "Category list is either blank or have not been created."
      //     );
      //   } else {
      //     toast.success("Category list is successfully retrieved.");
      //   }
      // } else {
      //   setCategoryList([]);
      //   toast.error("Encountered an error while fetching category list");
      // }
    } catch (error) {
      setStepDefData([]);
      // setCategoryList([]);
      setFeatureFiles([]);
      toast.error("Internal server error");
      console.error("Error fetching data:", error);
    }
    dispatch(
      login({
        userId: user.userId,
        password: user.password,
        email: user.email,
        role: user.role,
        permissionsList: user.permissionsList,
        productCode: user.productCode,
        firstName: user.firstName,
        lastName: user.lastName,
        products: user.products,
        filesStatus: false,
        clientId: user.clientId,
        clientCode: user.clientCode,
        auth: user.auth,
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [user.productCode]);

  useEffect(() => {
    //   if (selectedCategory !== "ALL") {
    //     const featuresPresent = features.featureFiles.filter(
    //       (feature: { name: any }) => {
    //         return lodash.includes(filteredFeatureNames, feature.name);
    //       }
    //     );
    //     setFeatureFiles(featuresPresent);
    //   } else {
    // setFeatureFiles(features.featureFiles);
    setFeatureFiles(() => {
      if (
        basefiles.baseFiles === undefined ||
        userfiles.userFiles === undefined
      ) {
        return undefined;
      } else {
        let combinedFiles = [
          ...basefiles.baseFiles,
          ...lodash.filter(
            userfiles.userFiles,
            (file) => file.location !== LOCATION.REVIEW
          ),
        ];
        return combinedFiles;
      }
    });
    //   }
  }, [userfiles]);

  const handleItemsPerPageChange = (event: any) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handleOnSaveFeatureFile = async (feature: Feature) => {
    const response = await saveFeatureFile(
      feature,
      user.auth,
      user.productCode
    );
    if (response.ok) {
      feature.location = LOCATION.IN_PROGRESS;
      uploadedFeatureFiles.push(feature);
      dispatch(
        userFilesUpdate({
          userFiles: [...userfiles.userFiles, ...uploadedFeatureFiles],
        })
      );
      toast.success(feature.name + " saved successfully");
    } else if (response.status === 500) {
      toast.error("Internal server error");
    }
  };

  const checkIfAlreadyPresent = (featureName: string) => {
    return featureFiles?.some(
      (existingFeature: { name: string }) =>
        existingFeature.name === featureName
    );
  };

  const handleFileUpload = async (e: any) => {
    let files: File[] = Array.from(e.target.files ?? []);
    let invalidFiles: { [key: string]: string } = {};

    for (let i = 0; i < files.length; i++) {
      let fileContent = await parseFileToStringMethod(files[i]);
      let featureName = fileContent
        .split("\n")[0]
        .replace("Feature:", "")
        .trim();
      if (checkIfAlreadyPresent(featureName)) {
        invalidFiles[files[i].name] = "already present";
      } else if (
        files[i].name.split(".")[0] !== featureName.replaceAll(" ", "_")
      ) {
        invalidFiles[files[i].name] = featureName;
      }
    }
    setInValidFiles(invalidFiles);
    if (Object.keys(invalidFiles).length === 0) {
      isLoading(true);
      saveMultipleFeatureFile(files, user.auth, user.productCode).then(
        async (res) => {
          if (res.ok) {
            const resData = await res.json();
            if (resData.status === "Failed") {
              isLoading(false);
              setInValidFiles(resData.errorList);
              console.error("Error List ", resData.errorList);
            } else {
              let featureArray: Feature[] = Object.values(
                resData.features["InProgress"]
              );
              let message = "";
              for (let i = 0; i < featureArray.length; i++) {
                message = message + featureArray[i].name + ", ";
                featureArray[i].location = LOCATION.IN_PROGRESS;
              }
              dispatch(
                userFilesUpdate({
                  userFiles: [...userfiles.userFiles, ...featureArray],
                })
              );
              isLoading(false);
              toast.success(
                message.substring(0, message.length - 2) + " saved successfully"
              );
            }
          } else if (res.status === 500) {
            isLoading(false);
            toast.error("Internal server error");
          }
        }
      );
      setInValidFiles({});
    }
    e.target.value = "";
  };

  const parseFileToStringMethod = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      parseFileToString(file, (fileContent: string) => {
        resolve(fileContent);
      });
    });
  };

  const handleOnBack = () => {
    setFeatureData(null);
  };

  const handleView = (fileIndex: number) => {
    fileIndex = (currentPage - 1) * itemsPerPage + fileIndex;
    if (featureFiles) {
      setMode("view");
      setFileIndex(fileIndex);
      setFeatureData(featureFiles[fileIndex]);
    }
  };

  const handleEdit = (fileIndex: number) => {
    fileIndex = (currentPage - 1) * itemsPerPage + fileIndex;
    if (featureFiles) {
      setMode("edit");
      setFileIndex(fileIndex);
      setFeatureData(featureFiles[fileIndex]);
    }
  };

  const handleRunIcon = (fileIndex: number) => {
    setRunPopup(true);
    setRunFeatureIndex(fileIndex);
  };

  const handleRunPopupCancel = () => {
    setRunPopup(false);
  };

  const handleDeletePopupOk = async () => {
    setDeletePopup(false);
    isLoading(true);
    const response = await deleteFeatureFiles(
      selectedFeatures,
      user.auth,
      user.productCode
    );
    if (response.ok) {
      const updatedFeatureFilesData = userfiles.userFiles.filter(
        (feature: Feature) =>
          !selectedFeatures.includes(feature.name.replaceAll(" ", "_"))
      );
      dispatch(userFilesUpdate({ userFiles: updatedFeatureFilesData }));
      let message = "";
      for (let i = 0; i < selectedFeatures.length; i++) {
        message = message + selectedFeatures[i] + ", ";
      }
      toast.success(
        message.substring(0, message.length - 2) + " deleted successfully"
      );
      fetchData();
    } else if (response.status === 500) {
      toast.error("Internal server error");
    }
    isLoading(false);
    setSelectedFeatures([]);
  };

  const handleDeletePopupCancel = () => {
    setDeletePopup(false);
    setSelectedFeatures([]);
  };

  const handleDownload = async () => {
    const response = await downLoadFeatureFiles(
      selectedFeatures,
      user.auth,
      user.productCode
    );
    if (response.ok) {
      const byteArray = await response.arrayBuffer();
      const zipBlob = new Blob([new Uint8Array(byteArray)], {
        type: "application/zip",
      });
      const blobUrl = URL.createObjectURL(zipBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "featureFile.zip";
      link.click();
      URL.revokeObjectURL(blobUrl);
    } else if (response.status === 500) {
      toast.error("Internal server error");
    }
    setSelectedFeatures([]);
  };

  const handleSelect = (fileName: string) => {
    const index = selectedFeatures.indexOf(fileName);
    if (index !== -1) {
      const updatedFeatures = [...selectedFeatures];
      updatedFeatures.splice(index, 1);
      setSelectedFeatures(updatedFeatures);
    } else {
      setSelectedFeatures([...selectedFeatures, fileName]);
    }
  };
  const handleClone = () => {
    if (
      isCloneEnable !== null &&
      isCloneEnable >= 0 &&
      featureFiles !== undefined
    ) {
      const clonedFeature: Feature = featureFiles[isCloneEnable];
      setIsCloneEnable(null);
      let cloningFeature = { ...clonedFeature };
      cloningFeature.name = fileName.replaceAll("_", " ");
      handleOnSaveFeatureFile(cloningFeature);
    }
  };

  const handleCloneIcon = (fileIndex: number) => {
    fileIndex = (currentPage - 1) * itemsPerPage + fileIndex;
    if (featureFiles) {
      setIsCloneEnable(fileIndex);
      let incre = 1;
      while (
        lodash.some(featureFiles, (feature) => {
          return lodash.isEqual(
            feature.name.replaceAll(" ", "_"),
            `${
              featureFiles[fileIndex].name.replaceAll(" ", "_").split("(")[0]
            }(${incre})`
          );
        })
      ) {
        incre = incre + 1;
      }
      setFileName(
        `${
          featureFiles[fileIndex].name.replaceAll(" ", "_").split("(")[0]
        }(${incre})`
      );
    }
  };

  const handleCreate = () => {
    setMode("edit");
    setFeatureData({
      name: "",
      background: {
        name: "",
        steps: [],
      },
      scenarios: [
        {
          name: "",
          tags: [],
          steps: [],
        },
      ],
    });
  };

  const handlePopUpCancel = () => {
    setInValidFiles({});
  };

  const configTestLoadingOnBack = (booleanValue: boolean) => {
    setIsConfigTestLoading(booleanValue);
  };

  // const handleCategorySelect = async (event: any) => {
  //   let { name, value } = event.target;
  //   setSelectedCategory(value);
  //   setCurrentPage(1);
  //   if (value === "ALL") {
  //     setFeatureFiles(features.featureFiles);
  //   } else {
  //     const categoryId = categoryList.find(
  //       (category) => category.categoryName === value
  //     )?.categoryId;
  //     const categoryIdList = [categoryId] as number[];
  //     const response = await getFeatureNamesByCategoryId(
  //       categoryIdList,
  //       user.auth
  //     );
  //     if (response.ok) {
  //       const filterFeatureNames = await response.json();
  //       setFilteredFeatureNames(filterFeatureNames);
  //       const featuresPresent = features.featureFiles.filter(
  //         (feature: { name: any }) => {
  //           return lodash.includes(filterFeatureNames, feature.name);
  //         }
  //       );
  //       setCurrentPage(1);
  //       setFeatureFiles(featuresPresent);
  //     }
  //   }
  // };

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  const currentPageItems = featureFiles?.slice(firstIndex, lastIndex);

  if (!featureFiles) {
    return (
      <Box className="loaderIconStylings">
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <Box>
      {isDisable && <Box className={featurestyles.overlay}></Box>}
      {isDisable && (
        <Box className={featurestyles.loaderContainer}>
          <CircularProgress disableShrink />
        </Box>
      )}
      {!featureData && (
        <>
          {/* <Box>
            <FormControl sx={{ width: "18%", margin: "1rem" }}>
              <InputLabel>Category List</InputLabel>
              <Select
                size="small"
                value={selectedCategory} // Convert comma-separated string to array
                label="categoryList"
                name="categoryList"
                onChange={handleCategorySelect}
                defaultValue="ALL"
                // renderValue={(selected) => selected.join(",")}
              >
                {categoryList.map((category) => (
                  <MenuItem
                    key={category.categoryName}
                    value={category.categoryName}
                  >
                    <ListItemText primary={category.categoryName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box> */}
          {!lodash.isEmpty(featureFiles) ? (
            <>
              <Typography
                variant="h5"
                sx={{ paddingTop: "2.5%" }}
              >
                Automation Test Scripts
              </Typography>
              <Box className={featurestyles.featureItemsOuterBox}>
                {currentPageItems?.map((file: Feature, key: number) => {
                  let actions = [
                    {
                      name: "View",
                      onButtonClick: () => handleView(key),
                      icon: <VisibilityIcon />,
                      isDisabled: false,
                    },
                    {
                      name: "Clone",
                      onButtonClick: () => handleCloneIcon(key),
                      icon: <FileCopyIcon />,
                      isDisabled:
                        !user.permissionsList.includes("EDIT FEATURE"),
                    },
                  ];
                  if (file.location === LOCATION.IN_PROGRESS) {
                    actions.splice(1, 0, {
                      name: "Edit",
                      onButtonClick: () => handleEdit(key),
                      icon: <EditIcon />,
                      isDisabled:
                        !user.permissionsList.includes("EDIT FEATURE") ||
                        stepDefData.length <= 0,
                    });
                    actions.splice(2, 0, {
                      name: "Run",
                      onButtonClick: () => handleRunIcon(key),
                      icon: <PlayCircleFilledOutlinedIcon />,
                      isDisabled: false,
                    });
                  }

                  return (
                    <FeatureCard
                      feature={file}
                      key={key}
                      id={key}
                      totalSelected={selectedFeatures.length}
                      handleSelect={handleSelect}
                      isChecked={
                        selectedFeatures.indexOf(
                          file.name.replaceAll(" ", "_")
                        ) !== -1
                      }
                      actions={actions}
                    />
                  );
                })}
              </Box>
            </>
          ) : (
            <Box className={featurestyles.NoFilesMessage}>
              <Typography variant="subtitle1">
                No test scripts/stepDefs are present.
              </Typography>
            </Box>
          )}
          <Box className={featurestyles.actionBox}>
            {/* pagination */}
            <Pagination
              rows={String(itemsPerPage)}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalDataLength={featureFiles.length}
              dataPerPage={itemsPerPage}
              handleItemsPerPageChange={(e) => handleItemsPerPageChange(e)}
              fieldName="Test scripts"
            />
            {/* action buttons */}
            <Box className={featurestyles.actionButtonsBox}>
              <Button
                variant="outlined"
                startIcon={<DeleteOutlineOutlinedIcon />}
                disabled={
                  !user.permissionsList.includes("DELETE FEATURE") ||
                  selectedFeatures.length === 0 ||
                  disableDelete
                }
                onClick={() => {
                  setDeletePopup(true);
                }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                startIcon={<FileDownloadOutlinedIcon />}
                disabled={selectedFeatures.length === 0}
                onClick={handleDownload}
              >
                Download
              </Button>
              <Button
                variant="outlined"
                startIcon={<FileUploadOutlined />}
                disabled={
                  selectedFeatures.length !== 0 || stepDefData.length === 0
                }
                component="label"
              >
                Upload a file
                <input
                  id="file-upload-button"
                  accept=".feature"
                  type="file"
                  onChange={handleFileUpload}
                  multiple
                  hidden
                />
              </Button>
              <Button
                variant="outlined"
                startIcon={<NoteAddOutlinedIcon />}
                disabled={
                  selectedFeatures.length !== 0 || stepDefData.length === 0
                }
                onClick={handleCreate}
              >
                Create Test Script
              </Button>
            </Box>
          </Box>
        </>
      )}
      {isCloneEnable !== null && (
        <Dialog
          open={isCloneEnable !== null}
          onClose={() => setIsCloneEnable(null)}
          PaperProps={{
            style: {
              padding: "1rem",
            },
          }}
        >
          <Box className={featurestyles.cloneDialogBody}>
            <TextField
              variant="outlined"
              label="Enter Test Script Name"
              value={fileName}
              onChange={(e) => {
                setFileName(e.target.value);
              }}
              helperText={
                isFileNameUnique
                  ? ""
                  : "Please enter valid test script file name"
              }
            />
            <Box className={featurestyles.cloneDialogBodyActions}>
              <Button
                variant="outlined"
                onClick={() => setIsCloneEnable(null)}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={handleClone}
                disabled={fileName === "" || !isFileNameUnique}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
      {mode === "view" && featureData !== null && featureData !== undefined && (
        <ViewFeature
          fileIndex={fileIndex}
          featureData={featureData}
          stepDefList={stepDefData}
          onBack={handleOnBack}
        />
      )}
      {mode === "edit" && featureData !== null && featureData !== undefined && (
        <EditFeature
          fileIndex={fileIndex}
          featureData={featureData}
          stepDefList={stepDefData}
          handleOnBack={handleOnBack}
        />
      )}
      {Object.keys(inValidFiles).length > 0 && (
        <WarningPopup
          isOpen={true}
          onClose={handlePopUpCancel}
          actionButtons={[
            {
              name: "Cancel",
              onButtonClick: handlePopUpCancel,
            },
          ]}
        >
          <Typography sx={{ paddingBottom: "8px" }}>
            The files provided are not in the correct format. Kindly review and
            reupload the files.
          </Typography>
          <Typography variant="subtitle2">Note:</Typography>
          <List className={featurestyles.listCss}>
            <ListItem
              disablePadding
              className={featurestyles.listItemCss}
            >
              <ListItemText primary="The uploaded files must consist of complete test script data without any null content." />
            </ListItem>
            <ListItem
              disablePadding
              className={featurestyles.listItemCss}
            >
              <ListItemText primary="Ensure that the filename matches the corresponding test script module name." />
            </ListItem>
            <ListItem
              disablePadding
              className={featurestyles.listItemCss}
            >
              <ListItemText primary="Duplicate entries should be avoided." />
            </ListItem>
            <ListItem
              disablePadding
              className={featurestyles.listItemCss}
            >
              <ListItemText primary="Step definitions should be valid." />
            </ListItem>
          </List>
          <Divider sx={{ padding: "8px 0px" }} />
          {Object.keys(inValidFiles).map((file, index) => (
            <Box key={index}>
              <Typography sx={{ color: "#fe524a" }}>{file}</Typography>
            </Box>
          ))}
        </WarningPopup>
      )}
      {deletePopup && (
        <WarningPopup
          isOpen={true}
          onClose={handleDeletePopupCancel}
          actionButtons={[
            {
              name: "Confirm",
              onButtonClick: handleDeletePopupOk,
            },
            {
              name: "Cancel",
              onButtonClick: handleDeletePopupCancel,
            },
          ]}
        >
          <Typography>Do you want to delete the test script(s)</Typography>
        </WarningPopup>
      )}
      {runPopup && (
        <WarningPopup
          isOpen={true}
          header="Execute Test"
          onClose={!isConfigTestLoading ? handleRunPopupCancel : undefined}
        >
          <Box sx={{ width: "35rem" }}>
            <RunForm
              featureFile={
                currentPageItems && currentPageItems[runFeatureIndex]
              }
              configTestLoadingOnBack={configTestLoadingOnBack}
            />
          </Box>
        </WarningPopup>
      )}
    </Box>
  );
};

export default Features;
