import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

interface TableTemplateProps {
  data: any[];
  columnNames: any[];
}
function TableTemplateA({ data, columnNames }: TableTemplateProps) {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: "80%" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {columnNames.map((columnName) => (
              <TableCell
                key={columnName}
                width={150}
              >
                <Typography>{columnName}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row: any, index: any) => {
            return (
              <TableRow key={index}>
                {Object.keys(row).map((key) => (
                  <TableCell key={key as string}>{row[key]}</TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableTemplateA;
