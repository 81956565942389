import { createContext, useContext } from "react";
import { ClientData } from "src/types/UserData";

export const ClientSetUpContext = createContext<
  | {
      clientData: ClientData;
      setClientData: React.Dispatch<React.SetStateAction<ClientData>>;
    }
  | undefined
>(undefined);

export const useClientDataContext = () => {
  const clientData = useContext(ClientSetUpContext);
  if (!clientData) {
    throw new Error("useClientContex must be used with a ClientSetUpContext");
  }
  return clientData;
};
