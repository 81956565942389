import { AccountCircle } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Card,
  ClickAwayListener,
  CssBaseline,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  MenuList,
  Popper,
  Select,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  baseFeatureFilesReset,
  baseFeatureFilesUpdate,
} from "src/redux/slices/baseFilesSlice";
import {
  userFilesReset,
  userFilesUpdate,
} from "src/redux/slices/userFilesSlice";
import { Products } from "src/types/UserData";
import rs_logo from "../../assets/redshift-logo-final.svg";
import { setContainerIdnull } from "../../redux/slices/dockercontid";
import { login, logout, selectUser } from "../../redux/slices/userSlice";
import WarningPopup from "../modal/WarningPopup";
import navbarstyles from "./Navbar.module.css";

const AppHeader = () => {
  const [accountOpen, setAccountOpen] = useState(false); // State to control popper visibility
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [isPopUpOpened, setIsPopUpOpened] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const [productcode, setproductcode] = useState(user?.productCode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [productsList, setProductsList] = useState<Products[]>([
    {
      productId: 1,
      productName: "MAO",
    },
    {
      productId: 2,
      productName: "MAWM",
    },
  ]);

  const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAccountOpen((prevOpen) => !prevOpen); // Toggle the popper visibility
    setAnchorEl2(event.currentTarget); // Store the anchor element
  };

  const handleChangePassword = () => {
    navigate("/changePassword");
    setAccountOpen(false);
  };

  const handlePopupCancel = () => {
    setIsPopUpOpened(false);
  };

  const handleSignOut = () => {
    setproductcode(undefined);
    dispatch(baseFeatureFilesReset());
    dispatch(userFilesReset());
    dispatch(logout());
    dispatch(setContainerIdnull());
    navigate("/login");
    setAccountOpen(false);
  };

  const handleClickAway = () => {
    setAccountOpen(false);
  };

  const handleProductChange = (val: string) => {
    if (
      user.products.some(
        (product: Products) => product.productName.indexOf(val) !== -1
      )
    ) {
      setproductcode(val);
      dispatch(
        login({
          userId: user.userId,
          password: user.password,
          email: user.email,
          role: user.role,
          permissionsList: user.permissionsList,
          productCode: val,
          firstName: user.firstName,
          lastName: user.lastName,
          products: user.products,
          filesStatus: true,
          clientId: user.clientId,
          clientCode: user.clientCode,
          auth: `Basic ${btoa(`${user.userId}:${user.password}`)}`,
        })
      );
      dispatch(
        baseFeatureFilesUpdate({
          baseFiles: undefined,
        })
      );
      dispatch(userFilesUpdate({ userFiles: undefined }));
      navigate("/");
    } else {
      setIsPopUpOpened(true);
    }
  };

  return (
    <>
      <AppBar
        position="sticky"
        className={navbarstyles.appbarCss}
      >
        <Toolbar variant="dense">
          <Box className={navbarstyles.toolbarOuterBox}>
            <Box className={navbarstyles.toolbarInnerBox}>
              <Typography
                variant="h6"
                onClick={() => navigate("/")}
                sx={{ cursor: "pointer" }}
              >
                RS Automator
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 4,
              }}
            >
              {user && (
                <FormControl
                  sx={{
                    width: "fit-content",
                  }}
                >
                  <Select
                    required
                    size="small"
                    id="statuscode"
                    value={
                      !user.products.some(
                        (product: Products) =>
                          product.productName === productcode
                      )
                        ? user.productCode
                        : productcode
                    }
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      "& .MuiSelect-root": {
                        alignSelf: "flex-start",
                      },
                    }}
                    onChange={(e) => {
                      handleProductChange(e.target.value);
                    }}
                    defaultValue={user.productCode}
                    disabled={user.filesStatus === true}
                  >
                    {productsList.map((product) => (
                      <MenuItem
                        key={product.productId}
                        value={product.productName}
                      >
                        {product.productName}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* {productcode === "MAWM" && (
                    <Mawmnolicense
                      productcode={productcode}
                      setproductcode={setproductcode}
                    />
                  )} */}
                </FormControl>
              )}
              <Box className={navbarstyles.navbarImageOuterBox}>
                <Typography
                  variant="h5"
                  component="div"
                >
                  <img
                    className={navbarstyles.rsLogoCss}
                    src={rs_logo}
                    alt="logo"
                  />
                </Typography>
                {user && (
                  <IconButton
                    edge="start"
                    onClick={handleAccountClick}
                    disableRipple={true}
                  >
                    <AccountCircle className={navbarstyles.accountCircleCss} />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <CssBaseline />
      {user && (
        <Popper
          open={accountOpen}
          anchorEl={anchorEl2}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Card className={navbarstyles.popperCardCss}>
              <MenuList
                autoFocusItem={accountOpen}
                id="account-menu-list"
              >
                <MenuItem
                  onClick={handleClickAway}
                  dense
                  className={navbarstyles.popperMenuItemCss}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                      alignItems={"center"}
                      textOverflow={"ellipsis"}
                      overflow={"hidden"}
                    >
                      <AccountCircle />
                      <Tooltip
                        placement="top-start"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -10],
                                },
                              },
                            ],
                          },
                        }}
                        title={`${user.firstName} ${user.lastName}`}
                      >
                        <Box
                          textOverflow={"ellipsis"}
                          overflow={"hidden"}
                          width={"10em"}
                          whiteSpace={"nowrap"}
                        >
                          {user.firstName} {user.lastName}
                        </Box>
                      </Tooltip>
                    </Box>

                    <Box
                      display={"flex"}
                      ml={4}
                    >
                      ({user.userId})
                    </Box>
                  </Box>
                </MenuItem>
                <Divider className={navbarstyles.popperDividerCss} />
                <MenuItem
                  onClick={handleChangePassword}
                  dense
                  className="card"
                >
                  Change Password
                </MenuItem>
                <Divider className={navbarstyles.popperDividerCss} />
                <MenuItem
                  onClick={handleSignOut}
                  dense
                  className="card"
                >
                  Sign Out
                </MenuItem>
              </MenuList>
            </Card>
          </ClickAwayListener>
        </Popper>
      )}
      {isPopUpOpened && (
        <WarningPopup
          isOpen={true}
          onClose={handlePopupCancel}
          actionButtons={[
            {
              name: "Close",
              onButtonClick: handlePopupCancel,
            },
          ]}
        >
          <Typography>
            You don't have enough privileges. Please contact your administrator.
          </Typography>
        </WarningPopup>
      )}
    </>
  );
};

export default AppHeader;
