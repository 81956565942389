import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { changePassword } from "src/clients/UserService";
import { login, selectUser } from "src/redux/slices/userSlice";
import changePasswordStyles from "./ChangePassword.module.css";

const ChangePassword = () => {
  const [passwordValidations, setPasswordValidations] = useState<
    { valid: boolean; message: string }[]
  >([]);
  const [PreviousPassword, setPreviousPassword] = useState("");
  const [showPreviousPassword, setShowPreviousPassword] = useState(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [Instructions, setInstructions] = useState<boolean>(false);
  const [currentPasswordError, setCurrentPasswordError] =
    useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [confirmPasswordError, setConfirmPasswordError] =
    useState<boolean>(false);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const checkPasswordStrength = (password: string) => {
    const validations = [
      { regex: /[a-z]/, message: "At least one lowercase letter," },
      { regex: /[A-Z]/, message: "uppercase letter," },
      { regex: /[0-9]/, message: "digit," },
      {
        regex: /[!@#$%^&*()\-_=+[\]{}|;:'",.<>?/~`]/,
        message: "special character,",
      },
      { regex: /.{6,}/, message: "Minimum 6 characters" },
    ];

    let isValid = true;

    const validationResults = validations.map(({ regex, message }) => ({
      valid: regex.test(password),
      message,
    }));

    validationResults.forEach((error) => {
      if (!error.valid) {
        isValid = false;
      }
    });

    setPasswordError(!isValid);
    return validationResults;
  };

  useEffect(() => {
    setPasswordValidations(checkPasswordStrength(newPassword));
  }, [newPassword]);

  useEffect(() => {
    if (currentPasswordError) {
      setCurrentPasswordError(false);
    }
    if (confirmPassword) {
      setConfirmPasswordError(false);
    }
  }, [PreviousPassword, newPassword, confirmPassword]);

  const handlePreviousPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPreviousPassword(event.target.value);
    setInstructions(false);
  };

  const handlePreviousPasswordVisibility = () => {
    setShowPreviousPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.target.value);
    setInstructions(true);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
    setInstructions(false);
    setConfirmPasswordError(false);
  };

  const handleNewPasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = () => {
    if (newPassword === confirmPassword) {
      changePassword(user.userId, PreviousPassword, newPassword).then((res) => {
        if (res.ok) {
          toast.success("password updated successfully");
          const updatedUser = {
            ...user,
            password: newPassword,
            auth: `Basic ${btoa(`${user.user}:${newPassword}`)}`,
          };
          dispatch(login(updatedUser));
          setPreviousPassword("");
          setNewPassword("");
          setConfirmPassword("");
        } else {
          if (res.status === 401) {
            setCurrentPasswordError(true);
          }
          toast.error("Failed to update password");
        }
      });
    } else {
      setConfirmPasswordError(true);
    }
  };

  return (
    <Box className={changePasswordStyles.outterBox1}>
      <Box className={changePasswordStyles.outterBox2}>
        <Typography
          variant="h5"
          sx={{ padding: "1rem" }}
        >
          Change Password
        </Typography>
        <Box>
          <Box className={changePasswordStyles.inputBox}>
            <TextField
              type={showPreviousPassword ? "text" : "password"}
              fullWidth
              id="filled-basic-currentPassword"
              size="small"
              value={PreviousPassword}
              label="Current Password"
              variant="outlined"
              error={currentPasswordError}
              onChange={handlePreviousPasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handlePreviousPasswordVisibility}
                      edge="end"
                    >
                      {showPreviousPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
              helperText={currentPasswordError ? "Invalid Password" : ""}
            />

            <TextField
              type={showPassword ? "text" : "password"}
              fullWidth
              id="filled-basic-newPassword"
              size="small"
              value={newPassword}
              label="New Password"
              variant="outlined"
              onChange={handleNewPasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleNewPasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />

            {Instructions && (
              <Grid
                container
                direction="row"
              >
                {passwordValidations.map((validation, index) => (
                  <Grid
                    item
                    key={index}
                  >
                    <Typography
                      variant="caption"
                      color={validation.valid ? "green" : "error"}
                    >
                      {validation.message + "\t"}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            )}

            <TextField
              id="standard-password-input"
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              value={confirmPassword}
              size="small"
              autoComplete="current-password"
              variant="outlined"
              onChange={handleConfirmPasswordChange}
              required
              disabled={passwordError}
              error={confirmPasswordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleConfirmPasswordVisibility}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={confirmPasswordError ? "Passwords do not match" : ""}
            />
          </Box>

          <Box className={changePasswordStyles.actionBox}>
            <Button
              sx={{ width: "5rem" }}
              variant="outlined"
              onClick={handleSubmit}
              disabled={passwordError}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
