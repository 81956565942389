import { Box, Checkbox, IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import featureCardStyles from "src/components/templates/FeatureCard.module.css";
import { ActionButton } from "src/types/ActionButton";
import { Feature } from "src/types/Feature";
import { LOCATION } from "../constants/Constants";

interface FeatureCardProps {
  feature: Feature;
  id: number;
  totalSelected: number;
  handleSelect: (featureName: string) => void;
  isChecked: boolean;
  actions: ActionButton[];
}

const FeatureCard = ({
  feature,
  id,
  totalSelected,
  handleSelect,
  isChecked,
  actions,
}: FeatureCardProps) => {
  const [hoveredCardIndex, setHoveredCardIndex] = useState(-1);

  const handleCardMouseEnter = (index: number) => {
    setHoveredCardIndex(index);
  };

  const handleCardMouseLeave = () => {
    setHoveredCardIndex(-1);
  };

  return (
    <Box
      className={featureCardStyles.featureCardCss}
      key={`feature ${id}`}
      onMouseEnter={() => handleCardMouseEnter(id)}
      onMouseLeave={handleCardMouseLeave}
      sx={{
        backgroundColor: feature.location === LOCATION.REVIEW ? "#E5E4E2" : "",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          display: "flex",
          alignItems: "center",
          gap: "2rem",
        }}
      >
        {
          <Checkbox
            size="small"
            sx={{
              visibility:
                (hoveredCardIndex === id || totalSelected > 0) &&
                feature.location !== LOCATION.REVIEW
                  ? "visible"
                  : "hidden",
            }}
            onClick={() => {
              handleSelect(feature.name.replaceAll(" ", "_"));
            }}
            checked={isChecked}
          />
        }
        {(feature.location === LOCATION.BASE ||
          feature.location === LOCATION.CUSTOM) && (
          <Typography
            variant="subtitle2"
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {feature.location} File
          </Typography>
        )}
        {feature.location === LOCATION.IN_PROGRESS && (
          <Typography
            variant="subtitle2"
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            User File
          </Typography>
        )}
        {feature.location === LOCATION.REVIEW && (
          <Typography
            variant="subtitle2"
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            In Review
          </Typography>
        )}
      </Box>

      <Box sx={{ m: "0 2rem 2rem" }}>
        <Tooltip
          placement="top-start"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [45, -10],
                  },
                },
              ],
            },
          }}
          title={feature.name}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                overflow: "hidden",
                textWrap: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Module : {feature?.name}
            </Typography>
          </Box>
        </Tooltip>
        <Typography>Test Scenarios : {feature?.scenarios.length}</Typography>
        {actions && (
          <Box className={featureCardStyles.featureCardActionsCss}>
            {actions.map((action, index) => {
              return (
                <IconButton
                  key={`button ${index}`}
                  className={featureCardStyles.featureIconButtonCss}
                  onClick={() => action.onButtonClick()}
                  disabled={action.isDisabled}
                >
                  {action.icon}
                </IconButton>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FeatureCard;
