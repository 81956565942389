import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Typography } from "@mui/material";
import { NotificationProps } from "src/types/NotificationProps";
import notifyWarningStyles from "./NotifyWarning.module.css";

const NotifyWarning = ({ header, children }: NotificationProps) => {
  return (
    <Box sx={{ display: "inline" }}>
      <Box className={notifyWarningStyles.notifyWarningHeader}>
        <WarningAmberIcon className={notifyWarningStyles.warnIcon} />
        <Typography variant="h4">{header}</Typography>
      </Box>
      {children}
    </Box>
  );
};

export default NotifyWarning;
