import { HttpMethod } from "../types/HttpMethod";
import { ScheduleJobTypes } from "../types/ScheduleJobTypes";

const scheduleapiUrl = `${process.env.REACT_APP_BASE_URL}/rs/automation/schedule/job`;

export const getScheduledJobs = (
  auth: string,
  filterdata: ScheduleJobTypes
) => {
  const url = `${scheduleapiUrl}/user`;
  const data = {
    scheduleJobId: filterdata.scheduleJobId,
    scheduleJobStartDateTime: filterdata.scheduleJobStartDateTime,
    scheduleJobEndDate: filterdata.scheduleJobEndDate,
    countryCode: filterdata.countryCode,
    status: filterdata.status,
    reportUser: filterdata.reportUser,
    jobType: filterdata.jobType,
    jobFrequency: filterdata.jobFrequency,
    userSpecificScheduleJobId: filterdata.userSpecificScheduleJobId,
    productCode: filterdata.productCode,
    clientCode: filterdata.clientCode,
  };
  return fetch(url, {
    method: HttpMethod.Post,
    headers: { Authorization: auth, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};
export const update = (
  auth: string,
  formData: FormData,
  productCode: string
) => {
  const url = `${scheduleapiUrl}/${productCode}/update`;
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
    },
    body: formData,
  });
};

export const schedule = (
  auth: string,
  formData: FormData,
  productCode: string
) => {
  const url = `${scheduleapiUrl}/${productCode}/create`;
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
    },
    body: formData,
  });
};

export const pause = (auth: string, formData: FormData) => {
  const url = `${scheduleapiUrl}/pause`;
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
    },
    body: formData,
  });
};

export const resume = (auth: string, formData: FormData) => {
  const url = `${scheduleapiUrl}/resume`;
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
    },
    body: formData,
  });
};

export const deleteJob = (auth: string, formData: FormData) => {
  const url = `${scheduleapiUrl}/delete`;
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
    },
    body: formData,
  });
};
