import {
  Box,
  Button,
  CircularProgress,
  Link,
  Radio,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clientStatusUpdate,
  getAllClients,
  setUpClient,
} from "src/clients/ClientService";
import { getAllProducts } from "src/clients/ProductsService";
import { selectUser } from "src/redux/slices/userSlice";
import { ClientData, Products } from "src/types/UserData";
import Pagination from "../templates/Pagination";
import listOfClientsStyles from "./ListOfClients.module.css";
import TabComponent from "./TabComponent";

const ListOfClients = () => {
  const [selectedRow, setSelectedRow] = useState<number>(0);
  const [listOfClients, setlistOfClients] = useState<ClientData[] | undefined>(
    undefined
  );
  const [productsList, setProductsList] = useState<Products[]>([]);
  const [actionBoolean, setActionBoolean] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("");
  const [isDisable, isLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [clientData, setClientData] = useState<ClientData>({
    clientName: "",
    clientCode: "",
    ftpServer: "",
    status: "CREATED",
    isActive: true,
    userDataList: [],
    appCredentialsList: [],
  });
  const user = useSelector(selectUser);

  useEffect(() => {
    fetchData();
    fetchProducts();
  }, []);

  const fetchData = async () => {
    const clientListRes = await getAllClients(user.userId, user.password);
    if (clientListRes.ok) {
      const clientList = await clientListRes.json();
      setlistOfClients(clientList);
      setCurrentPage(1);
    } else {
      toast.error("Failed to fetch Clients");
    }
  };

  const fetchProducts = () => {
    getAllProducts(user.userId, user.password).then(async (res) => {
      if (res.ok) {
        setProductsList(await res.json());
      } else {
        toast.error("Failed to fetch Products");
      }
    });
  };

  const handleOnLinkClick = (clientData: ClientData) => {
    setMode("Edit");
    setClientData(clientData);
    setActionBoolean(true);
  };
  const handleRowSelect = (id: number) => {
    if (selectedRow !== id) {
      setSelectedRow(id);
      if (listOfClients) {
        setClientData(listOfClients[id - 1]);
      }
    } else {
      setSelectedRow(0);
      setClientData({
        clientName: "",
        clientCode: "",
        ftpServer: "",
        status: "CREATED",
        isActive: true,
        userDataList: [],
        appCredentialsList: [],
      });
    }
  };

  const handleItemsPerPageChange = (event: any) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handleStatusChange = (index: number) => {
    if (listOfClients) {
      const currentClientData = listOfClients[index];
      isLoading(true);
      currentClientData.isActive = !currentClientData.isActive;
      clientStatusUpdate(currentClientData, user.userId, user.password).then(
        async (res) => {
          if (res.ok) {
            const clientRes = await res.json();
            const updatedListOfClients = [...listOfClients];
            updatedListOfClients[index] = clientRes;
            setlistOfClients(updatedListOfClients);
            isLoading(false);
          }
          isLoading(false);
        }
      );
    }
  };

  const handleClientSetup = () => {
    isLoading(true);
    listOfClients &&
      setUpClient(
        listOfClients[selectedRow - 1].clientCode,
        user.userId,
        user.password
      ).then((res) => {
        if (res.ok) {
          setSelectedRow(0);
          isLoading(false);
          toast.success("Client setup successfully");
        } else {
          isLoading(false);
          toast.error("Client setup Failed");
        }
        fetchData();
      });
  };

  const handleOnCreateClientCancel = () => {
    fetchData();
    setClientData({
      clientName: "",
      clientCode: "",
      ftpServer: "",
      status: "CREATED",
      isActive: true,
      userDataList: [],
      appCredentialsList: [],
    });
    setActionBoolean(false);
    setMode("");
    setSelectedRow(0);
  };

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentPageItems = listOfClients?.slice(firstIndex, lastIndex);

  if (!listOfClients) {
    return (
      <Box className="loaderIconStylings">
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <>
      {isDisable && <Box className={listOfClientsStyles.overlay}></Box>}
      {isDisable && (
        <Box className={listOfClientsStyles.loaderContainer}>
          <CircularProgress disableShrink />
        </Box>
      )}
      {!actionBoolean && (
        <Box>
          <Box className={listOfClientsStyles.mainContainer}>
            <Box height="60vh">
              <Typography
                variant="h5"
                sx={{ padding: "2%" }}
              >
                List Of Clients
              </Typography>
              <TableContainer
                sx={{
                  padding: "0rem 2rem",
                  maxHeight: "46vh",
                  position: "sticky",
                }}
                className="tableBody"
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead className="table-row-odd">
                    <TableRow>
                      <TableCell className="tableHeader">Select</TableCell>
                      <TableCell className="tableHeader">Client Name</TableCell>
                      <TableCell className="tableHeader">Client Code</TableCell>
                      <TableCell className="tableHeader">FTP Server</TableCell>
                      <TableCell className="tableHeader">Status</TableCell>
                      <TableCell className="tableHeader">Active</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentPageItems?.length === 0 ? (
                      <TableRow sx={{ height: "40vh" }}>
                        <TableCell
                          align="center"
                          colSpan={8}
                        >
                          No Clients
                        </TableCell>
                      </TableRow>
                    ) : (
                      currentPageItems?.map((client, index) => (
                        <TableRow
                          key={index}
                          className={
                            index % 2 === 0 ? "table-row-even" : "table-row-odd"
                          }
                        >
                          <TableCell>
                            <Radio
                              name="selectedclient"
                              checked={
                                selectedRow ===
                                (currentPage - 1) * itemsPerPage + index + 1
                              }
                              onClick={() =>
                                handleRowSelect(
                                  (currentPage - 1) * itemsPerPage + index + 1
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>{client.clientName}</TableCell>
                          <TableCell>
                            <Link onClick={() => handleOnLinkClick(client)}>
                              {client.clientCode}
                            </Link>
                          </TableCell>
                          <TableCell>{client.ftpServer}</TableCell>
                          <TableCell>{client.status}</TableCell>
                          <TableCell>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Switch
                                checked={client.isActive}
                                onChange={(e) =>
                                  handleStatusChange(
                                    (currentPage - 1) * itemsPerPage + index
                                  )
                                }
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box className={listOfClientsStyles.actionBox}>
            {/* pagination */}
            <Pagination
              rows={String(itemsPerPage)}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalDataLength={listOfClients.length}
              dataPerPage={itemsPerPage}
              handleItemsPerPageChange={(e) => handleItemsPerPageChange(e)}
              fieldName="Clients"
            />
            {/* action buttons */}
            <Box className={listOfClientsStyles.actionButtonsBox}>
              <Button
                variant="outlined"
                disabled={
                  !(
                    listOfClients[selectedRow - 1]?.isActive &&
                    ((listOfClients[selectedRow - 1].status !== "ACTIVE" &&
                      listOfClients[selectedRow - 1].userDataList.some(
                        (user) => user.role.roleName === "ADMIN"
                      )) ||
                      (listOfClients[selectedRow - 1].status === "ACTIVE" &&
                        listOfClients[selectedRow - 1].userDataList &&
                        (!listOfClients[selectedRow - 1].userDataList.every(
                          (user) => user.onBoarded
                        ) ||
                          listOfClients[selectedRow - 1].userDataList.some(
                            (user) =>
                              user.userAppCredentials &&
                              user.userAppCredentials.some(
                                (credential) => !credential.onBoarded
                              )
                          ))))
                  )
                }
                onClick={handleClientSetup}
              >
                Client Setup
              </Button>
              <Button
                variant="outlined"
                disabled={selectedRow === 0}
                onClick={() => {
                  setActionBoolean(true);
                  setMode("Edit");
                }}
              >
                Edit Client
              </Button>
              <Button
                variant="outlined"
                disabled={selectedRow !== 0}
                onClick={() => {
                  setActionBoolean(true);
                  setMode("Create");
                }}
              >
                Create New Client
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {actionBoolean && (
        <TabComponent
          mode={mode}
          selectedClient={clientData}
          productsList={productsList}
          handleOnCreateClientCancel={handleOnCreateClientCancel}
        />
      )}
    </>
  );
};

export default ListOfClients;
