import { Assessment, LibraryBooks, ManageHistory } from "@mui/icons-material";
import PeopleIcon from "@mui/icons-material//People";
import PublishIcon from "@mui/icons-material/Publish";
import RuleFolderRoundedIcon from "@mui/icons-material/RuleFolderRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "src/redux/slices/userSlice";
export interface NavItem {
  label: string;
  route: string;
  privilege: boolean;
  icon: ReactNode;
  options?: NavItem[]; // Use an array of NavItem for options
}

const NavItems = () => {
  const user = useSelector(selectUser);

  const SidebarData: NavItem[] = [
    {
      label: "Test Scripts",
      route: "/",
      privilege: user?.permissionsList.includes("VIEW FEATURE"),
      icon: React.createElement(LibraryBooks),
    },
    {
      label: "Publish",
      route: "/publish",
      privilege: user?.permissionsList.includes("PUBLISH FEATURE"),
      icon: React.createElement(PublishIcon),
    },
    {
      label: "Review",
      route: "/review",
      privilege: user?.permissionsList.includes("REVIEW FEATURE"),
      icon: React.createElement(RuleFolderRoundedIcon),
    },
    {
      label: "Test Run",
      route: "/configure",
      privilege: user?.permissionsList.includes("EXECUTE FEATURE"),
      icon: React.createElement(SettingsIcon),
    },
    {
      label: "Schedule",
      route: "/schedule",
      privilege: user?.permissionsList.includes("VIEW SCHEDULES"),
      icon: React.createElement(ManageHistory),
    },
    {
      label: "Reports",
      route: "/reports",
      privilege: user?.permissionsList.includes("VIEW REPORTS"),
      icon: React.createElement(Assessment),
    },
    {
      label: "Set-Up",
      route: "/listOfClients",
      privilege: user?.permissionsList.includes("VIEW CONFIG DATA"),
      icon: React.createElement(PeopleIcon),
    },
    {
      label: "Roles & Permissions",
      route: "/rolesAndPermissions",
      privilege: user?.permissionsList.includes("ROLES AND PERMISSIONS"),
      icon: React.createElement(VerifiedUserIcon),
    },
    // {
    //   label: "Edit User",
    //   route: "/editUser",
    //   privilege: user?.role === "USER" || user?.role === "ADMIN",
    //   icon: React.createElement(ManageAccountsIcon),
    // },
  ];

  return SidebarData;
};
export default NavItems;
