export const stepItem = (text: string) => {
  // let list = text.split("{string}");
  // return list.map((el, index) => {
  //   return (
  //     <span key={"span" + index}>
  //       {el + " "}{" "}
  //       {index !== list.length - 1 ? (
  //         <>
  //           <span
  //             style={{
  //               color: "#ef6935",
  //               padding: "0 5px",
  //             }}
  //           >
  //             {`{string}`}
  //           </span>
  //         </>
  //       ) : null}
  //     </span>
  //   );
  // });

  let list = text.split(" ");
  return list.map((el, index) => {
    return (
      <span key={"span" + index}>
        {el.match(/{string}/) ? (
          <span
            style={{
              color: "#ef6935",
              padding: "0 2px",
            }}
          >
            {`{string}`}
          </span>
        ) : el.match(/{word}/) ? (
          <span
            style={{
              color: "#ef6935",
              padding: "0 2px",
            }}
          >
            {`{word}`}
          </span>
        ) : el.match(/{int}/) ? (
          <span
            style={{
              color: "#ef6935",
              padding: "0 2px",
            }}
          >
            {`{int}`}
          </span>
        ) : (
          <span
            style={{
              padding: "0 2px",
            }}
          >
            {el}
          </span>
        )}
      </span>
    );
  });
};
