import { Box, Button, TextField } from "@mui/material";
import { Scenario } from "src/types/Feature";
import { tagsToString } from "src/utils/FileUtil";
import ReuseComponent from "../ReuseComponent";
import ViewFeatureStep from "./ViewFeatureStep";
import viewscenariostyles from "./ViewScenario.module.css";

interface ViewScenariosProps {
  scenarioData: Scenario;
  handleOnScenarioBack: () => void;
}

function ViewScenario({
  scenarioData,
  handleOnScenarioBack,
}: ViewScenariosProps) {
  return (
    <>
      <Box className={viewscenariostyles.scenarioBox}>
        {/* tags */}
        <TextField
          className={viewscenariostyles.viewtextfieldTagCss}
          label="Tags"
          variant="standard"
          InputProps={{
            readOnly: true,
            className: viewscenariostyles.inputTags,
          }}
          value={tagsToString(scenarioData.tags)}
          multiline
        />
        {/* Scenario name */}
        <ReuseComponent
          Name="Test Scenario"
          Value={scenarioData.name}
        />
        {/* Scenario Steps */}
        <Box className={viewscenariostyles.viewScenarioStepsBox}>
          {scenarioData.steps.map((step, stepIndex) => (
            <ViewFeatureStep
              key={`view__scenario__step__${stepIndex}`}
              stepIndex={stepIndex}
              step={step}
            />
          ))}
        </Box>
      </Box>
      {/* Action Button's */}
      <Box className={viewscenariostyles.actionButtonsBox}>
        <Button
          type="submit"
          variant="outlined"
          onClick={handleOnScenarioBack}
        >
          Back
        </Button>
      </Box>
    </>
  );
}

export default ViewScenario;
