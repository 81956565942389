import { Feature } from "src/types/Feature";
import { HttpMethod } from "src/types/HttpMethod";
import { PublishData } from "src/types/PublishData";
import { PublishDataDto } from "src/types/PublishDataDto";

const publishApiUrl = `${process.env.REACT_APP_BASE_URL}/rs/automation/publish`;

export const getAllPublishData = (productCode: string, auth: string) => {
  const url = `${publishApiUrl}/${productCode}/getAll`;
  return fetch(url, {
    method: HttpMethod.Get,
    headers: { Authorization: auth },
  });
};

export const publishFeatureFiles = (
  features: string[],
  productCode: string,
  auth: string
) => {
  const url = `${publishApiUrl}/${productCode}/feature`;
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(features),
  });
};

export const publishCommitApprove = (
  requestData: PublishDataDto[],
  reviewFeatures: Feature[],
  commitMessage: string,
  productCode: string,
  auth: string
) => {
  const bodyData = {
    requestData: requestData,
    reviewFeatures: reviewFeatures,
    commitMessage: commitMessage,
  };
  const url = `${publishApiUrl}/${productCode}/review/approve`;
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyData),
  });
};

export const publishCommitReject = (
  requestData: PublishDataDto[],
  commitMessage: string,
  productCode: string,
  auth: string
) => {
  const bodyData = {
    requestData: requestData,
    commitMessage: commitMessage,
  };
  const url = `${publishApiUrl}/${productCode}/review/reject`;
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyData),
  });
};

export const savePublishedData = (publishData: PublishData, auth: string) => {
  const url = `${publishApiUrl}/save`;
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(publishData),
  });
};
