import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import lodash from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getPermissionsByRoleId,
  saveRolesAndPermissions,
} from "src/clients/RolesAndPermissionsService";
import { selectUser } from "src/redux/slices/userSlice";
import { RolesAndPermissions } from "src/types/RolesAndPermissions";
import permissionsStyles from "../admin/Permissions.module.css";

interface PermissionsProps {
  mode: string;
  roleId: number;
  handlePermissionsEdit: () => void;
  handlePermissionsBack: (mode: string) => void;
}
export const Permissions = ({
  mode,
  roleId,
  handlePermissionsEdit,
  handlePermissionsBack,
}: PermissionsProps) => {
  const [rolesAndPermissions, setRolesAndPermissions] = useState<
    RolesAndPermissions[]
  >([]);
  const [editedRolesAndPermissions, setEditedRolesAndPermissions] = useState<
    RolesAndPermissions[]
  >([]);
  const user = useSelector(selectUser);

  useEffect(() => {
    fetchPermissionsByRoleId(roleId);
  }, [roleId]);

  const fetchPermissionsByRoleId = (roleId: number) => {
    getPermissionsByRoleId(user.userId, user.password, roleId).then(
      async (res) => {
        const data = await res.json();
        setRolesAndPermissions(data);
        setEditedRolesAndPermissions(
          data.map((item: RolesAndPermissions) => ({ ...item }))
        );
      }
    );
  };

  const handleOnCheckBoxChange = (permissionsIndex: number) => {
    setEditedRolesAndPermissions((prev) => {
      const updatedRolesAndPermissions = [...prev];
      const updatedPermissions = updatedRolesAndPermissions[permissionsIndex];
      updatedPermissions.isEnabled = !updatedPermissions.isEnabled;
      updatedRolesAndPermissions[permissionsIndex] = updatedPermissions;
      return updatedRolesAndPermissions;
    });
  };

  const handleOnBack = (mode: string) => {
    setEditedRolesAndPermissions(
      rolesAndPermissions.map((item: RolesAndPermissions) => ({ ...item }))
    );
    handlePermissionsBack(mode);
  };

  const handlePermissionsSave = (
    editedRolesAndPermissions: RolesAndPermissions[]
  ) => {
    saveRolesAndPermissions(
      user.userId,
      user.password,
      editedRolesAndPermissions
    ).then(async (res) => {
      if (res.ok) {
        fetchPermissionsByRoleId(roleId);
        handleOnBack("edit");
        toast.success("Permissions Updated Successfully");
      } else {
        toast.error("Failed to update Permissions");
      }
    });
  };

  if (editedRolesAndPermissions.length === 0) {
    return (
      <Box className="loaderIconStylings">
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <>
      <Typography variant="h5">Permissions</Typography>
      <Box className={permissionsStyles.permissionsOutterBox}>
        {editedRolesAndPermissions.map((permissions, permissionsIndex) => (
          <Box
            className={permissionsStyles.permissionsBox}
            key={permissionsIndex}
          >
            <Checkbox
              className="checkcss"
              checked={permissions.isEnabled}
              onChange={() => handleOnCheckBoxChange(permissionsIndex)}
              disabled={mode === "view"}
            />
            <Typography> {permissions.permission.permissionName}</Typography>
          </Box>
        ))}
      </Box>
      <Box className={permissionsStyles.actionBox}>
        <Box className={permissionsStyles.actionButtonsBox}>
          {mode !== "view" && (
            <Button
              variant="outlined"
              onClick={() => {
                handlePermissionsSave(editedRolesAndPermissions);
              }}
              disabled={lodash.every(
                rolesAndPermissions,
                (roleAndPerm, index) =>
                  lodash.isEqual(
                    roleAndPerm.isEnabled,
                    editedRolesAndPermissions[index].isEnabled
                  )
              )}
            >
              Save
            </Button>
          )}
          {mode === "view" && (
            <Button
              variant="outlined"
              onClick={() => {
                handlePermissionsEdit();
              }}
            >
              Edit
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={() => {
              handleOnBack(mode);
            }}
          >
            Back
          </Button>
        </Box>
      </Box>
    </>
  );
};
