import { Route, Routes } from "react-router-dom";
import NotFound from "src/components/modal/NotFound";
import RouteItems, { RouteItem } from "./RouteItems";

const Routers = () => {
  const RouteData: RouteItem[] = RouteItems();

  return (
    <Routes>
      {RouteData.map((item) => {
        return (
          <Route
            key={item.navItemId}
            path={item.permissions ? item.path : "/*"}
            element={item.permissions ? item.element : <NotFound />}
          />
        );
      })}
    </Routes>
  );
};

export default Routers;
