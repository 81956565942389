import { HttpMethod } from "../types/HttpMethod";

const apiUrl = `${process.env.REACT_APP_BASE_URL}/rs/automation/process`;

export const runautomation = (
  auth: string,
  formData: FormData,
  productCode: string
) => {
  const url = `${apiUrl}/${productCode}/run`;
  return fetch(url, {
    method: HttpMethod.Post,
    headers: {
      Authorization: auth,
    },
    body: formData,
  });
};
